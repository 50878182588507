import React, { useEffect, useState } from "react";
import "../assets/css/entry.css";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Entry = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Link
      key={props?.id}
      to={props.preview ? "" : `/blog/${props?.link}`}
      state={{ ...props }}
      className="heatfeed__card"
    >
      <div className="entry__container"> 
        <img src={props?.thumbnail} alt="" className={props.preview ? "entry__image entry__blur__image" : "entry__image"} />
        <div className={(props.preview) ? "filter__blur" : ""}>
          <h3 className="entry__h3">{props?.heading}</h3>
          <p className="entry__p">{props.short}</p>
        </div>
        <button
          disabled={props.preview}
          className={props.preview ? "entry_button_preview" : "entry_button"}
          onClick={() => navigate(`/blog/${props?.link}`)}
        >
          {props.preview ? t('available_soon') : t('learn_more')}
        </button>
      </div>
    </Link>
  );
};

export default Entry;
