import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer";
import { useTranslation } from "react-i18next";

const Blog = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const { t } = useTranslation();
  const [entry, setEntry] = useState(state || {})
  const { id } = useParams();


  useEffect(() => {
    const blogentries = t('blog.entries', { returnObjects: true })
    blogentries?.map(e => {
      if (id == e.link) {
        setEntry(e);
      }

    })
  }, [location]);


  console.log(entry)

  const handleBack = () => {
    (entry.id == undefined) ? navigate(-1):navigate('/blog');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {/* {loading && loader} */}
      <div>
        <Navigation {...{ subMenu: false }} />
        <main id="main" className="main">
          <div className="heatfeed__container">
            {entry?.thumbnail && <img src={entry.thumbnail} alt="" className="blog__img" />}
            <section className="heatfeed__section__text">
              <h2>{entry.heading || entry.title}</h2>


              {/* normal Blog-Entry */}
              {entry.content ?
                <>
                  {entry.content.map(c => <p className="heatfeed__p">{c.text}</p>)}
                  <button
                    className="stacked__card__button heatfeed__back__button"
                    onClick={()=>navigate(-2)}
                  >
                    zurück
                  </button>
                </>
                :
                // Entry autogenerated from Card
                <>
                  <p className="heatfeed__p padding__top__p">{entry.paragraph}</p>
                  <p className="heatfeed__p">{entry.description}</p>
                  <button
                    className="stacked__card__button heatfeed__back__button"
                    onClick={handleBack}
                  >
                    zurück
                  </button>
                </>

              }
            </section>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Blog;
