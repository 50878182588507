import "../../assets/css/heatFeed.css";

import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navigation from "../../layouts/Navigation";
import Footer from "../../layouts/Footer";

import Newsletter from "../../layouts/Newsletter";

import CardCarousel from "../../components/CardCarousel";

function Academy({ loader, loading }) {
  let location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {loading && loader}
      <div>
        <Navigation {...{ subMenu: false }} />
        <main id="main" className="main">
          <div className="heatfeed__container">
            <header className="heatfeed__header" />

            <section className="heatfeed__section__text">
              <h2>{t("products.academy.title")}</h2>
              <p className="heatfeed__p padding__top__p">
                Die Academy für fortschrittliche Heizsysteme richtet sich
                gezielt an eine breite Palette von Interessenten, die in den
                Bereichen Vermietung, Verwaltung, Facility-Management,
                Objektbetreuung und Handwerk tätig sind, sowie an jeden, der
                sein Wissen über effiziente Wärmetechnik erweitern möchte. Das
                Programm ist sorgfältig konzipiert, um die spezifischen
                Bedürfnisse und Herausforderungen dieser Zielgruppen zu
                adressieren, indem es praxisnahe Lösungen und tiefgreifendes
                Fachwissen vermittelt. Teilnehmer profitieren von einem
                Curriculum, das auf dem neuesten Stand der Technik basiert und
                von führenden Experten in der Branche entwickelt wurde.
              </p>
              <h5>Das Angebot umfasst:</h5>
              <ul className="heatfeed__ul">
                <li>
                  <u>Für Vermieter und Verwalter:</u> Strategien zur Steigerung
                  der Energieeffizienz in Immobilienportfolios, was nicht nur
                  Kosten senkt, sondern auch den Wert der Immobilien steigern
                  kann.
                </li>
                <li>
                  <u>Für Hausmeister und Objektbetreuer:</u> Praktische
                  Anleitungen zur Wartung und Optimierung von Heizsystemen, um
                  die Betriebssicherheit und Zufriedenheit der Bewohner zu
                  gewährleisten.
                </li>
                <li>
                  <u>Für Handwerker:</u> Vertiefende Kurse zu Installation,
                  Instandhaltung und Reparatur moderner Heizsysteme, die eine
                  hohe Nachfrage in der Branche generieren.
                </li>
                <li>
                  <u>Für alle Interessierten:</u> Grundlagen und
                  fortgeschrittene Konzepte der effizienten Wärmetechnik, um
                  umweltfreundlichere und kostensparende Heizlösungen zu
                  fördern.
                </li>
              </ul>
              <p className="heatfeed__p">
                Die Academy versteht sich als Partner auf dem Weg zu einem
                nachhaltigeren, effizienteren Heizungsbetrieb und bietet allen
                Teilnehmern die Werkzeuge, das Wissen und die Unterstützung, um
                in diesem wichtigen Sektor erfolgreich zu sein.
                <br />
                <br />
                Aktuell ist eine Anmeldung nur über unser Büro möglich, bitte
                nehmen Sie bei Interesse direkt mit uns Kontakt auf!
              </p>
            </section>
            <section>
              {/* <div className="pre__footer__buttons"> */}
              <div className="heatfeed__button__bar">
                <Link
                  className="stacked__card__button heatfeed__product__button button_pulse"
                  to="/contact"
                >
                  {t("getInTouch")}
                </Link>
                {/* <Link className="pre__footer__button" style={{ color: "white", backgroundColor: "black" }} to='/contact'>{t('getInTouch')}</Link> */}
              </div>
            </section>
            {/* <section className="heatfeed__card__container">
              <div className="heatfeed__card__div">
                {entrys.map((entry) => { */}
            {/* <Link  */}

            {/* //   className="heatfeed__entry"
                  //   key={entry.id}
                  //   to={`/about/${entry.id}`}
                  //   state={{ ...entry }}
                  // >
                  //   <img src={entry.thumbnail} />
                  //   <p>{entry.heading}</p>
                  // </Link>

                  //     let position =
                  //       n > index
                  //         ? "nextCard"
                  //         : n === index
                  //         ? "activeCard"
                  //         : "prevCard"; */}

            {/* //       return (
            //         <HeatFeedCard */}
            {/* //           key={entry.id}
            //           {...entry}
            //           // cardStyle={position}
            //         />
            //       );
            //     })}
            //   </div>
            // </section> */}
          </div>

          {/* <Blog /> */}
        </main>
        <Footer />

        <Newsletter />
      </div>
    </>
  );
}

export default Academy;
