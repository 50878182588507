import { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { Navigate } from "react-router";
import Homepage from './pages/Homepage';
// import FAQ from './pages/FAQ';
import Jobs from './pages/Jobs';
import Job from './pages/Job';
import Terms from './pages/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy';
// import EnergieServices from './pages/EnergieServices';
import EnergieService from './pages/EnergieService';
import Contact from './pages/Contact';
import Imprint from './pages/Imprint';
import WizardForm from './layouts/WizardForm';
import anime from 'animejs/lib/anime.es.js';
import gsap from "gsap";
import { Helmet, HelmetProvider } from "react-helmet-async";
import HubspotContactForm from './components/HubspotContactForm';
import Gluecksrad from './pages/Gluecksrad';
import HeatFeed from './pages/HeatFeed';
import Blog from './components/Blog';
import MonitoringOptimization from './pages/products/Monitoring_Optimierung';
import HydrBalancing from './pages/products/Hydraulischer_Abgleich';
import Software from './pages/products/Software';
import Academy from './pages/products/Academy';
import { useTranslation } from 'react-i18next';
import Navigation from './layouts/Navigation';
import Footer from './layouts/Footer';

import HubspotForm from './components/HubspotForm';
import TestKit from './pages/craft/TestKit';


const Loader = ({ start, setLoading }) => {
  useEffect(() => {
    if (!start) return;

    window.scrollTo(0, 0);

    var textWrapper = document.querySelector(".loader__text");
    textWrapper.innerHTML = textWrapper.textContent.replace(
      /\S/g,
      "<span class='letter'>$&</span>"
    );

    anime.timeline().add({
      targets: ".loader__text .letter",
      translateX: [40, 0],
      translateZ: 0,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 1200,
      delay: (el, i) => 2400 + 30 * i
    })

    gsap.from(".loader__wrapper", 2, {
      scale: 0.8,
      ease: "power1.inOut"
    })

    gsap.from(".loader", 2, {
      top: "100%",
      ease: "power3.inOut"
    })

    gsap.to(".loader", 2.5, {
      top: "0%",
      ease: "power3.inOut"
    })

    gsap.to(".loader__wrapper, .pre__loader", 1, {
      scale: 1.5,
      opacity: 0,
      display: "none",
      ease: "power3.inOut",
      delay: 2
    }, "-=")

    gsap.from(".revealer", 1, {
      width: 0,
      ease: "power3.inOut",
      delay: 3
    })

    gsap.to(".revealer", 0.8, {
      left: "100%",
      ease: "power3.inOut",
      delay: 4,
      onComplete: () => { setLoading(false); localStorage.setItem("sigmaheatLoader", true) }
    })

    gsap.to(".loader__text__wrapper", 0.2, {
      opacity: 0,
      display: "none",
      ease: "power3.inOut",
      delay: 4
    })
  }, [start, setLoading])

  return (
    <>
      <div className="loader__text__wrapper block">
        <h1 className="loader__text">SigmaHeat</h1>
      </div>
      <div className="revealer"></div>
      <div className="pre__loader block">
        <div className="loader__wrapper">
          <div className="loader"></div>
        </div>
      </div>
    </>
  );
};

function Redirector({ redirect }) {
  useEffect(() => {
    window.location.replace(redirect.to);
  }, [window.location]);

  return null;
}

function App() {
  const [loading, setLoading] = useState(localStorage.getItem("sigmaheatLoader") === null ? true : false);
  const [start, setStart] = useState(false);

  const redirects = [
    {
      path: "app",
      to: "https://app.sigmaheat.de"
    },

    {
      path: "partnering",
      to: "https://share-eu1.hsforms.com/12dMT2-46Sta6o-GsuVg8hAewvig"
    },

    {
      path: "beta-test",
      to: "https://share-eu1.hsforms.com/1IkNesf9YRX2NZ2vF64ptzAewvig"
    }
  ]

  const acceptsInput = (e) => {
    if (!e) return false
    let tag = e.tagName;
    return tag === 'INPUT' || tag === 'SELECT' || tag === 'TEXTAREA' || e.isContentEditable || e.tabIndex >= 0;
  } 

  useEffect(() => {
    setStart(true);

    document.addEventListener('touchend', (e) => {
      let target = e.target;
      if (!acceptsInput(target)) document.activeElement.blur()
    })
  }, []);

  useEffect(() => {
    if (loading) {
      document.querySelector('body').classList.add('overflow');
    } else {
      document.querySelector('body').classList.remove('overflow');
    }
  }, [loading])

  return (
    <>
      <HelmetProvider>
        <Helmet htmlAttributes={{ lang: 'de' }} />
      </HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} />
          {/* <Route path="/faq" element={<FAQ {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} /> */}
          <Route path="/jobs" element={<Jobs {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} />
          <Route path="/job/:jobid" element={<Job {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} />
          {/* <Route path="/energieservices" element={<EnergieServices {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} /> */}
          {/* <Route path="/energieservices" element={<EnergieService {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} /> */}
          <Route path="/jobapply" element={<WizardForm {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} />
          <Route path="/jobapply/:jobId" element={<WizardForm {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} />
          {/* <Route path="/terms" element={<Terms {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} /> */}
          <Route path="/privacy-policy" element={<PrivacyPolicy {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} />
          <Route path="/contact" element={<Contact {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} />
          <Route path="/imprint" element={<Imprint {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} />

          {redirects?.map(redirect => <Route path={`/${redirect.path}`} element={<Redirector {...{ redirect }} />} />)}


          <Route path="/teilnahmebedingungen-gewinnspiel" element={<Gewinnspiel {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} />
          <Route path="/teilnahmebedingungen-verlosung" element={<Verlosung {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} />


          <Route path="/blog/:id" element={<Blog {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} />
          <Route path="/blog" element={<HeatFeed {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} />

          <Route path="/products/test-kit" element={<TestKit {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} />
          <Route path="/products/monitoring_optimierung" element={<MonitoringOptimization {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} />
          <Route path="/products/hydraulischer_abgleich" element={<HydrBalancing {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} />
          {/* <Route path="/products/software" element={<Software {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} /> */}
          <Route path="/products/academy" element={<Academy {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} />

          <Route path="/partneranmeldung" element={<HubspotForm {...{ portalId: "25048456", formId: "92f105b3-34a7-4b40-807e-8e642126f66f" }}/>} />

          {/* <Route path="/partneranmeldung" element={<HubspotContactForm {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} /> */}
          <Route path="/spiel/gluecksrad" element={<Gluecksrad {...{ loading, loader: <Loader {...{ start, setLoading }} /> }} />} />

          <Route path="*" element={<Navigate to={"/"} replace />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}



function Gewinnspiel({ loader, loading }) {
  const { t } = useTranslation();
  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])

  return (
    <>
      {loading && loader}
      <div>
        <Navigation {...{ subMenu: false, boolean: true }} />
        <main id='main' className='main'>
          <section className='privacy__wrapper'>
            <div className='privacy__container'>
              <h1 className='terms__heading'>🎉 Gewinnspiel-Teilnahmebedingungen SHK-Essen</h1>
              <p class='terms__paragraph'>
                Die SigmaHeat GmbH, <br />
                Lange Laube 2, <br />
                30159 Hannover <br />
                E-Mail: partner@sigmaheat.de │ Tel: 0800-7446243 <br />
                (nachfolgend „Veranstalter“) veranstaltet das „Partner-Gewinnspiel“. Unter allen Teilnehmern werden vier SigmaHeat-Starterkits und sechs Marken-Laserentfernungsmessgeräte verlost. Jeder Teilnehmer erklärt sich mit der Teilnahme an dieser Aktion mit den nachstehenden Teilnahmebedingungen (und der Datenschutzerklärung) einverstanden.
              </p>

              <h3 class='terms__heading3'>1. Teilnahmeberechtigung</h3>
              <p class='terms__paragraph'>
                1.1 Teilnahmeberechtigt sind alle natürlichen Personen in eigenem Namen mit einem Mindestalter von 18 Jahren und juristische Personen. <br />
                1.2 Von der Teilnahme am Gewinnspiel ausgeschlossen sind Mitarbeiter des Gewinnspielveranstalters, sowie Mitarbeiter der an diesem Gewinnspiel beteiligten Unternehmen und deren direkten Angehörige.<br />
                1.3 Der Veranstalter behält sich vor, Personen von der Teilnahme auszuschließen, die gegen diese Teilnahmebedingungen verstoßen oder sich durch unzulässige Beeinflussung oder Manipulation des Gewinnspiels einen Vorteil gegenüber anderen Teilnehmern verschaffen oder verschaffen wollen. Handelt es sich bei dem ausgeschlossenen Teilnehmer um einen bereits ausgelosten Gewinner, kann der Gewinn nachträglich aberkannt werden und ein Ersatzgewinner ausgelost werden.
              </p>

              <h3 class='terms__heading3'>2. Gewinnspiel-Zeitraum</h3>
              <p class='terms__paragraph'>
                Das Gewinnspiel wird im Zeitraum vom 19.03.2024, 09:00 Uhr, bis 22.03.2024, 15:00 Uhr, durchgeführt.
              </p>

              <h3 class='terms__heading3'>3. Teilnahme</h3>
              <p class='terms__paragraph'>
                3.1 Die Teilnahme ist kostenlos.<br />
                3.2 Um teilzunehmen, muss der Teilnehmer ein Teammitglied des Veranstalters auf dem Messestand ansprechen und im Anschluss das online Glücksrad betätigen.<br />
                3.3 Jeder Teilnehmer darf nur einmal an diesem Gewinnspiel teilnehmen.
              </p>

              <h3 class='terms__heading3'>4. Ablauf der Verlosung</h3>
              <p class='terms__paragraph'>
                Es dürfen alle Teilnehmer das Glücksrad starten, die bis zu dem unter Ziffer 2 genannten Zeitpunkt die unter Ziffer 3 genannten Voraussetzungen erfüllen. Durch Starten des Glücksrads wird dieses in Rotation gebracht bis es wieder zum Stillstand kommt und der Dorn das Gewinnsymbol anzeigt.
              </p>

              <h3 class='terms__heading3'>5. Gewinnabwicklung und Kosten</h3>
              <p class='terms__paragraph'>
                5.1 Der Gewinn wird an eine vom jeweiligen Gewinner angegebene Postadresse versendet.<br />
                5.2 Eine Barauszahlung des Gewinnwerts oder ein Umtausch des Gewinns ist ausgeschlossen.<br />
                5.3 Gewinnansprüche können an Dritte nur nach vorheriger Zustimmung des Veranstalters abgetreten oder übertragen werden.<br />
                5.4 Sollten die angegebenen Kontaktmöglichkeiten des Teilnehmers fehlerhaft sein (z.B. Postadresse), ist der Veranstalter nicht verpflichtet, die richtigen Daten zu ermitteln. Die Nachteile, die sich aus der Angabe fehlerhafter Kontaktdaten ergeben, gehen zu Lasten des Teilnehmers.
              </p>

              <h3 class='terms__heading3'>6. Vorzeitige Beendigung, Anpassung oder Änderung des Gewinnspiels</h3>
              <p class='terms__paragraph'>
                Der Veranstalter ist berechtigt, das Gewinnspiel jederzeit ohne Vorankündigung und ohne Angabe von Gründen vorzeitig zu beenden, anzupassen oder abzuändern, wenn eine ordnungsgemäße Durchführung des Gewinnspiels ohne entsprechende Maßnahmen aus technischen oder rechtlichen Gründen nicht gewährleistet werden kann. Den Teilnehmern stehen in einem solchen Fall keine Ansprüche gegen den Veranstalter zu.
              </p>

              <h3 class='terms__heading3'>7. Haftung</h3>
              <p class='terms__paragraph'>
                Bei leicht fahrlässiger Schadensverursachung haften wir nur im Falle der Verletzung einer wesentlichen Vertragspflicht, das heißt einer Verpflichtung, deren Erfüllung den Vertrag prägt und auf die der Vertragspartner regelmäßig vertraut und vertrauen darf, sowie begrenzt auf den vertragstypischen und vorhersehbaren Schaden. Diese Einschränkung gilt weder bei Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit noch in Fällen zwingender gesetzlicher Haftung.
              </p>

              <h3 class='terms__heading3'>8. Sonstiges</h3>
              <p class='terms__paragraph'>
                8.1 Sollten einzelne Bestimmungen der Teilnahmebedingungen ungültig sein oder ungültig werden, bleibt die Gültigkeit der übrigen Teilnahmebedingungen unberührt.<br />
                8.2 Für sämtliche Rechtsbeziehungen der Parteien gilt das Recht der Bundesrepublik Deutschland.
              </p>

              <h3 class='terms__heading3'>Datenschutzerklärung</h3>
              <p class='terms__paragraph'>
                Verantwortlich für Datenverarbeitungen im Zusammenhang mit dem Gewinnspiel ist: SigmaHeat GmbH, Lange Laube 2, 30159 Hannover<br />
                Mit der Teilnahme am Gewinnspiel willigen Sie in die Erhebung und Verwendung Ihres Vor- und Nachnamens sowie einer Postadresse ein. Wir verarbeiten Ihre personenbezogenen Daten zur ordnungsgemäßen Durchführung und Abwicklung des Gewinnspiels und insbesondere zur Versendung des Gewinns.<br />
                Zur Durchführung des Gewinnspiels werden der Vor- und Nachname des jeweiligen Teilnehmers erhoben und gespeichert. Zur Versendung des Gewinns erheben wir von den Gewinnern zusätzlich den Namen und Vornamen sowie die Anschrift und geben diese Daten an ein von uns beauftragtes Versandunternehmen weiter. Eine darüberhinausgehende Weitergabe Ihrer Daten an Dritte erfolgt nicht.<br />
                Sofern wir den Vor- und Zunamen der Gewinner separat auf unserer Instagram-Seite veröffentlichen oder auf unserer Homepage bekanntgeben, erfolgt dies nur bei Vorliegen einer vorher eingeholten Einwilligung der Gewinner.<br />
                Sie können die Einwilligungen jederzeit durch Nachricht an uns widerrufen. Ihre Daten werden anschließend gelöscht.<br />
                Nach Beendigung des Gewinnspiels werden Ihre für die Durchführung des Gewinnspiels verarbeiteten personenbezogenen Daten nach dem Ende des Gewinnspiels gelöscht, sofern keine Einwilligung zur Weiterverarbeitung (etwa bezüglich des Newsletter-Versandes) vorliegt und/oder unsererseits kein berechtigtes Interesse an der Weiterspeicherung fortbesteht.
              </p>



            </div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  )
}



function Verlosung({ loader, loading }) {
  const { t } = useTranslation();
  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])

  return (
    <>
      {loading && loader}
      <div>
        <Navigation {...{ subMenu: false, boolean: true }} />
        <main id='main' className='main'>
          <section className='privacy__wrapper'>
            <div className='privacy__container'>
              <h1 className='terms__heading'>🎉 Verlosung-Teilnahmebedingungen SHK-Essen</h1>

              <div>
                <h3 class='terms__heading3'>Teilnahmebedingungen</h3>
                <p class='terms__paragraph'>
                  Die SigmaHeat GmbH,<br />
                  Lange Laube 2,<br />
                  30159 Hannover,<br />
                  E-Mail: partner@sigmaheat.de │Tel: 0800-7446243,<br />
                  vertreten durch ihren Geschäftsführer Maik Brinkmann<br />
                  (nachfolgend „Veranstalter“) veranstaltet die „SigmaHeat-Verlosung“.<br />
                  Unter allen Teilnehmern werden ein DERBLAUE Komplett-Set, ein Makita Schlagbohrer inkl. Bohrset und ein Knipex Wasserpumpenset verlost.<br />
                  Jeder Teilnehmer erklärt sich mit der Teilnahme an dieser Aktion mit den nachstehenden Teilnahmebedingungen (und der Datenschutzerklärung) einverstanden.<br />
                  Dieses Gewinnspiel steht in keiner Verbindung zu Instagram. Es wird in keiner Weise von Instagram gesponsert, unterstützt oder organisiert und begründet keinerlei Rechtsansprüche gegenüber Instagram. Sämtliche Informationen im Rahmen dieses Gewinnspiels werden ausschließlich vom Veranstalter bereitgestellt.
                </p>

                <h3 class='terms__heading3'>1. Teilnahmeberechtigung</h3>
                <p class='terms__paragraph'>
                  1.1 Teilnahmeberechtigt sind natürlichen Personen, in eigenem Namen und über Unternehmensaccounts, mit einem Mindestalter von 18 Jahren. Teilnehmer, die bereits das sechzehnte Lebensjahr vollendet haben, aber noch nicht volljährig sind, dürfen nur mit Einwilligung der Erziehungsberechtigten am Gewinnspiel mitmachen.<br />
                  1.2 Von der Teilnahme am Gewinnspiel ausgeschlossen sind Mitarbeiter des Gewinnspielveranstalters, sowie Mitarbeiter der an diesem Gewinnspiel beteiligten Unternehmen und deren direkten Angehörige.<br />
                  1.3 Der Veranstalter behält sich vor, Personen von der Teilnahme auszuschließen, die gegen diese Teilnahmebedingungen verstoßen oder sich durch unzulässige Beeinflussung oder Manipulation des Gewinnspiels einen Vorteil gegenüber anderen Teilnehmern verschaffen oder verschaffen wollen. Handelt es sich bei dem ausgeschlossenen Teilnehmer um einen bereits ausgelosten Gewinner, kann der Gewinn nachträglich aberkannt werden und ein Ersatzgewinner ausgelost werden.
                </p>

                <h3 class='terms__heading3'>2. Gewinnspiel-Zeitraum</h3>
                <p class='terms__paragraph'>
                  Das Gewinnspiel wird im Zeitraum vom 19.03.2024, 09:00 Uhr, bis 22.03.2024, 12:00 Uhr, durchgeführt.
                </p>

                <h3 class='terms__heading3'>3. Teilnahme</h3>
                <p class='terms__paragraph'>
                  3.1 Die Teilnahme ist kostenlos.<br />
                  3.2 Um teilzunehmen, muss der Teilnehmer ein Bild von sich auf dem Messestand auf der Messe Essen, Halle 3 Messestand 3B48 des Veranstalters aufnehmen, bei Instagram hochladen und die Instagram-Seite des Veranstalters (Instagram-Name: sigmaheat) markieren. Weitere Voraussetzung für die Teilnahme ist das Folgen der Instagram-Seite des Veranstalters.<br />
                  3.3 Jeder Teilnehmer darf nur einmal an diesem Gewinnspiel teilnehmen.
                </p>

                <h3 class='terms__heading3'>4. Ablauf der Verlosung</h3>
                <p class='terms__paragraph'>
                  Es werden alle Teilnehmer bei der Verlosung berücksichtigt, die bis zu dem unter Ziffer 2 genannten Zeitpunkt die unter Ziffer 3 genannten Voraussetzungen erfüllen. Unmittelbar nach Ablauf der Teilnahmefrist werden die Gewinner unter den Teilnehmern nach dem Zufallsprinzip ausgelost.
                </p>

                <h3 class='terms__heading3'>5. Gewinnbenachrichtigung und fristgebundene Gewinnannahme</h3>
                <p class='terms__paragraph'>
                  5.1 Nach der Auslosung werden die Gewinner in der Instagram-Story des Veranstalters verlinkt und auf diese Weise benachrichtigt. Diese Benachrichtigung der Gewinner erfolgt ohne Gewähr.<br />
                  5.2 Jeder Gewinner muss innerhalb von 14 Tagen nach Bekanntgabe des Gewinns dem Veranstalter mittels „Direct Message“ seine Postadresse mitteilen. An diese Adresse wird der Gewinn geschickt. Falls der Veranstalter innerhalb dieser Frist keine entsprechende Nachricht erhält, verfällt die Möglichkeit der Annahme des Gewinns und der Veranstalter behält sich vor, einen anderen Teilnehmer zu ermitteln und entsprechend zu benachrichtigen.
                </p>

                <h3 class='terms__heading3'>6. Gewinnabwicklung und Kosten</h3>
                <p class='terms__paragraph'>
                  6.1 Der Gewinn wird an eine vom jeweiligen Gewinner angegebene Postadresse versendet.<br />
                  6.2 Eine Barauszahlung des Gewinnwerts oder ein Umtausch des Gewinns ist ausgeschlossen.<br />
                  6.3 Gewinnansprüche können an Dritte nur nach vorheriger Zustimmung des Veranstalters abgetreten oder übertragen werden.<br />
                  6.4 Sollten die angegebenen Kontaktmöglichkeiten des Teilnehmers fehlerhaft sein (z.B. Postadresse), ist der Veranstalter nicht verpflichtet, die richtigen Daten zu ermitteln. Die Nachteile, die sich aus der Angabe fehlerhafter Kontaktdaten ergeben, gehen zu Lasten des Teilnehmers.
                </p>

                <h3 class='terms__heading3'>7. Vorzeitige Beendigung, Anpassung oder Änderung des Gewinnspiels</h3>
                <p class='terms__paragraph'>
                  Der Veranstalter ist berechtigt, das Gewinnspiel jederzeit ohne Vorankündigung und ohne Angabe von Gründen vorzeitig zu beenden, anzupassen oder abzuändern, wenn eine ordnungsgemäße Durchführung des Gewinnspiels ohne entsprechende Maßnahmen aus technischen oder rechtlichen Gründen nicht gewährleistet werden kann. Den Teilnehmern stehen in einem solchen Fall keine Ansprüche gegen den Veranstalter zu.
                </p>

                <h3 class='terms__heading3'>8. Haftung</h3>
                <p class='terms__paragraph'>
                  Bei leicht fahrlässiger Schadensverursachung haften wir nur im Falle der Verletzung einer wesentlichen Vertragspflicht, das heißt einer Verpflichtung, deren Erfüllung den Vertrag prägt und auf die der Vertragspartner regelmäßig vertraut und vertrauen darf, sowie begrenzt auf den vertragstypischen und vorhersehbaren Schaden. Diese Einschränkung gilt weder bei Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit noch in Fällen zwingender gesetzlicher Haftung.
                </p>

                <h3 class='terms__heading3'>9. Sonstiges</h3>
                <p class='terms__paragraph'>
                  9.1 Sollten einzelne Bestimmungen der Teilnahmebedingungen ungültig sein oder ungültig werden, bleibt die Gültigkeit der übrigen Teilnahmebedingungen unberührt.<br />
                  9.2 Für sämtliche Rechtsbeziehungen der Parteien gilt das Recht der Bundesrepublik Deutschland.
                </p>

                <h3 class='terms__heading3'>Datenschutzerklärung</h3>
                <p class='terms__paragraph'>
                  Verantwortlich für Datenverarbeitungen im Zusammenhang mit dem Gewinnspiel ist:<br />
                  SigmaHeat GmbH,<br />
                  Lange Laube 2,<br />
                  30159 Hannover.<br />
                  Die benannte Verantwortliche ist für die Datenverarbeitungen auf der Instagram Seite, auf der das Gewinnspiel veranstaltet wird, zusammen mit Instagram (Plattformbetreiber ist die Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2 Ireland) gemeinsam verantwortlich i.S.d. Art. 26 DSGVO.<br />
                  Mit der Teilnahme am Gewinnspiel willigen Sie in die Erhebung und Verwendung Ihres Profilnamens und, sofern vorhanden, Vor- und Nachnamen ein. Wir verarbeiten Ihre personenbezogenen Daten zur ordnungsgemäßen Durchführung und Abwicklung des Gewinnspiels und insbesondere zur Versendung des Gewinns. Zur Durchführung des Gewinnspiels werden der Profilname, und, sofern vorhanden, Vor- und Nachname des jeweiligen Teilnehmers erhoben und gespeichert. Zur Versendung des Gewinns erheben wir von den Gewinnern zusätzlich den Namen und Vornamen sowie die Anschrift und geben diese Daten an ein von uns beauftragtes Versandunternehmen weiter. Eine darüberhinausgehende Weitergabe Ihrer Daten an Dritte erfolgt nicht.<br />
                  Sofern wir den Vor- und Zunamen der Gewinner separat auf unserer Instagram-Seite veröffentlichen oder extern auf unserer Homepage bekannt geben, erfolgt dies nur bei Vorliegen einer vorher eingeholten Einwilligung der Gewinner.<br />
                  Sie können die Einwilligungen jederzeit durch Nachricht an uns widerrufen. Ihre Daten werden anschließend gelöscht.<br />
                  Nach Beendigung des Gewinnspiels werden Ihre im Rahmen des Gewinnspiels verarbeiteten personenbezogenen Daten gelöscht, sofern keine wirksame Einwilligung zur Weiterverarbeitung vorliegt und/oder unsererseits kein berechtigtes Interesse an der Weiterspeicherung fortbesteht.
                </p>
              </div>



            </div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  )
}





export default App