import "../../assets/css/heatFeed.css";

import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navigation from "../../layouts/Navigation";
import Footer from "../../layouts/Footer";

import Newsletter from "../../layouts/Newsletter";

import CardCarousel from "../../components/CardCarousel";

function MonitoringOptimization({ loader, loading }) {
  let location = useLocation();

  const { t } = useTranslation();
  const texte = t("products.monitoring_optimization.texte", {
    returnObjects: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {loading && loader}
      <div>
        <Navigation {...{ subMenu: false }} />
        <main id="main" className="main">
          <div className="heatfeed__container">
            <header className="heatfeed__header" />

            <section className="heatfeed__section__text">
              <h2>{t("products.monitoring_optimization.title")}</h2>
              {texte.map((text) => (
                <>
                  {text.title && <h5>{text.title}</h5>}
                  {!text.li && <p className="heatfeed__p">{text.text}</p>}
                  {text.li && (
                    <ul className="heatfeed__ul">
                      {text.li &&
                        text.li.map((l) => (
                          <li>
                            <u>{l.title}:</u> {l.text}
                          </li>
                        ))}
                    </ul>
                  )}
                  <div className="heatfeed__button__bar">
                    {text.button && (
                      <Link
                        className="stacked__card__button heatfeed__product__button button_pulse"
                        to={text.button.to}
                        style={{
                          minWidth: "120px",
                          textAlign: "center",
                          marginBottom: "20px",
                        }}
                      >
                        {text.button.text}
                      </Link>
                    )}
                  </div>
                </>
              ))}
            </section>
            {/* <section className="heatfeed__card__container">
              <div className="heatfeed__card__div">
                {entrys.map((entry) => { */}
            {/* <Link  */}

            {/* //   className="heatfeed__entry"
                  //   key={entry.id}
                  //   to={`/about/${entry.id}`}
                  //   state={{ ...entry }}
                  // >
                  //   <img src={entry.thumbnail} />
                  //   <p>{entry.heading}</p>
                  // </Link>

                  //     let position =
                  //       n > index
                  //         ? "nextCard"
                  //         : n === index
                  //         ? "activeCard"
                  //         : "prevCard"; */}

            {/* //       return (
            //         <HeatFeedCard */}
            {/* //           key={entry.id}
            //           {...entry}
            //           // cardStyle={position}
            //         />
            //       );
            //     })}
            //   </div>
            // </section> */}
          </div>

          {/* <Blog /> */}
        </main>
        <Footer />

        <Newsletter />
      </div>
    </>
  );
}

export default MonitoringOptimization;
