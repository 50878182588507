import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        featuredJobs: "Featured jobs",
        heatedEnough: "Heated up enough already?",
        howWeHire: "How we hire",
        readOurFaq: "Read our FAQ",
        communities: "Communities",
        stayUpdated: "Stay Updated",
        career: "Career",
        diversity: "Diversity, Equity & Impact",

        design: "Design",
        developers: "Developers",
        brands: "Brands",
        artists: "Artists",
        engineering: "Engineering",
        research: "Research",
        latest: "Latest",
        instagram: "Instagram",
        linkedin: "LinkedIn",
        twitter: "Twitter",
        youtube: "YouTube",
        podcast: "Podcast",
        locations: "Locations",
        beingHere: "Being Here",
        students: "Students",
        allJobs: "All Jobs",
        mentalHealth: "Mental Health",
        diversityEquityInclusionBelonging:
          "Diversity, Equity, Inclusion & Belonging",
        socialImpact: "Social Impact",
        climateAction: "Climate Action",
        sigmaHeat: "© 2021-2024 SigmaHeat",
        terms: "Terms and Conditions",
        privacyPolicy: "Privacy Policy",

        freshContent: "Fresh content",
        discoverMore: "Discover More",

        applyNow: "Apply Now",
        description:
          "Do you love being in the flow as a developer and want to enable that for everyone else as well? Do you believe that the key to personal flow is in making the local developer build experience fast, well-integrated, automated and with meaningful insights? We are seeking a collaborative and curious Sr Engineer to help drive and develop our CI infrastructure forward.",
        location: {
          title: "Location",
          content: "London",
        },
        type: {
          title: "Job Type",
          content: "Permanent",
        },
        whatYouDo: "What You'll Do",
        extensiveLearningOpportunities: "Extensive learning opportunities",
        extensiveLearningOpportunitiesDesc:
          "through our dedicated team, GreenHouse.",
        flexibleShareIncentives: "Flexible share incentives",
        flexibleShareIncentivesDesc:
          "letting you choose how you share in our success.",
        globalParentalLeave: "Global parental leave",
        globalParentalLeaveDesc:
          "six months off - fully paid - for all new parents.",
        allTheFeels: "All The Feels",
        allTheFeelsDesc: "our employee assistance program and self-care hub.",
        flexiblePublicHolidays: "Flexible public holidays",
        flexiblePublicHolidaysDesc:
          "swap days off according to your values and beliefs.",
        similarJobs: "Similar jobs",
        productEmployee: "Production Employee",
        sigmaHeatConsultant: "SigmaHeat Consultant",
        technicalInternship: "Technical Internship",
        permanent: "permanent",
        intern: "intern",
        seeAllJobs: "See all similar jobs",
        showMoreJobs: "Show more jobs",
        "part-time": "Part Time",
        "full-time": "Full Time",
        "full-or-part-time": "Full or Part Time",
        department: "Department",
        jobs: "jobs",
        "all-locations": "all locations",
        "all-departments": "all departments",
        "all-job-types": "all job types",
        locationsHeading: "Here, there, everywhere",
        locationsParagraph:
          "Audio is global, so we are too. Wherever in the world you're looking for a new role, chances are, we're there.",
        seeAllLocationsAriaLabel: "See all locations",
        seeAllLocations: "See all locations",
        mainCtaHeading: "Beat the Heat",
        mainCtaButton: "Explore all jobs",
        searchForJobs: "Search for jobs",
        searchJobsAndKeywords: "Search jobs and keywords",
        search: "Search",

        navigation: {
          mission: "The Mission",
          energyServices: "Energy Services",
          blog: "HeatFeed",
          faq: "FAQ",
          login: "Login",
          manifesto: "The Band Manifesto",
          workFromAnywhere: "Work From Anywhere",
          growWithUs: "Grow With Us",
          jobCategories: "Job Categories",
        },

        quickClicks: {
          heading: "Quick clicks",
          categories: {
            text: "categories",
            linkText: "Discover more about our ",
          },
          students: {
            text: "Students",
            linkText: ", make an impact not the coffees",
          },
          beingHere: {
            text: "Read about ",
            linkText: "being here",
          },
        },

        ourClients: "In good company",
        clientsDescription:
          "Our diverse range of clients includes individuals, small businesses, startups, and Fortune 500 companies across various industries.",

        activeNoiseCancellation:
          "Active Noise Cancellation for immersive sound.",
        transparencyMode:
          "Transparency mode for hearing what's happening around you.",
        customizableFit: "A customizable fit for all-day comfort.",
        magicLikeYouveNeverHeard: "Magic like you've never heard.",

        whoWeAre: "Who we are",
        oneBand: "One band, no solo artists",
        whoWeAreParagraph:
          "It's our culture. It's our values. It's who we are and what we're not. It's why we do things the way we do and why that matters.",
        whereWeBelong: "Where we belong",
        weAllHaveParts: "We all have parts to play",
        whereWeBelongParagraph:
          "We want you to feel like you belong here and can thrive here. No two creators or listeners are the same, so neither are we.",
        howWeAct: "How we act",
        listeningAndLearning: "Listening and learning",
        howWeActParagraph:
          "We're not just shaping the future of audio. We want to help shape a better future for society and the planet too. Bit by bit. Day by day.",
        readOurManifesto: "Read our manifesto",

        reviewHeading: "Review and submit you application for: ",
        privacyPolicyConfirmation:
          "By checking this box, I confirm that I have read and agree to the ",
        privacyPolicyLink: "privacy policy",
        submit: "Submit",
        firstNameQuestion: "What is your first name?",
        firstNamePlaceholder: "First Name",
        firstNameError: "Error message for first name",
        lastNameQuestion: "What is your last name?",
        lastNamePlaceholder: "Last Name",
        lastNameError: "Error message for last name",
        emailQuestion: "What is your email address?",
        emailPlaceholder: "Email address",
        emailError: "Error message for email address",
        phoneQuestion: "What is your phone number?",
        phonePlaceholder: "Phone number",
        phoneError: "Error message for phone number",
        availabilityQuestion: "When do you think that you're available?",
        availabilityPlaceholder: "Available",
        availabilityError: "Error message for availability",
        payQuestion: "How much you think that you should be paid?",
        payPlaceholder: "Blink Blink",
        payError: "Error message for pay",
        cvQuestion: "Curriculum Vitae please",
        cvPlaceholder: "CV",
        cvError: "Error message for CV",
        docsQuestion: "Other possible documents",
        docsPlaceholder: "Docs",
        optionalText:
          "Here you can send us another message or specify who recruited you from our team",
        docsError: "Error message for documents",
        previous: "Previous",
        next: "Next",
        review: "Review",

        termsnconditions: {
          heading: "General Terms and Conditions (GTC) of SigmaHeat GmbH",
          scopeChanges: "Scope, Changes",
          generalTerms1:
            "These general terms and conditions (AGB) apply to all contracts between SigmaHeat GmbH, represented by managing director Maik Brinkmann, Lange Laube 2, 30159 Hannover (hereinafter referred to as 'SigmaHeat') and its customers. They apply in their current version at the time of conclusion of the contract.",
          generalTerms2:
            "Deviating terms and conditions of the customer do not apply to the concluded contracts, even if SigmaHeat has not expressly objected to them.",
          generalTerms3:
            "If the parties make agreements deviating from these AGB, these shall take precedence over the provisions of the AGB. Deviating agreements which are made after conclusion of the contract require text form for their effectiveness.",
          generalTerms4:
            "SigmaHeat exclusively concludes contracts with entrepreneurs within the meaning of § 14 BGB, i.e. with persons who pursue a self-employed professional (secondary) activity. The customer therefore assures with the conclusion of the contract that he is an entrepreneur in this sense and concludes the contract in this capacity. No contractual relationships are entered into with persons who act as consumers within the meaning of § 13 BGB. The customer is obliged to provide SigmaHeat with corresponding proof of his entrepreneurial activity (e.g. commercial register extract) upon request.",
          generalTerms5:
            "Changes to these terms and conditions will be notified to customers by e-mail. If customers do not object to a change within two weeks of receipt of the notification, the changes will be deemed accepted. Customers will be separately informed of their right to object and the legal consequences of silence in the e-mail notification.",
          scopeAgreement: "Scope, Agreement",
          servicesOffered:
            "SigmaHeat offers services in the field of thermal technology to reduce heating costs, monitor efficiency and increase environmental friendliness. The aim of SigmaHeat's services is to optimize the operation of heating systems in such a way that heating costs and CO2 emissions can be saved. With the help of a system developed by SigmaHeat, the running costs for the operation of a heating system can be regularly reduced by up to 25%. There is no guarantee of a specific cost reduction. Depending on the nature of the respective heating system and other operating conditions, the possible successes are individual and vary from system to system. SigmaHeat does not owe any specific cost reduction, but always tries to achieve the best possible result for the optimization of the heating system depending on the existing conditions of the customer.",
          servicesIncluded:
            "Depending on the individual agreement between the parties, some or all of the following services are covered:",
          messtechnik:
            "Provision of the required measuring technology compatible with the SigmaHeat system",
          installation:
            "Installation of the required measuring technology in the existing heating infrastructure and connection to the SigmaHeat system",
          remoteMonitoring:
            "Remote monitoring and testing of the operational readiness and safety of the heating system",
          measurementData:
            "Provision of measurement data, alarms and evaluations within the framework of the SigmaHeat app",
          softwarePackage:
            "Provision of the SigmaHeat software as part of a Software as a Service (SaaS) package",
          dataAnalysis: "Analysis of the collected measurement data",
          optimization:
            "Optimization of the heating system through adjustments of the setting parameters based on the analysis",
          contractConclusion:
            "The contract between SigmaHeat and the customer is usually concluded via e-mail, by telephone or via the SigmaHeat website. The acceptance of the contract offer by the customer is made by express declaration (order confirmation) from SigmaHeat by e-mail.",
          contractTerms:
            "The contractual provisions with information on the ordered deliveries of goods and/or services including these general terms and conditions will be sent to the customer for storage by e-mail with the order confirmation or handed out in paper form.",
          generalTerms: "General Terms",
          serviceDescriptions:
            "Descriptions and representations of SigmaHeat's services (e.g. on websites and/or in advertising brochures) are always non-binding and not to be understood as a guarantee or assurance of a property. Statements regarding the scope of services only constitute guarantees or assurances in the legal sense if they are made in writing and expressly named as 'guarantee' or 'assurance'.",
          subcontractors:
            "SigmaHeat is entitled to use one or more subcontractors to fulfill the contract.",
          customerObligations: "Customer Obligations",
          unhinderedAccess:
            "The customer ensures that SigmaHeat can carry out any work commissioned on the agreed objects without hindrance. This includes in particular ensuring unimpeded access to the objects, providing free electricity and water, using driveways and elevators, and creating the technical conditions for on-site installation of measuring equipment.",
          completeAndCorrectInformation:
            "The customer is obliged to provide the necessary information for the calculation of the services fully and correctly. This includes in particular the specification of the correct square meter areas of the agreed objects.",
          protectionOfTechnology:
            "The customer ensures that the technology installed by SigmaHeat in the objects is not accessible to third parties (e.g. tenants). Any distortions of the measured values that may occur due to the influence of third parties are the sole responsibility of the customer and do not give rise to any claims against SigmaHeat.",
          reservationOfOwnership: "Reservation of Ownership",
          ownedBySigmaHeat:
            "Provided measuring equipment remains the property of SigmaHeat until full payment is made in case of purchase option. If the customer is in default of payment for more than 14 days, SigmaHeat has the right to withdraw from the contract and demand the return of the equipment provided in full.",
          resaleOfEquipment:
            "The customer is entitled to resell the measuring equipment subject to retention of title in the ordinary course of business. In this case, the customer already assigns to SigmaHeat all claims arising from such resale up to the amount of the invoice value of SigmaHeat's claim. Notwithstanding SigmaHeat's authority to collect the claim itself, the customer remains authorized to collect the claim after the assignment. In this context, SigmaHeat undertakes not to collect the claim itself as long as the customer meets its payment obligations, no application has been made for the opening of insolvency or similar proceedings against its assets, and no suspension of payments exists. Insofar as the above-mentioned securities exceed the claims to be secured by more than 10%, SigmaHeat is obliged to release the securities at the request of the customer.",
          deliveryTime: "Delivery, Time of Performance",
          deliveryDescription1:
            "If the service provided by SigmaHeat exclusively concerns the delivery of measurement technology, delivery will be made within Germany using DHL. Delivery time is up to 14 days. Deviations and agreements made individually with the customer take priority. Any shipping costs will be listed separately on the invoice. Separate shipping costs apply for deliveries outside of Germany.",
          deliveryDescription2:
            "If the delivery of the measurement technology is not made or not made in accordance with the contract, the customer must set a grace period of 2 weeks to effect the performance. Otherwise, the customer is not entitled to withdraw from the contract.",
          deliveryDescription3:
            "If installation of the measurement technology, as well as monitoring, and, if necessary, analysis and optimization of the heating system have been agreed upon with the customer, installation will usually take place within 90 days after order confirmation. Depending on the order situation and in exceptional cases, this period can extend up to 120 days. In this case, the customer will be informed in writing about the delay. Monitoring and optimization of the heating system begins immediately after installation of the measurement technology.",
          saasDescription:
            "If the customer has purchased the Software as a Service (SaaS) package, the service will begin immediately after implementation of the SigmaHeat software. The continuous uninterrupted provision of the software, as well as the continuous uninterrupted analysis of data and their transmission, is not owed.",
          warranty: "Warranty",
          warrantyDescription1:
            "The statutory provisions apply, unless otherwise provided below.",
          warrantyDescription2:
            "If the delivered goods are defective, the customer is entitled, in accordance with the statutory provisions, to request subsequent performance in the form of rectification of the defect or delivery of goods free of defects. SigmaHeat has the right to choose the type of subsequent performance. Subsequent performance does not include the removal or reinstallation of the defective goods if SigmaHeat was not originally obligated to install them.",
          warrantyDescription3:
            "A prerequisite for any warranty claims is that the customer fulfills all inspection and notification obligations owed in accordance with § 377 HGB. The notification pursuant to § 377 (1) HGB is considered immediate if it is made within 5 working days, whereby the timely dispatch of the notification is sufficient to meet the deadline.",
          warrantyDescription4:
            "The statute of limitations for warranty claims for the delivered goods is twelve months from receipt of the goods, except in the case of claims for damages.",
          warrantyDescription5:
            "SigmaHeat is always committed to customer satisfaction and tries to find a satisfactory solution in all cases. Should SigmaHeat, in exceptional cases, fail to remedy a reported defect within a period of 90 days, the customer can remove the object affected by the defect from the contractual agreement and demand reimbursement of already billed costs from the occurrence of the defect. SigmaHeat can offset such contractual services, which are advantageous to the customer despite the defect, against the customer's claim for reimbursement.",
          compensationDueDate: "Compensation, Due Date",
          compensationDescription1:
            "The amount of compensation is determined by the agreement between the parties. It becomes due for payment immediately upon proper invoicing for the first calendar year (short year) unless otherwise agreed between the parties. For further calendar years, the agreed compensation is to be paid annually in advance upon proper invoicing.",
          compensationDescription2:
            "For pure Software as a Service (SaaS) services (no provision or installation of hardware), the agreed compensation is to be paid monthly or annually in advance upon proper invoicing, depending on the agreement.",
          compensationDescription3:
            "If the parties have made different contractual agreements regarding compensation and due date, these take precedence over the General Terms and Conditions.",
          creditreformDescription:
            "SigmaHeat is a contracting party of Creditreform Hannover-Celle Bissel KG, Freundallee 11a, 30173 Hannover. The customer agrees that SigmaHeat will transmit data to Creditreform Rating AG regarding the commissioning, initiation, and proper execution of the business relationship between the parties. Independent of this, SigmaHeat will also transmit data due to non-contractual behavior. These notifications will only be made if this is permissible after weighing all affected interests. Creditreform stores and transmits the data to its contractual partners in the EU internal market to provide information to them for the assessment of the creditworthiness of companies. Contractual partners of Creditreform are primarily credit institutions, credit card, and leasing companies. In addition, Creditreform also provides information to commercial, telecommunications, and other companies that grant credit for services and deliveries. Creditreform only provides personal data if a legitimate interest in doing so has been credibly demonstrated in individual cases. For debtor tracing, Creditreform discloses address data. When providing information, Creditreform may also provide its contractual partners with a probability value calculated from its database to assess credit risk (score procedure). SigmaHeat reserves the right to refuse payment on invoice or by direct debit to the customer due to the credit check. Further information can be found at <a href='https://www.creditreform.de/datenschutz' target='_blank' rel='noreferrer'>https://www.creditreform.de/datenschutz</a>.",
          contractDurationCancellation: "Contract Duration, Cancellation",
          contractDurationDescription1:
            "The start of the service provision and the contract term are individually agreed upon by the parties. Ordinary termination before the end of the agreed term is excluded.",
          contractDurationDescription2:
            "The right to terminate without notice for good cause remains unaffected. Such good cause exists for SigmaHeat in particular if the customer:",
          contractDurationReason1:
            "is more than 14 days in arrears with payment of the compensation or a portion thereof, or",
          contractDurationReason2:
            "behaves inappropriately towards SigmaHeat (or its representatives and agents) in personal contact.",
          cancellationConditions:
            "Terminations are only effective if they are declared in writing at a minimum.",
          rightOfWithdrawal: "Right of Withdrawal",
          noRightOfWithdrawal:
            "The customer has no right of withdrawal, in particular no statutory distance selling right of withdrawal, with regard to contracts concluded under inclusion of these GTCs.",
          limitationOfLiability: "Limitation of Liability",
          liabilityForIntentionalMisconductAndGrossNegligence:
            "SigmaHeat is fully liable for intentional misconduct and gross negligence. For simple negligence, SigmaHeat is only liable for damages resulting from the violation of life, body, health, or a material contractual obligation (an obligation whose fulfillment makes the proper execution of the contract possible in the first place and on whose compliance the contractual partner regularly relies and may rely).",
          liabilityForSimpleNegligence:
            "In case of simple negligence, the liability of SigmaHeat is limited to the foreseeable, typically occurring damage.",
          limitationOfLiabilityForSimpleNegligence:
            "Otherwise, the liability of SigmaHeat is excluded. These limitations of liability also apply to the benefit of SigmaHeat's legal representatives and vicarious agents.",
          exceptionsToLimitationOfLiability:
            "The foregoing limitations of liability do not apply if SigmaHeat has fraudulently concealed a defect or has assumed a guarantee for the quality of the goods. The same applies to claims of the customer under the Product Liability Act.",
          electronicCommunication_heading: "Electronic Communication",
          electronicCommunication_content1:
            "The customer expressly agrees that communication between him/her and SigmaHeat will primarily take place electronically, in particular via unencrypted email. It should be noted that unencrypted emails offer only limited security and confidentiality.",
          electronicCommunication_content2:
            "The customer must ensure that the email address provided by him/her is accurate, so that emails sent by SigmaHeat to this address can be received. In particular, when using SPAM filters, the customer must ensure that all emails sent by SigmaHeat can be delivered.",
          dataProtectionConfidentiality_heading:
            "Data Protection, Confidentiality",
          dataProtectionConfidentiality_content1:
            "The parties process the personal data of the respective other party in accordance with the data protection regulations, in particular the provisions of the General Data Protection Regulation (GDPR) and the Federal Data Protection Act (BDSG).",
          dataProtectionConfidentiality_content2:
            "The parties undertake to treat all information and data exchanged between them in the course of their cooperation confidentially and not to disclose it to third parties, unless agreed otherwise or the purpose of the contract requires disclosure.",
          finalProvisions: "Final Provisions",
          finalProvisions_content1:
            "The law of the Federal Republic of Germany applies, excluding the UN Sales Convention.",
          finalProvisions_content2:
            "The exclusive place of jurisdiction for all disputes arising from or in connection with contracts concluded with the inclusion of these GTC is the registered office of SigmaHeat.",
          finalProvisions_content3:
            "Should individual provisions of these GTC, including this provision, be or become wholly or partially ineffective, the effectiveness of the remaining provisions shall not be affected. The respective statutory regulations shall apply in place of the ineffective or missing provisions.",
        },

        "privacy.privacyPolicyDesc":
          'This privacy policy explains the type, scope and purpose of the collection and use of data of visitors and users (hereinafter collectively referred to as "users") by SigmaHeat GmbH (hereinafter "provider") as the data protection responsible body.',
        "privacy.responsibleNameAndContact":
          "Name and Contact of the Controller",
        "privacy.managedBy": "Managed by",
        "privacy.phone": "Tel.: ",
        "privacy.email": "Mail: ",
        "privacy.website": "Internet address: ",
        "privacy.personalDataProtection": "Protection of Personal Data",
        "privacy.personalDataProtectionDesc":
          "The provider takes technical and organizational measures in accordance with the requirements of Art. 32 GDPR to protect the user’s personal data. All employees of the provider who are involved in the processing of personal data are obliged to maintain data secrecy. The user’s personal data is generally encrypted using HTTPS when transmitted to the websites.",
        "accessDataCollection.heading": "Collection of Access Data",
        "accessDataCollection.info":
          "The provider temporarily collects data on every access to the online offering (so-called server log files). The access data includes the name of the accessed webpage, file, date and time of access, amount of data transferred, message about successful access, browser type and version, the user's operating system, referrer URL (the previously visited page), IP address, and requesting provider. The provider uses the log data in accordance with legal regulations for statistical evaluations for the purpose of operation, security, and optimization of the online offering, without associating it with the user's personal information or creating user profiles. This data is automatically deleted. However, the provider reserves the right to retrospectively review the log data if there is a justified suspicion of unlawful use based on concrete evidence. This information and personal data is required to correctly deliver and optimize the website's content, ensure network and information security, and protect the website against attacks, disruptions, and damage.",
        "personalDataCollection.heading": "Collection and Use of Personal Data",
        "personalDataCollection.info":
          "The processing of this personal data is justified in accordance with Art. 6 para. 1 sentence 1 letter f) GDPR. The provider has a legitimate interest in processing the data for the purpose of",
        connectionPurpose:
          "to quickly establish the connection to the provider's website",
        userFriendly: "to enable a user-friendly application of the website",
        securityStability:
          "to detect and ensure the security and stability of the systems",
        administrationPurpose:
          "to facilitate and improve the administration of the website. The processing is expressly not intended to gain insights into the identity of the website visitor.",
        "dataTransfer.heading": "Data Transfer to Third Parties",
        "dataTransfer.info1": "Personal data is transmitted to third parties",
        "dataTransfer.info2":
          "if the data subject has given express consent pursuant to Art. 6 para. 1 sentence 1 letter a) GDPR,",
        "dataTransfer.info3":
          "if the transfer pursuant to Art. 6 para. 1 sentence 1 letter f) GDPR is necessary for the assertion, exercise, or defense of legal claims and there is no reason to assume that the data subject has an overriding legitimate interest in not disclosing their data,",
        "dataTransfer.info4":
          "if there is a legal obligation for the transfer according to Art. 6 para. 1 sentence 1 letter c) GDPR, and/or",
        "dataTransfer.info5":
          "if the transfer pursuant to Art. 6 para. 1 sentence 1 letter b) GDPR is necessary for the performance of a contract with the data subject. In other cases, personal data will not be transferred to third parties.",
        "cookies.heading": "Cookies",
        "cookies.description":
          "The provider uses “cookies” as part of its online offering. Cookies are small files that are stored on the user's computer and can store information for providers. Temporary cookies are deleted after the browser is closed, permanent cookies are retained for a specified period of time and can make the stored information available when the online offer is called up again. On the one hand, cookies are used to facilitate the use of the service. A cookie stores the status of a user's shopping cart. The user can influence the use of cookies. Most browsers have an option to restrict or completely prevent the storage of cookies. The provider endeavors to design the online offer in such a way that the use of cookies is not necessary. However, it is pointed out that the use and in particular the ease of use are restricted without cookies. The data processed by cookies are required for the above Purposes to protect the legitimate interests of the provider according to Art. 6 Para. 1 Sentence 1 Letter f) GDPR justified.",
        "rights.heading": "Your Rights as a Data Subject",
        "rights.description":
          "If your personal data is processed during your visit to our website, you have the following rights as a 'data subject' within the meaning of the GDPR:",
        "information.heading": "Information",
        "information.description":
          "You can request information from us as to whether personal data about you is being processed by us. There is no right to information if the information must be kept secret for special reasons, particularly due to an overriding legitimate interest of a third party. However, a duty to provide information may exist if, taking into account impending damage, your interests outweigh the interest in secrecy. The right to information is also excluded if the data is only stored because it may not be deleted due to statutory or statutory retention periods or serves exclusively for data backup or data protection control purposes, provided that providing the information would require a disproportionate amount of effort and processing for other purposes is excluded by suitable technical and organizational measures. If, in your case, the right to information is not excluded and your personal data is processed by us, you can request information from us about the following information:",
        "rights.purposes": "Purposes of processing",
        "rights.categories": "Categories of processed personal data",
        "rights.recipients":
          "Recipients or categories of recipients to whom personal data has been or will be disclosed, particularly for recipients in third countries",
        "rights.storageDuration":
          "Planned duration for which personal data will be stored, or if not possible, criteria used to determine that duration",
        "rights.correction":
          "Existence of the right to request correction, deletion or limitation of processing of personal data, or the right to object to such processing",
        "rights.complaint":
          "Existence of the right to lodge a complaint with a supervisory authority",
        "rights.source":
          "Available information on the origin of the data if personal data was not collected from the data subject",
        "rights.automatedDecisionMaking":
          "Existence of automated decision-making, including profiling, and meaningful information about the logic involved, as well as the intended effects of automated decision-making",
        "rights.thirdCountry":
          "Information on what appropriate safeguards according to Art. 46 para. 2 GDPR are in place for the transfer of data to third countries if no adequacy decision under Art. 45 para. 3 GDPR is in place",
        "privacy.correctionTitle": "CORRECTION AND COMPLETION",
        "privacy.correctionText":
          "If you notice that we have incorrect personal data from you, you can request the immediate correction of this incorrect data from us. If your personal data concerning you is incomplete, you can request that it be completed.",
        "privacy.deletionTitle": "DELETION",
        "privacy.deletionText":
          "You have the right to deletion ('right to be forgotten'), provided that processing is not necessary for the exercise of the right to freedom of expression, the right to information or for compliance with a legal obligation or to perform a task carried out in the public interest and one of the following reasons applies:",
        "privacy.deletionReason1":
          "The personal data are no longer necessary for the purposes for which they were processed.",
        "privacy.deletionReason2":
          "The justification for the processing was solely your consent, which you have withdrawn.",
        "privacy.deletionReason3":
          "You have objected to the processing of your personal data which we have made public.",
        "privacy.deletionReason4":
          "You have objected to the processing of personal data by us which has not been made public, and there are no overriding legitimate grounds for the processing.",
        "privacy.deletionReason5":
          "Your personal data have been processed unlawfully.",
        "privacy.deletionReason6":
          "Deletion of personal data is necessary to comply with a legal obligation to which we are subject.",
        "privacy.deletionExceptionText":
          "There is no right to deletion if, in the case of lawful, non-automated data processing, deletion is not possible or only possible with disproportionate effort due to the special nature of the storage and your interest in deletion is low. In this case, the processing shall be restricted instead of deletion.",
        "privacy.restrictionTitle": "RESTRICTION OF PROCESSING",
        "privacy.restrictionText1":
          "You may request us to restrict the processing if one of the following conditions applies: You contest the accuracy of the personal data. In this case, the restriction can be requested for the duration that enables us to verify the accuracy of the data.",
        "privacy.restrictionText2":
          "The processing is unlawful and you request the restriction of the use of your personal data instead of deletion.",
        "privacy.restrictionText3":
          "We no longer need your personal data for the purposes of processing, but you require them for the assertion, exercise or defense of legal claims.",
        "privacy.restrictionText4":
          "You have objected pursuant to Art. 21 para. 1 GDPR. The restriction of processing can be requested as long as it is not yet clear whether our legitimate reasons outweigh your reasons. Restriction of processing means that personal data may only be processed with your consent or for the assertion, exercise or defense of legal claims or for the protection of the rights of another natural or legal person or for reasons of important public interest. Before we lift the restriction, we have a duty to inform you about it.",
        "privacy.dataPortabilityTitle": "DATA PORTABILITY",
        "privacy.dataPortabilityText":
          "You have the right to data portability if the processing is based on your consent (Art. 6 para. 1 sentence 1 letter a) or Art. 9 para. 2 letter a) GDPR) or on a contract to which you are a party and the processing is carried out by automated means. In this case, the right to data portability includes the following rights, provided that this does not adversely affect the rights and freedoms of other persons: You may request us to provide you with the personal data you have provided to us in a structured, commonly used and machine-readable format. You have the right to transmit this data to another controller without hindrance from us. To the extent technically feasible, you may request us to transmit your personal data directly to another controller.",
        "privacy.objectionTitle": "OBJECTION",
        "privacy.objectionText1":
          "If the processing is based on Art. 6 para. 1 sentence 1 letter e) GDPR (performance of a task carried out in the public interest or in the exercise of official authority) or Art. 6 para. 1 sentence 1 letter f) GDPR (legitimate interest of the controller or a third party), you have the right to object at any time, on grounds relating to your particular situation, to the processing of personal data concerning you. This also applies to profiling based on Art. 6 para. 1 sentence 1 letter e) or letter f) GDPR. After exercising the right to object, we will no longer process your personal data unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms or the processing serves the purpose of asserting, exercising or defending legal claims.",
        "privacy.objectionText2":
          "You have the right to object at any time to the processing of personal data concerning you for the purposes of direct marketing, which includes profiling related to such direct marketing. After exercising this right to object, we will no longer use the personal data concerned for direct marketing purposes. You may informally communicate the objection by telephone, email, if applicable, by fax or by post to the postal address of our registered office listed at the beginning of this privacy policy.",
        "privacy.revocationTitle": "REVOCATION OF CONSENT",
        "privacy.revocationText":
          "You have the right to revoke your consent at any time with effect for the future. The revocation of consent can be communicated informally by telephone, e-mail, if applicable, by fax or by post to our postal address. The revocation of consent does not affect the legality of the processing carried out on the basis of the consent until the revocation is received. After receipt of the revocation, the data processing, which was based exclusively on your consent, will be stopped.",
        "privacy.complaintTitle": "COMPLAINT",
        "privacy.complaintText":
          "If you believe that the processing of your personal data is unlawful, you have the right to lodge a complaint with a supervisory authority for data protection, which is responsible for the place of your residence or workplace or for the place of the alleged infringement.",
        "privacy.updateTitle": "UPDATES TO THIS PRIVACY POLICY",
        "privacy.updateText":
          "This privacy policy is effective as of June 1st, 2021. We reserve the right to update this privacy policy from time to time in order to improve privacy and/or to comply with changing regulatory requirements or case law.",

        contact: {
          chatTitle: "Chat to us",
          chatDescription: "Our friendly team is here to help.",
          addressTitle: "Visit us",
          addressDescription: "Come say hello to our office HQ.",
          phoneTitle: "Call us",
          phoneDescription: "Mon-Fri from 8am to 5pm.",
          heading: "Got the ideas? We've got the skills. Let's team up.",
          description:
            "Tell us more about yourself and what you've got in mind.",
          placeholder: "Tell us a little about the project",
          submit: "Let's get started",
          errorMSG:
            "There was an error while attempting to send the message. Please try again at a later time.",
          successMSG:
            "Your message has been received, and we will be in touch with you shortly.",
          mail: "E-Mail",
          name: "Your Name",
          redirected:
            "Please wait as you are automatically redirected within: ",
          privacyPolicyMSG:
            "To submit this message, it is required that you accept our privacy and policy terms.",
        },

        dsgvo: {
          processingDataTitle:
            "Processing (personal) data by the controller of the online application process",
          generalTitle: "General",
          dataPrivacyNotice:
            "We provide you with this privacy policy, which exclusively refers to the data collected as part of the online application process, to inform you how we handle your personal data collected during the application process.",
          responsibleEntityTitle: "Responsible Entity",
          responsibleEntityText1:
            "The responsible entity within the meaning of data protection law is:",
          handelsregisterEntry: "Registration in the commercial register",
          registerNumber: "Registration number:",
          registerCourt: "Register court:",
          personalDataTitle: "Personal Data in the Application Process",
          applicationProcessText1:
            "If you apply to us electronically, by email or through our web form, we collect and process your personal data for the purpose of handling the application process and conducting pre-contractual measures. By submitting an application on our recruiting page, you express your interest in starting employment with us. In this context, you provide us with personal data that we use and store exclusively for the purpose of your job search/application.",
          applicationProcessText2:
            "In particular, the following data is collected:",
          applicationProcessText3: "Name (first and last name)",
          applicationProcessText4: "Email address",
          applicationProcessText5: "Phone number",
          applicationProcessText6: "LinkedIn profile (optional)",
          applicationProcessText7:
            "Channel through which you became aware of us",
          additionalDocuments:
            "In addition, you have the option to upload meaningful documents such as a cover letter, your resume, and certificates. These may contain further personal data such as date of birth, address, etc.",
          dataAccess:
            "Only authorized employees in the HR department or employees involved in the application process have access to your data.",
          dataStoragePurpose:
            "The storage of personal data is generally done exclusively for the purpose of filling the vacant position you have applied for.",
          dataRetention:
            "Your data will be stored for a period of <strong>180</strong> days beyond the completion of the application process. This is usually done to fulfill legal obligations or defend against any claims arising from legal regulations. Afterward, we are obliged to delete or anonymize your data. In this case, the data is only available to us as so-called metadata without direct personal reference for statistical evaluations (such as the ratio of female to male applicants, number of applications per period, etc.).",
          talentPoolStorage:
            "Furthermore, we reserve the right to store your data for inclusion in our 'Talent Pool' for <strong>365</strong> days after the completion of the application process to identify any other interesting positions for you. This also applies, for example, to applications for training or internships. By accepting the privacy policy, you consent to the potential further storage of your data and inclusion in our 'Talent Pool'.",
          jobOfferAcceptance:
            "If you receive an offer of employment from us as part of the application process and accept it, we will store the personal data collected during the application process for at least the duration of the employment relationship.",
          dataDisclosureTitle: "Data Disclosure to Third Parties",
          dataDisclosureText:
            "The data transmitted as part of your application is transferred via TLS encryption and stored in a database. This database is operated by Personio GmbH, which provides human resources management and applicant management software (<a href='https://www.personio.de/impressum/' target='_blank' rel='noreferrer'>https://www.personio.de/impressum/</a>). In this context, Personio acts as our data processor according to Art. 28 GDPR. The processing is based on a data processing agreement between us as the responsible entity and Personio.",
          individualRightsTitle: "Rights of Data Subjects",
          individualRightsText:
            "If personal data is processed by us as the responsible entity, as a data subject, you have certain rights under Chapter III of the EU General Data Protection Regulation (GDPR), depending on the legal basis and purpose of the processing. These may include the right to information (Art. 15 GDPR), the right to rectification (Art. 16 GDPR), the right to erasure (Art. 17 GDPR), the right to restriction of processing (Art. 18 GDPR), the right to data portability (Art. 20 GDPR), and the right to object (Art. 21 GDPR). If the processing of personal data is based on your consent, you have the right to withdraw this consent under Art. 7(3) GDPR. Please contact our data protection officer (see Section 2) to exercise your rights as a data subject regarding the data processed in this online application process.",
          concludingProvisionsTitle: "Final Provisions",
          concludingProvisionsText:
            "We reserve the right to adapt this privacy policy at any time to ensure that it always complies with current legal requirements or to reflect changes in the application process or similar. For any subsequent visit to this recruiting page or a new application, the new privacy policy will apply. In addition to this privacy policy, you can also view our general privacy policy at <a href='sigmaheat.de/privacy-policy' target='_blank' rel='noreferrer'>sigmaheat.de/privacy-policy</a>.",
          processingDataTitle1:
            "Processing (personal) Data by the Operator of the Recruiting Page",
          processingDataText:
            "This recruiting page is operated by Personio SE & Co. KG, a company based in Germany that provides human resources management and applicant management software (<a href='https://www.personio.de/impressum/' target='_blank' rel='noreferrer'>https://www.personio.de/impressum/</a>). The data transmitted as part of your application is transferred via TLS encryption and stored in a database. The company conducting this online application process is solely responsible for this data in terms of Art. 24 GDPR. Personio is merely the operator of the software and this recruiting page and acts as a data processor according to Art. 28 GDPR. The processing by Personio is based on a data processing agreement between the responsible entity and Personio. Furthermore, Personio SE & Co. KG processes additional data to provide its services, particularly for the operation of this recruiting page. Some of this data may also be personal data. This will be explained in more detail below.",
          dataProtectionOfficer:
            "Data Protection Officer: <a href='mailto:datenschutz@personio.de'>datenschutz@personio.de</a>",
          accessLogsTitle: 'Access Logs ("Server Logs")',
          accessLogsText:
            "With each access to this recruiting page, general log data, known as server logs, are automatically recorded. These data are usually pseudonyms and therefore do not allow conclusions to be drawn about a natural person. Without this data, it would be technically impossible in some cases to deliver and display the content of the software. Furthermore, the processing of this data is necessary for security purposes, particularly for access control, input control, transmission control, storage control, and control of availability. In addition, the anonymous information can be used for statistical purposes and for optimizing the offering and technology. The log files can also be checked and evaluated retrospectively in the event of suspected unlawful use of the software. The legal basis for this can be found in §25(2) sentence 2 TTDSG. Generally, data such as the domain name of the website, web browser and browser version, operating system, IP address, and timestamp of access to the software are recorded. The scope of this logging does not exceed the common scope of any other website on the Internet. The storage period for these access logs is up to 7 days. There is no right to object.",
          errorLogsTitle: 'Error Logs ("Error Logs")',
          errorLogsText:
            'Error logs ("error logs") are created for the purpose of error identification and correction. This is necessary in order to be able to react promptly to possible problems with the presentation and implementation of content (legitimate interest). These data are usually pseudonyms and therefore do not allow conclusions to be drawn about a natural person. The legal basis for this can be found in §25(2) sentence 2 TTDSG. In the event of an error message, general data such as the domain name of the website, web browser and browser version, operating system, IP address, and timestamp of the occurrence of the respective error message/specification are recorded. The storage period for these error logs is up to 7 days. There is no right to object.',
          cookieUsageTitle: "Use of Cookies",
          cookieUsageText:
            'Cookies are used on this recruiting page in some cases. These are small text files that are stored on the device with which you access this recruiting page. In principle, cookies serve to ensure security when visiting a website ("strictly necessary"), implement certain functionalities such as standard language settings ("functional"), improve the user experience or performance on the website ("performance"), or display target group-based advertising ("marketing"). On this recruiting page, only strictly necessary, functional, and performance cookies are generally used, especially to implement certain pre-settings such as language, to identify the application channel, or to analyze the performance of a job advertisement through which a user accessed this recruiting page. The use of cookies is mandatory for the provision of our services and therefore for the performance of the contract (Art. 6(1)(b) GDPR). Storage period: Up to 1 month or until the end of the browser session Right to object: You can determine whether you allow cookies or object to the use of cookies through your browser settings. Please note that disabling cookies may result in limited or completely restricted functionality of this recruiting page.',
          individualRightsText1:
            "If personal data is processed by Personio SE & Co. KG as the responsible entity, as a data subject, you have certain rights under Chapter III of the EU General Data Protection Regulation (GDPR), depending on the legal basis and purpose of the processing. These may include the right to information (Art. 15 GDPR), the right to rectification (Art. 16 GDPR), the right to erasure (Art. 17 GDPR), the right to restriction of processing (Art. 18 GDPR), the right to data portability (Art. 20 GDPR), and the right to object (Art. 21 GDPR). If the processing of personal data is based on your consent, you have the right to withdraw this consent under Art. 7(3) GDPR. Please contact the data protection officer of Personio SE & Co. KG to exercise your data subject rights regarding the data processed for the operation of this recruiting page (see Section B).",
          concludingProvisionsText1:
            "Personio reserves the right to adapt this privacy policy at any time to ensure that it always complies with current legal requirements or to implement changes to the services in the privacy policy, such as the introduction of new services. For any subsequent visit to this recruiting page or a new application, the new privacy policy will apply.",
        },

        applyingFor: "You are applying for:",

        impressum: {
          heading: "Impressum",
          requiredInfo:
            "Required information according to § 5 Telemedia Act (TMG) and § 2 of the Regulation on Information Obligations for Service Providers (DL-InfoV)",
          responsible: "Responsible",
          authorities: "Admission/Supervisory Authorities:",
          registerCourt: "Competent Register Court",
          tradeRegisterNumber: "Trade Register Number",
          vatId: "VAT ID",
          responsibleContent:
            "Responsible for the editorial content pursuant to § 55 II 1 RStV",
          responsibleWebsite:
            "Responsible for the editorial content of the website spaceman.de and the editorial content accessible through this website on social networks (Instagram, LinkedIn) is",
          disputeResolution: "Dispute Resolution",
          euCommissionText:
            "The European Commission has established an online platform for dispute resolution, which you can find at <a href='http://ec.europa.eu/consumers/odr/' target='_blank' rel='noreferrer'>http://ec.europa.eu/consumers/odr/</a>. We are neither obliged nor willing to participate in a dispute resolution procedure before a consumer arbitration board if there are disputes with consumers. In case disagreements with SigmaHeat GmbH cannot be resolved, we offer an internal dispute resolution.",
          internalDisputeResolution:
            "For this purpose, please contact Maik Brinkmann.",
          copyRight: "Copyright",
          copyRightContent:
            "Please note that this website and its contents are protected by copyright.",
          disclaimer: {
            heading: "Disclaimer",
            contentAndLink:
              "All information and data have been created to the best of our knowledge, but no guarantee is given for their completeness and accuracy. SigmaHeat GmbH has no influence on the contents of externally linked pages. Therefore, it explicitly dissociates itself from all contents of all linked pages.",
            liability:
              "Therefore, any liability in connection with the use of the content of the website sigmaheat.de and the content accessible through this website in social networks or the reliance on their accuracy is excluded.",
          },
          privacy: "Privacy Policy",
          privacyPolicyLink: "You can find our privacy policy",
          here: "here",
        },
        typo: {
          message1: "Great to see you here 😊",
          message2: "Hey, wanna join SigmaHeat?",
          message3: "Together, we beat the Heat.",
          message4: "Wait, where is your coffee?",
        },
        energieservices: {
          craftsmanship: "Craftsmanship in a different way",
          visionaries:
            "We consider ourselves not just as craftsmen, but as visionaries shaping the future together.",
          welcome: "Welcome to us...",
          aboutUs:
            "...the SigmaHeat Energieservices. The craftsmanship company with that special something! Traditional craftsmanship meets innovative ideas and start-up character.",
          scrollFurther:
            "Scroll further to learn more about us and be inspired by our idea.",
          ourGoals:
            "Our Goals - Highest standards in sustainability and quality",
          qualityStandards:
            "We only use high-quality materials and work according to the latest standards and technologies. We offer our customers a reliable and first-class service that is focused on long-term success.",
          climateHero: "Climate Hero?",
          servicesDescription:
            "With our services, as a climate hero, you not only protect the environment but also your wallet.",
          whyWeAreDifferent: "Why we are different",
          aboutUsDescription:
            "We are SigmaHeat Energieservices, a young, digital company specializing in the optimization of heating systems. Our goal is to actively contribute to climate protection by understanding and optimizing existing heating systems holistically. By working with SigmaHeat, we analyze the functionality and grievances in our customers systems. Our team from SigmaHeat Energieservices carries out the optimizing conversion work and every employee contributes to climate protection through their work.",
          enoughHeated: "Had enough heating?",
          contactUsDescription:
            "Get in touch with us. We are happy to advise you on the best solution for you.",
          contactNow: "Contact Now!",
          modernCraftsmanship: "Craftsmanship goes modern – wanna bet?",
          differentThanOthers:
            "We are different from other SHK companies - because here traditional craftsmanship meets innovative ideas and a start-up character. Our goal is for you to be just as enthusiastic about our passion for the craft as we are. Experience craftsmanship differently, for this we rely on the latest technology and creative solutions.",
          hydraulicBalancing: "Hydraulic balancing according to EnSimiMaV",
          hydraulicBalancingDescription:
            "This involves determining the heat demands in the rooms and calculating the optimal water flow for each radiator. The heating system is adjusted accordingly, and thermostat valves are adapted. This ensures even heat distribution and a more efficient heating system. In older buildings, adjustable thermostat valves are often not installed, so they need to be replaced with presettable ones first. We offer the complete package!",
          pumpReplacement: "Pump replacement",
          pumpReplacementDescription:
            "Replacing old circulation or heating pumps with modern high-efficiency pumps offers long-term benefits. These pumps save costs, reduce power consumption and reduce CO2 emissions. They also have a longer service life and require less maintenance. By replacing the pump, you bring your heating system up to date and save energy and costs in the long term.",
          bufferStorageRetrofit: "Buffer storage retrofit",
          bufferStorageRetrofitDescription:
            "A buffer storage system stores thermal energy and enables continuous and uniform operation of the heating system. This results in energy savings and a reduction in wear and tear of the heating system. Buffer storage systems are flexible and adapt to individual needs. They improve energy efficiency and can be useful even when installing a heat pump. Installing a buffer storage system is a long-term investment in the future of your heating system.",
          heatingMaintenance: "Heating maintenance",
          heatingMaintenanceDescription:
            "Regular heating maintenance is important for safety and climate protection reasons. Through maintenance, the heating system operates more efficiently, consumes less energy, and reduces CO2 emissions. Wear parts are replaced, the combustion chamber is cleaned, and safety devices are checked. Early detection of malfunctions saves costs and prevents complete failure. Heating maintenance contributes to extending the lifespan of the system and climate protection.",
          heatingInspectionOptimization:
            "Heating inspection and optimization according to EnSimiMaV §2",
          heatingInspectionOptimizationDescription:
            "According to EnSimiMaV §2, building owners with natural gas heating systems must conduct a heating inspection and implement optimization measures. This includes checking technical parameters such as flow temperature, heating curve, circulation operation, and activating setback functions. The results and required optimization measures must be documented and implemented by September 15, 2024. Our technicians offer professional heating inspections and optimizations, ideally in combination with heating maintenance.",
          greeting: "We look forward to you!",
          teammembers:
            "Our motivated team consists of experienced craftsmen and creative minds who pull together. We are proud of our work and the results we achieve together with our customers.",
          apply:
            "Let's revolutionize the craft together and put our visions into practice. Apply now for one of our positions.",
        },
        cookie:
          "Please agree to the processing of the data so that we can offer you our service. <1>Here</1> you can find our Privacy Policy.",
        allow: "Allow",
      },
    },
    de: {
      translation: {
        featuresTitle: "Die Features auf einen Blick",
        features: [
          {
            title: "Gebäude- und Liegenschaftsverwaltung",
            description:
              "Zentralisierte Verwaltung aller Immobiliendaten und -dokumente.",
          },
          {
            title: "Messdatenvisualisierung",
            description:
              "Übersichtliche Darstellung komplexer Datensätze in Echtzeit.",
          },
          {
            title: "Digitalisierung von analogen Zählern via Bilder-OCR",
            description:
              "Automatische Erfassung von Zählerständen durch Bilderkennung.",
          },
          {
            title:
              "Automatische Analyse von Heizungsanlagen und Temperaturprofilen",
            description:
              "Optimierung von Energieverbrauch durch intelligente Analyse.",
          },
          {
            title: "Kennwertanalyse zur Erstellung von Energieberichten",
            description:
              "Effizienzbewertung und Berichterstattung auf Knopfdruck.",
          },
          {
            title: "Heizlastberechnung raumweise nach Verfahren B (DIN 12831)",
            description:
              "Präzise Bestimmung der benötigten Heizleistung pro Raum.",
          },
          {
            title: "Heizlastberechnung im Single-Entry-Verfahren",
            description:
              "Effiziente Berechnung und Einstellung in einem Schritt.",
          },
          {
            title:
              "Digitale Anlagendaten vom Schema bis zu den Kenndaten des Wärmeerzeugers",
            description:
              "Alle technischen Daten und Schemata digital verfügbar.",
          },
          {
            title: "Digitales Wartungs- & Anlagenbuch",
            description: "Lückenlose Dokumentation und Wartungsplanung.",
          },
          {
            title: "Kontakte für das Gebäudemanagement",
            description: "Zentrale Verwaltung aller relevanten Kontakte.",
          },
          {
            title: "Nutzer- & Rechteverwaltung",
            description: "Individuelle Zugriffsrechte für ein sicheres System.",
          },
          {
            title: "Externer Zugriff für Dienstleister",
            description:
              "Erleichterte Zusammenarbeit durch externen Systemzugang.",
          },
          {
            title: "API-Schnittstelle für normierten Datenaustausch",
            description:
              "Flexible Integration in bestehende Systemlandschaften.",
          },
          {
            title: "Anschluss für OMS-Datensammler für die UVI",
            description:
              "Automatisierte Datenerfassung für die Umgebungsvisualisierung.",
          },
          {
            title: "Mandantenfähig",
            description:
              "Wir können unser System überall laufen lassen, bei uns in der Cloud oder bei Ihnen auf dem Server.",
          },
          {
            title: "OEM-Anpassung",
            description:
              "Sie wollen ihr Design nutzen? Wir stellen eine individualisierbare Lösung bereit, die es Ihnen ermöglicht, das Tool optimal auf Ihre Bedürfnisse zuzuschneiden.",
          },
        ],

        featuredJobs: "Unsere Stellenangebote",
        heatedEnough: "Schon genug aufgeheizt?",
        howWeHire: "Wie wir einstellen",
        readOurFaq: "Unsere FAQ lesen",
        communities: "Gemeinschaften",
        stayUpdated: "Aktualisiert bleiben",
        career: "Karriere",
        diversity: "Vielfalt, Gerechtigkeit & Wirkung",

        design: "Design",
        developers: "Entwickler",
        brands: "Marken",
        artists: "Künstler",
        engineering: "Technik",
        research: "Forschung",
        latest: "Aktuellste",
        instagram: "Instagram",
        linkedin: "LinkedIn",
        twitter: "Twitter",
        youtube: "YouTube",
        podcast: "Podcast",
        locations: "Standorte",
        beingHere: "Hier sein",
        students: "Studenten",
        allJobs: "Alle Jobs",
        toTheJobs: "und hier gehts zu unseren Jobs",
        applyToJob: "Jetzt bewerben",
        orToTheContactForm: "oder zum Kontaktformular",
        getInTouch: "Hier direkt Kontakt aufnehmen",

        products: {
          monitoring_optimization: {
            title: "Monitoring und Optimierung von Heizungsanlagen",
            texte: [
              {
                title: "Unser Serviceangebot",
                text: "In der heutigen Zeit, wo Energieeffizienz und Nachhaltigkeit mehr denn je im Mittelpunkt stehen, präsentieren wir eine fortschrittliche Lösung für die Überwachung und Optimierung von Zentralheizungsanlagen in Mehrfamilienhäusern. Unser Service nutzt modernste, nicht-invasive Messtechnik, um Ihre Heizsysteme aus der Ferne zu überwachen und zu analysieren. Dies ermöglicht es uns, innerhalb kürzester Zeit Optimierungen durchzuführen und die Heizungsanlagen korrekt einzustellen – eine Maßnahme, die in vielen Fällen bisher noch nie vorgenommen wurde, jedoch essenziell für die Effizienz des Systems ist.",
              },
              {
                title: "Die Bedeutung der Einregulierung von Heizungsanlagen",
                text: "Viele Zentralheizungsanlagen in Mehrfamilienhäusern arbeiten nicht mit optimaler Effizienz, da sie niemals korrekt eingestellt wurden. Dies führt zu unnötig hohen Energiekosten und einer erhöhten Umweltbelastung. Unser Service adressiert dieses weit verbreitete Problem direkt, indem er durch präzise Analyse und Einstellung der Anlage eine durchschnittliche Energieeinsparung von 10% ermöglicht. Diese Optimierung verbessert nicht nur die Wirtschaftlichkeit der Heizungsanlage, sondern trägt auch zum Umweltschutz bei.",
              },
              {
                text: "Unsere modernen Analysemethoden sind dabei der Schlüssel zum Erfolg. Durch den Einsatz fortschrittlicher Diagnosetools und Algorithmen können wir den Zustand und die Leistung Ihrer Heizsysteme detailliert bewerten. Diese tiefgehende Analyse ermöglicht es uns, individuell abgestimmte Lösungen für jede Anlage zu entwickeln, um deren Effizienz zu maximieren. Dieser Prozess wird durch die Erstellung umfassender Energieberichte ergänzt, die nicht nur Aufschluss über den aktuellen Energieverbrauch geben, sondern auch konkrete Empfehlungen für weitere Energieeinsparungen und Optimierungsmaßnahmen enthalt.",
              },
              {
                text: "Für Vermieter bringt unser Service zusätzliche Vorteile. Die optimierte Heizungsanlage führt zu einer Aufwertung der Immobilie und zu einer höheren Zufriedenheit unter den Mietern, da ein verbessertes Heizsystem direkt zu einem angenehmeren Wohnklima beiträgt. Darüber hinaus ermöglicht die Energieeinsparung eine bessere Positionierung am Markt durch das Angebot nachhaltiger und kosteneffizienter Wohnräume. Die Investition in unsere Dienstleistung kann somit als ein strategischer Schritt verstanden werden, der die Attraktivität und den Wert Ihrer Immobilien langfristig steigert.",
              },
              {
                text: "Zudem bereitet unser Service den Weg für die Einführung eines Gütezeichens, das als stilles Bekenntnis zur Exzellenz dient. Dieses Zeichen wird nicht nur die substanzielle Qualität Ihrer Immobilien unterstreichen, sondern auch deren Wertigkeit in einem sich stetig wandelnden Marktumfeld nachhaltig festigen. Ein diskretes, aber mächtiges Symbol für Weitsicht und Verantwortung, das Ihre Position als Vorreiter in Sachen Nachhaltigkeit und Effizienz klar hervorhebt.",
              },
              {
                title:
                  "Die Vorteile unseres Optimierungs- und Überwachungsservice",
                li: [
                  {
                    title: "Schnelle Optimierung",
                    text: "Dank unserer effizienten Prozesse können wir Ihre Heizungsanlage in kürzester Zeit optimal einstellen.",
                  },
                  {
                    title: "Energieeinsparung",
                    text: "Durch die Optimierung der Heizungsanlage können durchschnittlich 10% der Heizenergie eingespart werden, was zu erheblichen Kosteneinsparungen führt. Wenn Sie die Kosten vollständigen umlegen, hat der Mieter innerhalb eines Monats volle Kostenersparnis plus ca. 1,70 EUR pro Wohneinheit Gewinn monatlich.",
                  },
                  {
                    title: "Nicht-invasive Messtechnik",
                    text: "Unsere moderne Technologie ermöglicht es uns, Ihre Heizungsanlage zu überwachen und zu analysieren, ohne in die bauliche Substanz eingreifen zu müssen.",
                  },
                  {
                    title:
                      "Fernüberwachung & weniger ungeplante Notdiensteinsätze",
                    text: "Die Überwachung und Analyse aus der Ferne bedeutet minimalen Aufwand und Störung für die Bewohner. Die Anlage kann aus der Ferne eingesehen werden und so gegenüber Mieter eine direkte Auskunft erteilt werden.",
                  },
                  {
                    title: "Beitrag zum Umweltschutz",
                    text: "Reduzierung des CO2- Fußabdrucks Ihrer Immobilie.",
                  },
                  {
                    title: "Umlegbar nach §2 BetrKV",
                    text: "Energie sparen, Kosten teilen: Gewinn für Mieter und Vermieter.",
                  },
                  {
                    title: "Rechtssicher",
                    text: "Unsere Rahmenverträge werden in Zusammenarbeit mit renommierten Kanzleien der Wohnungswirtschaft entwickelt, um maximale Rechtssicherheit zu gewährleisten.",
                  },
                  {
                    title: "Erfüllung des GEG",
                    text: "Durch unsere Optimierung ist die Erfüllung des neuen Gebäudeenergiegesetz (GEG) gesichert.",
                  },
                ],
              },
              {
                title:
                  "Bringen Sie noch heute Ihre Heizungsanlage auf den neuesten Stand",
                text: "Unser Service für das Monitoring und die Optimierung von Zentralheizungsanlagen in Mehrfamilienhäusern stellt eine essenzielle Investition in die Zukunft Ihrer Immobilie dar. Durch die Nutzung unserer nicht-invasiven Messtechnik und die Möglichkeit der Fernüberwachung und -analyse bieten wir eine effiziente, kostensparende und umweltfreundliche Lösung zur Optimierung Ihrer Heizsysteme. Engagieren Sie sich gemeinsam mit uns für eine energieeffiziente und nachhaltige Zukunft.",
                button: { text: "Jetzt direkt Anfragen!", to: "/contact" },
              },
            ],
          },
          hydr_balancing: {
            title: "Hydraulischer Abgleich in Mehrfamilienhäusern",
            texte: [
              {
                text: "Der hydraulische Abgleich in Mehrfamilienhäusern spielt eine entscheidende Rolle für die Effizienz und den Komfort der Heizungsanlagen. Er stellt sicher, dass Wärme gleichmäßig und bedarfsgerecht in alle Einheiten verteilt wird, was nicht nur für ein angenehmes Raumklima sorgt, sondern auch Energiekosten spart und die Umwelt schont. In der Praxis zeigt sich, dass die genaue Abstimmung der Heizungssysteme auf die individuellen Gegebenheiten eines jeden Gebäudes essentiell ist.",
              },
              {
                title: "Unser innovatives Single-Entry-Verfahren",
                text: "In der modernen Gebäudetechnik steht Effizienz nicht nur im Bezug auf Energieverbrauch und -verteilung im Fokus, sondern ebenso bei der Durchführung technischer Maßnahmen. Mit unserem innovativen und eigens entwickelten Single-Entry-Verfahren revolutionieren wir den Prozess des hydraulischen Abgleichs in Mehrfamilienhäusern. Das Ziel dieses Verfahrens ist es, den Eingriff in die Privatsphäre der Bewohner so gering wie möglich zu halten, indem wir den Prozess von der Berechnung über den Umbau bis hin zum Einstellen der Heizungsanlage in einem einzigen Arbeitsschritt vollziehen.",
              },
              {
                text: "Das Single-Entry-Verfahren basiert auf einer gründlichen Vorarbeit und Planung, die es uns ermöglicht, alle notwendigen Schritte präzise vorzubereiten. Zunächst führen wir eine detaillierte Analyse und Berechnung der Heizlast für jede Wohneinheit durch. Diese Berechnung folgt den Richtlinien der DIN 12831 und berücksichtigt sämtliche relevanten Faktoren wie Raumgröße, Isolierung, Fensterflächen und Nutzungsmuster. Auf Basis dieser Daten entwickeln wir einen maßgeschneiderten Plan für den hydraulischen Abgleich jeder einzelnen Einheit.",
              },
              {
                text: "Das Besondere am Single-Entry-Verfahren ist, dass wir nach dieser umfassenden Vorbereitung in der Lage sind, den Umbau und die Einstellung der Heizsysteme in nur einem einzigen Besuch in der jeweiligen Wohneinheit durchzuführen und das ohne dass uns Grundrisse oder Daten im Vorfeld vorliegen müssen. Dies bedeutet eine erhebliche Reduzierung der Belastung und des Zeitaufwands für die Bewohner. Unser geschultes Fachpersonal führt alle notwendigen Anpassungen effizient und mit minimaler Störung durch. Nach Abschluss der Arbeiten ist das Heizsystem optimal eingestellt, um eine effiziente Wärmeverteilung und einen maximalen Wohnkomfort zu gewährleisten.",
              },
              {
                text: "Unser Single-Entry-Verfahren entlastet Eigentümer und Verwalter von Mehrfamilienhäusern auf eine Weise, die sowohl effizient als auch rücksichtsvoll ist. Im Bewusstsein der vielfältigen Verantwortlichkeiten, die die Verwaltung von Immobilien mit sich bringt, haben wir dieses Verfahren speziell so konzipiert, dass es den Aufwand auf Ihrer Seite minimiert. Sie können sich beruhigt zurücklehnen, während wir den gesamten Prozess des hydraulischen Abgleichs fachmännisch und umsichtig handhaben.",
              },
              {
                text: "Vom ersten Schritt der detaillierten Analyse und Berechnung der notwendigen Heizlast nach den aktuellen Normen, über die sorgfältige Durchführung eventuell notwendiger Anpassungen, bis hin zur präzisen Feinabstimmung der Heizungsanlage: Unser erfahrenes Team gewährleistet eine zuverlässige und wenig invasive Umsetzung. Wir legen großen Wert darauf, dass jeder Eingriff in die Wohnungen Ihrer Mieter mit größtmöglicher Sorgfalt und so wenig Störung wie möglich erfolgt.",
              },
              {
                text: "Mit der Entscheidung für unser Single-Entry-Verfahren investieren Sie nicht nur in die langfristige Effizienz und den Komfort der Heizungsanlagen Ihrer Immobilien, sondern auch in die Zufriedenheit Ihrer Mieter. Sie profitieren von der Sicherheit, dass alle notwendigen Arbeiten nach höchsten Qualitätsstandards durchgeführt werden, ohne dass Sie sich um die Details kümmern müssen. Vertrauen Sie auf unsere Fachkompetenz und widmen Sie sich mit Gelassenheit anderen wichtigen Aufgaben der Immobilienverwaltung. Mit uns als Partner ist der hydraulische Abgleich Ihrer Objekte in den besten Händen.",
                button: { text: "Jetzt direkt Anfragen!", to: "/contact" },
              },
              {
                text: "Dieses Verfahren spiegelt unseren Anspruch wider, innovative Lösungen zu entwickeln, die nicht nur technisch fortschrittlich, sondern auch in ihrer Anwendung so effizient und bewohnerfreundlich wie möglich sind. Das Single-Entry-Verfahren steht exemplarisch für unsere Philosophie, durch intelligente Planung und Umsetzung sowohl den Komfort der Bewohner als auch die Energieeffizienz des Gebäudes zu maximieren. Mit diesem Ansatz bieten wir eine zukunftsweisende Lösung für die Herausforderungen des hydraulischen Abgleichs in Mehrfamilienhäusern, die sowohl praktikabel als auch nachhaltig ist.",
              },
              {
                title: "Der Heizungsnetz ist wie unser Herz-Kreislaufsystem",
                text: "Der hydraulische Abgleich in Mehrfamilienhäusern ist weit mehr als eine einmalige Maßnahme zur Optimierung von Heizsystemen. Es ist ein fortlaufender Prozess, der darauf abzielt, die Effizienz und den Komfort der Heizungsanlagen dauerhaft sicherzustellen. Ein Vergleich mit dem Herz-Kreislaufsystem des Menschen passt hier gut, denn wenn es kribbelt müssen wir abgleichen. Besonders wichtig ist dabei das Verständnis, dass die Anforderungen und Bedingungen eines Gebäudes, wie bei einem Menschen, sich über die Zeit ändern können, sei es durch Sanierungsmaßnahmen, den Austausch von Heizkörpern oder durch Veränderungen in der Nutzung der Räumlichkeiten. Daher ist es essentiell, den hydraulischen Abgleich als Teil einer kontinuierlichen Wartung und Überprüfung der Heizungsanlage zu begreifen.",
              },
              {
                text: "Zudem bringt der hydraulische Abgleich nach dem Gebäudeenergiegesetz (GEG) §60c nicht nur Empfehlungen, sondern auch rechtliche Verpflichtungen mit sich. Diese gesetzlichen Bestimmungen unterstreichen die Bedeutung einer fachgerechten Durchführung und regelmäßigen Überprüfung des hydraulischen Abgleichs. Das GEG zielt darauf ab, den Energieverbrauch von Gebäuden zu reduzieren und somit einen Beitrag zum Klimaschutz zu",
              },
              {
                title:
                  'Der dynamische Abgleich für potentielle "Stranded Assets"',
                text: 'Zusätzlich zu dieser detaillierten und individuellen Methode bieten wir auch eine innovative Option für einen dynamischen hydraulischen Abgleich an, der ausschließlich auf Strang-Ebene stattfindet. Diese Option ist besonders geeignet für Objekte, bei denen eine vollständige Datenerhebung für jeden Raum schwierig oder nicht wirtschaftlich ist. Besonders zu beachten ist dies bei sogenannten "Stranded Assets", die in den kommenden Jahren an Wert verlieren können und daher besonders wirtschaftlich betrachtet werden müssen. Durch den Abgleich auf Strang-Ebene können wir eine Verbesserung der Gesamteffizienz des Systems erreichen, indem wir die Verteilung der Heizwärme innerhalb der einzelnen Stränge optimieren. Dieser Ansatz bietet eine effektive Lösung, um die Vorteile des hydraulischen Abgleichs zu nutzen, ohne in die detaillierte Analyse jedes einzelnen Raumes investieren zu müssen. Dennoch ist ein raumweiser hydraulischer Abgleich immer dem strangweisen Abgleich vorzuziehen, da unter Anderem die Unter- und Überversorgung aus der Strangverteilung in die Wohneinheit verlagert werden können.',
              },
              {
                text: "Aus diesem Grund empfehlen wir den hydraulischen Abgleich nach Verfahren B raumweise gemäß DIN 12831. Diese Methode ermöglicht eine präzise Einstellung der Heizungsanlage, indem sie die spezifischen Bedürfnisse jedes Raumes berücksichtigt. Durch die genaue Berechnung der Heizlast eines jeden Raumes können wir gewährleisten, dass jede Einheit exakt die Wärmemenge erhält, die sie benötigt – nicht mehr und nicht weniger. Dies führt zu einer optimalen Effizienz der Heizungsanlage, reduziert Energieverbrauch und Kosten und erhöht zudem den Wohnkomfort für die Bewohner.",
              },
              {
                title: "Jedes Gebäude ist individuell",
                text: "Unsere Lösungen für den hydraulischen Abgleich in Mehrfamilienhäusern sind darauf ausgerichtet, den spezifischen Anforderungen und Gegebenheiten jedes Projekts gerecht zu werden. Indem wir sowohl die raumweise Abstimmung nach Verfahren B als auch den dynamischen Abgleich auf Strang-Ebene anbieten, sorgen wir dafür, dass unsere Kunden stets die effizienteste, kosteneffektivste und umweltfreundlichste Option wählen können. Engagieren Sie sich gemeinsam mit uns für eine Zukunft, in der Heizsysteme nicht nur leistungsstark, sondern auch nachhaltig sind.",
                button: { text: "Jetzt direkt Anfragen!", to: "/contact" },
              },
            ],
          },
          academy: {
            title: "Die Academy für effiziente Wärmetechnik",
            text: "",
          },
          software: {
            title: "Eine Software für die Wärmeversorgung der Zukunft",
            text: "In einer Welt, in der Energieeffizienz und Nachhaltigkeit immer mehr an Bedeutung gewinnen, steht die Immobilienbranche vor der Herausforderung, Heizsysteme und Gebäudetechnik zukunftsorientiert zu gestalten. Für Vermieter, Verwalter, Objektbetreuer und Facilitymanager, die nicht nur heute, sondern auch morgen an der Spitze der technologischen Entwicklung stehen möchten, präsentieren wir eine revolutionäre Softwarelösung. Unsere Plattform vereint modernste Technik mit intuitiver Bedienbarkeit, um die Verwaltung von Gebäuden und Liegenschaften, die Analyse von Energieverbrauchsdaten und die Optimierung von Heizungsanlagen nicht nur effizienter, sondern auch nachhaltiger zu gestalten. Entdecken Sie, wie unsere innovativen Features Ihnen dabei helfen können, die Zukunft der Wärmetechnik aktiv mitzugestalten und Ihre Objekte in eine effiziente, umweltfreundliche und kostensparende Zukunft zu führen.",
            text2:
              "Unsere Software ist das Ergebnis eines kontinuierlichen Dialogs zwischen Technologie, Bedarf und Vision. Jedes Feature, das wir entwickeln, wurzelt in einem tiefen Verständnis für die spezifischen Anforderungen der Branche – sei es durch eigene Erfahrungen oder durch das direkte Feedback unserer Kunden. Wir glauben fest daran, dass Innovation nur dann sinnvoll ist, wenn sie echte Probleme löst und greifbaren Mehrwert schafft. Daher fließt in jede neue Funktion nicht nur unsere technologische Expertise, sondern auch das praxisbezogene Wissen und die Wünsche derer ein, die täglich im Feld stehen: Vermieter, Verwalter, Objektbetreuer und Facilitymanager. Dieser Ansatz stellt sicher, dass unsere Software nicht nur eine Sammlung von Tools ist, sondern eine umfassende Lösung, die realen Nutzen stiftet, Effizienz steigert und zukunftssichere Gebäude ermöglicht. Entdecken Sie, wie jede unserer sorgfältig entwickelten Funktionen dazu beiträgt, die Herausforderungen von heute zu meistern und die Chancen von morgen zu nutzen.",
          },
        },

        be_a_partner: "Direkt Partner werden und Vorteile sichern!",
        mentalHealth: "Geistige Gesundheit",
        diversityEquityInclusionBelonging:
          "Diversität, Gerechtigkeit, Inklusion & Zugehörigkeit",
        socialImpact: "Sozialer Einfluss",
        climateAction: "Klimaschutz",
        sigmaHeat: "© 2021-2024 SigmaHeat",
        terms: "AGB",
        privacyPolicy: "Datenschutz",
        imprint: "Impressum",

        freshContent: "Neuer Inhalt",
        discoverMore: "Mehr Entdecken",

        liveDemo: "zur Live-Demo",
        DirectLiveDemo: "Direkt zur Live-Demo",

        applyNow: "Jetzt bewerben",
        description:
          "Lieben Sie es, als Entwickler im Flow zu sein und möchten Sie, dass dies auch für alle anderen gilt? Glauben Sie, dass der Schlüssel zum persönlichen Flow darin besteht, die lokale Entwickler-Build-Erfahrung schnell, gut integriert, automatisiert und mit sinnvollen Erkenntnissen zu gestalten? Wir suchen einen kooperativen und neugierigen Senior Engineer, der uns dabei unterstützt, unsere CI-Infrastruktur voranzutreiben und zu entwickeln.",
        location: {
          title: "Ort",
          content: "London",
        },
        type: {
          title: "Job-Typ",
          content: "Permanent",
        },
        whatYouDo: "Was Sie tun werden",
        extensiveLearningOpportunities: "Umfangreiche Lernmöglichkeiten",
        extensiveLearningOpportunitiesDesc:
          "durch unser dediziertes Team, GreenHouse.",
        flexibleShareIncentives: "Flexible Beteiligungsprogramme",
        flexibleShareIncentivesDesc:
          "mit denen Sie entscheiden können, wie Sie an unserem Erfolg teilhaben.",
        globalParentalLeave: "Globaler Elternurlaub",
        globalParentalLeaveDesc: "sechs Monate bezahlt für alle neuen Eltern.",
        allTheFeels: "All The Feels",
        allTheFeelsDesc:
          "unser Mitarbeiterunterstützungsprogramm und Selbstfürsorge-Hub.",
        flexiblePublicHolidays: "Flexible Feiertage",
        flexiblePublicHolidaysDesc:
          "frei an Feiertagen nach Ihren Werten und Überzeugungen.",
        similarJobs: "Ähnliche Jobs",
        productEmployee: "Produktionsmitarbeiter",
        sigmaHeatConsultant: "SigmaHeat Berater",
        technicalInternship: "Praktikum technisch",
        permanent: "permanent",
        intern: "intern",
        seeAllJobs: "Alle ähnlichen Jobs anzeigen",
        showMoreJobs: "Weitere Stellen anzeigen",
        "part-time": "Teilzeit",
        "full-time": "Vollzeit",
        "full-or-part-time": "Voll- oder Teilzeit",
        department: "Abteilung",
        jobs: "Stellenangebote",
        "all-locations": "alle Standorte",
        "all-departments": "alle Abteilungen",
        "all-job-types": "alle Stellenarten",
        locationsHeading: "Hier, dort, überall",
        locationsParagraph:
          "Audio ist global, deshalb sind wir es auch. Wo immer auf der Welt Sie nach einer neuen Stelle suchen, stehen die Chancen gut, dass wir da sind.",
        seeAllLocationsAriaLabel: "Alle Standorte anzeigen",
        seeAllLocations: "Alle Standorte anzeigen",
        mainCtaHeading:
          "Heizungsoptimierung für intelligente Mehrfamilienhäuser!",
        mainCtaButton: "Alle Jobs erkunden",
        searchForJobs: "Suche nach Jobs",
        searchJobsAndKeywords: "Jobs und Schlüsselwörter suchen",
        search: "Suche",

        top_navigation: {
          start: "Startseite",
          products: {
            title: "Produkte",
            dropdown: [
              "Immobilieneigentümer",
              "Handwerk",
              "Software: Anmelden zum Beta-Test",
              "Academy",
            //   "Individuelle Beratung",
            ],
          },
          propertyOwners: {
            title: "Immobilieneigentümer",
            dropdown: [
              "Monitoring + Optimierung",
              "Hydraulischer Abgleich",
            ],
          },
          craft: {
            title: "Handwerk",
            dropdown: [
              "Test-Kit"
            ],
          },
          blog: "HeatFeed",
          login: "App",
        },

        to_the_app: "Zur App",

        quickClicks: {
          heading: "Schnelle Klicks",
          categories: {
            text: "Kategorien",
            linkText: "Entdecken Sie mehr über unsere ",
          },
          students: {
            text: "Studenten",
            linkText: ", beeinflussen Sie die Dinge, nicht die Kaffees",
          },
          beingHere: {
            text: "Lesen Sie über ",
            linkText: "hier zu sein",
          },
        },

        ourClients: "In guter Gesellschaft",
        clientsDescription: "",

        activeNoiseCancellation:
          "Aktive Geräuschunterdrückung für immersiven Klang.",
        transparencyMode:
          "Transparenzmodus, um zu hören, was um Sie herum passiert.",
        customizableFit: "Eine anpassbare Passform für ganztägigen Komfort.",
        magicLikeYouveNeverHeard: "Magie wie Sie sie noch nie gehört haben.",
        cards: [
          {
            no: 1,
            issue: "Was wir machen",
            heading:
              "Den optimalen Betrieb von Heizungsanlagen in Mehrfamilienhäusern sichern!",
            paragraph:
              "Monitoring + Optimierung, Hydraulischer Abgleich und Academy",
            link: "/",
            // description: "ABC",
          },

          {
            no: 2,
            issue: "Wer wir sind",
            heading: "Der innovative Wärmeservice mit messbarem Klimaschutz",
            paragraph:
              "Jeder verdient eine effiziente, kostengünstige und transparente Heizungsanlage, denn die Wärmeversorgung von Bestandsgebäuden macht fast 1/6 aller Emissionen in Deutschland aus. Wir haben nur einen Planeten und wenig Zeit zu handeln.",
            link: "/",
            // description: "ABC",
          },

          {
            no: 3,
            issue: "Wo wir hingehören",
            heading:
              "Wir gehören zu jeder Zentralheizung und in jeden Kopf der sich mit Wärme auskennt",
            paragraph:
              "Wir wollen jeder Person eine transparente Einsicht in ihre Heizung bieten, denn in einer unabhängigen und verständlichen Erfassung jeder Heizungsanlage liegt der Schlüssel zur Klimaneutralität. Wir müssen verstehen, wie Heizungen funktionieren, bevor wir sie verbessern.",
            link: "/",
            // description: "ABC",
          },

          {
            no: 4,
            issue: "Wie wir arbeiten",
            heading: "Wir optimieren Heizungsanlagen mit Verstand",
            paragraph:
              "Handauflegen und Glaskugel war gestern! Durch unsere schnell integrierbare Messtechnik können wir mit minimalem Aufwand und Anlageneingriff verstehen, wie jede Heizungsanlage individuell für einen besonders effizienten Betrieb eingestellt werden muss. So sparen wir direkt durch das Einstellen des Wärmeerzeugers (durchschnittlich) 10% an Heizenergie ein.",
            link: "/",
            // description: "Warum Heizungen leider nicht korrekt eingestellt sind",
          },

          {
            no: 5,
            issue: "Warum wir wirklich gut sind",
            heading: "Ein System – Eine Philosphie – Ein Ziel",
            paragraph:
              "Wir entwickeln unsere Produkte selbst und komplett eigenständig, von der Elektronik bis hin zur Optimierungsanalyse. Unsere Kunden können darauf zählen, dass Sie unsere volle Aufmerksamkeit bekommen und unsere Produkte die Features abdecken, die benötigt werden.",
            link: "/",
            // description: "ABC",
          },

          {
            no: 6,
            issue: "Was wir zusammen erreichen können",
            heading:
              " 5 % der CO2-Emissionen und 25 % des Energieverbrauchs einsparen – ohne Verzicht!",
            paragraph:
              "Die Wärmewende ist unsere Jahrhundertaufgabe und daher erfordert sie unsere volle Aufmerksamkeit. Gemeinsam haben wir die Chanche ohne massive Investitionen bereits vor dem Einbau einer Wärmepumpe oder dem Absenken der Raumtemperatur bis zu 25% der Heizenergie einzusparen, einfach nur indem wir Heizungsanlagen mit Verstand betreiben. Wenn wir Heizungen anschließend mit Fachkentnissen austauschen sparen wir so 5% der deutschen CO2-Emissionen ein – Wow!",
            link: "/",
            // description: "ABC",
          },
        ],

        available_soon: "Demnächst verfügbar",
        learn_more: "Erfahre mehr",

        blog: {
          title: "Der HeatFeed",
          description:
            "Unsere Aufgabe ist es nicht nur, dafür zu sorgen, dass Ihre Heizung effizient läuft. Wir möchten Ihnen auch genau erklären, was in Ihrem Heizungskeller passiert. Auf unserer Webseite haben wir leicht verständliche Artikel für Sie zusammengestellt. Diese Artikel haben wir selbst geschrieben und sorgfältig ausgewählt, um Ihnen die besten Informationen zu bieten.",

          entries: [
            {
              id: 0,
              title: "Sieger der hubitation auf der ExpoReal 2023",
              short:
                "Gemeinsam mit der meravis haben wir den Sieg nach Hannover geholt!",
              content: [
                {
                  text: "ABC",
                },
                {
                  text: '<a href"https://www.youtube.com/watch?v=EGNyLxOMOXU">Hello</a>',
                },
              ],
              link: "hubitation",
              preview: true,
            },
            {
              id: 1,
              title: "Unser Software-Launch 2024",
              content: [
                {
                  text: "ABC",
                },
              ],
              preview: true,
              link: "SoftwareLaunch2024",
            },
            {
              id: 2,
              title:
                "5 % CO2-Ersparnis ohne die Raumtemperatur zu senken & ohne Wärmepumpe",
              content: [
                {
                  text: "ABC",
                },
              ],
              preview: true,
              link: "5ProzentCO2Ersparnis",
            },
            {
              preview: true,
              id: 3,
              title: "Warum werden Heizungen nicht richtig eingestellt?",
              content: [
                {
                  text: "ABC",
                },
              ],
              link: "WarumWerdenHeizungenNichtEingestellt",
            },

            {
              preview: true,
              id: 4,
              title:
                "Warum die Sommermonate ideal für die Einstellung Ihres Wärmeerzeugers sind",
              content: [
                {
                  text: "ABC",
                },
              ],
              link: "ImSommerEinstellen",
            },

            {
              preview: true,
              id: 5,
              title:
                "Offene Schnittstellen: Die unsichtbare Revolution im Heizungskeller",
              // title: "Offene Schnittstellen im Heizungskeller: Eine neue Ära der Gebäudetechnik",
              content: [
                {
                  text: "ABC",
                },
              ],
              link: "OpenInterfacesInTheBasement",
            },

            {
              preview: true,
              id: 6,
              title:
                "Von A bis Z: Ihr Leitfaden durch die Welt der Heizungstechnik",
              content: [
                {
                  text: "ABC",
                },
              ],
              link: "FAQ",
            },

            {
              preview: true,
              id: 7,
              title:
                "Von Archiven zu Innovationen: Die Rolle existierender Messdaten in der Heiztechnik",
              content: [
                {
                  text: "ABC",
                },
              ],
              link: "next",
            },
          ],
        },

        backgroundSlider: [
          {
            title: "Sieger der hubitation auf der ExpoReal 2023",
            to: "/blog/hubitation",
          },
          {
            title: "One more thing ...  der Software-Launch 2024",
            to: "/blog/software2024",
          },
          {
            title:
              "5 % CO2-Ersparnis ohne die Raumtemperatur zu senken & ohne Wärmepumpe",
            to: "/blog/5CO2-Ersparnis",
          },
        ],

        reviewHeading: "Überprüfen und senden Sie Ihre Bewerbung für:",
        privacyPolicyConfirmation: "Hiermit bestätige ich, dass ich die",
        privacyPolicyConfirmationPartTwo: " gelesen habe*",
        privacyPolicyLink: "Datenschutzerklärung",
        submit: "Absenden",
        firstNameQuestion: "Wie lautet dein Vorname?",
        firstNamePlaceholder: "Vorname",
        firstNameError: "Dein Vorname fehlt noch",
        lastNameQuestion: "Wie lautet dein Nachname?",
        lastNamePlaceholder: "Nachname",
        lastNameError: "Dein Nachname fehlt noch",
        emailQuestion: "Wie lautet deine E-Mail-Adresse?",
        emailPlaceholder: "E-Mail-Adresse",
        emailError: "Bitte trage deine E-Mail-Adresse ein",
        phoneQuestion: "Wie ist deine Telefonnummer?",
        phonePlaceholder: "Telefonnummer",
        phoneError: "Bitte trage deine Telefonnummer ein",
        availabilityQuestion: "Ab wann bist du verfügbar?",
        availabilityPlaceholder: "Verfügbarkeit",
        availabilityError: "Bitte trage deine ungefähre Verfügbarkeit ein",
        payQuestion: "Bitte nenne uns deine Gehaltsvorstellung.",
        payPlaceholder: "",
        payError:
          "Falls du dir unsicher bist, nimm einfach einen ungefähren Wert",
        cvQuestion: "Lade jetzt deinen Lebenslauf hoch",
        cvPlaceholder: "Lebenslauf",
        cvError: "Dein Lebenslauf fehlt hier noch",
        docsQuestion:
          "Falls Du ein weiteres Dokument hochladen möchtest, kannst du das hier tun.",
        docsPlaceholder: "Dokumente",
        optionalText:
          "Hier kannst du uns noch eine Nachricht senden oder angeben, wer dich aus unserem Team angeworben hat",
        docsError: "Fehlermeldung Dokument",
        previous: "Zurück",
        next: "Weiter",
        review: "Überprüfen",

        termsnconditions: {
          heading: "Allgemeine Geschäftsbedingungen (AGB) der SigmaHeat GmbH",
          scopeChanges: "Geltungsbereich, Änderungen",
          generalTerms1:
            "Die vorliegenden allgemeinen Geschäftsbedingungen (AGB) gelten für alle Verträge zwischen der SigmaHeat GmbH, vertreten durch den Geschäftsführer Maik Brinkmann, Lange Laube 2, 30159 Hannover (nachfolgend „SigmaHeat“) und ihren Kunden. Sie gelten in ihrer jeweils zum Zeitpunkt des Vertragsschlusses aktuellen Fassung.",
          generalTerms2:
            "Abweichende AGB des Kunden finden auf die geschlossenen Verträge keine Anwendung, auch wenn SigmaHeat diesen nicht ausdrücklich widersprochen hat.",
          generalTerms3:
            "Treffen die Parteien von diesen AGB abweichende Vereinbarungen, so gehen diese den Regelungen der AGB vor. Abweichende Vereinbarungen, die nach Vertragsschluss getroffen werden, bedürfen zu ihrer Wirksamkeit der Textform.",
          generalTerms4:
            "SigmaHeat schließt Verträge ausschließlich mit Unternehmern im Sinne des § 14 BGB, d. h. mit Personen, die eine selbstständige berufliche (Neben-)Tätigkeit verfolgen.  Der Kunde sichert daher mit Vertragsschluss zu, dass er Unternehmer in diesem Sinne ist und den Vertrag in dieser Eigenschaft schließt. Mit Personen, die als Verbraucher im Sinne des § 13 BGB handeln, werden keine vertraglichen Beziehungen eingegangen. Der Kunde ist verpflichtet, SigmaHeat auf Verlangen entsprechende Nachweise über seine unternehmerische Tätigkeit (z. B. Handelsregisterauszug) vorzulegen.",
          generalTerms5:
            "Änderungen dieser Geschäftsbedingungen werden Kunden per E-Mail mitgeteilt. Widersprechen Kunden einer Änderung nicht innerhalb von zwei Wochen nach Zugang der Mitteilung, gelten die Änderungen als anerkannt. Auf das Widerspruchsrecht und die Rechtsfolgen des Schweigens werden Kunden in der E-Mail-Mitteilung gesondert hingewiesen.",
          scopeAgreement: "Leistungsgegenstand, Vertragsschluss",
          servicesOffered:
            "SigmaHeat bietet Leistungen auf dem Gebiet der Wärmetechnik zur Heizkostensenkung, Effizienzkontrolle und Steigerung der Klimafreundlichkeit an. Ziel der Leistungen von SigmaHeat ist es, den Betrieb von Heizungsanlagen derart zu optimieren, dass Heizkosten und CO2-Emissionen eingespart werden können. Mit Hilfe eines von SigmaHeat entwickelten Systems können die laufenden Kosten für den Betrieb einer Heizungsanlage regelmäßig um bis zu 25 % gesenkt werden. Es besteht keine Garantie für eine Kostenreduzierung in bestimmter Höhe. Abhängig von der Beschaffenheit der jeweiligen Heizungsanlage und der sonstigen Betriebsumstände sind die möglichen Erfolge individuell und von Anlage zu Anlage unterschiedlich. SigmaHeat schuldet keine bestimmte Kostenreduzierung, versucht jedoch stets und in Abgängigkeit der vorliegenden Voraussetzungen beim Kunden das bestmögliche Ergebnis zur Optimierung der Heizungsanlage zu erzielen.",
          servicesIncluded:
            "Abhängig von der individuellen Vereinbarung zwischen den Parteien sind einige oder alle der nachfolgenden Leistungen erfasst:",
          messtechnik:
            "Bereitstellung der benötigten und mit dem SigmaHeat-System kompatiblen Messtechnik",
          installation:
            "Installation der benötigten Messtechnik in der vorhandene Heizungsinfrastruktur und Verbindung mit dem SigmaHeat-System",
          remoteMonitoring:
            "Überwachung und Prüfung der Betriebsbereitschaft und -sicherheit der Heizungsanlage aus der Ferne",
          measurementData:
            "Bereitstellung der Messdaten, Alarme und Auswertungen im Rahmen der SigmaHeat-App",
          softwarePackage:
            "Bereitstellung der SigmaHeat-Software im Rahmen eines Software as a Service (SaaS)-Pakets",
          dataAnalysis: "Analyse der erhobenen Messdaten",
          optimization:
            "Optimierung der Heizungsanlage durch auf der Analyse beruhende Anpassungen der Einstellparameter",
          contractConclusion:
            "Der Vertrag zwischen SigmaHeat und dem Kunden kommt in der Regel via E-Mail, fernmündlich oder über die Internetseite von SigmaHeat zustande. Die Annahme des Vertragsangebots durch den Kunden erfolgt durch ausdrückliche Erklärung (Auftragsbestätigung) von SigmaHeat per E-Mail.",
          contractTerms:
            "Die Vertragsbestimmungen mit Angaben zu den beauftragten Lieferungen von Waren und/oder Dienstleistungen einschließlich dieser Allgemeinen Geschäftsbedingungen werden dem Kunden zur Speicherung per E-Mail mit der Auftragsbestätigung übermittelt oder in Papierform ausgehändigt.",
          generalTerms: "Allgemeine Bestimmungen",
          serviceDescriptions:
            "Beschreibungen und Darstellungen der Leistungen von SigmaHeat (z.B. auf Websites und/oder in Werbebroschüren) sind stets unverbindlich und nicht als Garantie oder Zusicherung einer Eigenschaft zu verstehen. Aussagen zum Leistungsgegenstand stellen nur dann Garantien oder Zusicherungen im Rechtssinne dar, wenn diese in Schriftform erfolgen und ausdrücklich als „Garantie“ oder „Zusicherung“ benannt sind.",
          subcontractors:
            "SigmaHeat ist berechtigt, sich zur Vertragserfüllung eines oder mehrerer Unterauftragnehmer/s zu bedienen.",
          customerObligations: "Mitwirkungspflichten des Kunden",
          unhinderedAccess:
            "Der Kunde sorgt dafür, dass SigmaHeat die ggf. beauftragten Arbeiten an den vereinbarten Objekten ungehindert durchführen kann. Dazu zählt insbesondere die Sicherstellung des ungehinderten Zugangs zu den Objekten, die kostenlose Bereitstellung von Strom und Wasser, die Nutzung von Fahrwegen und Aufzügen sowie die Schaffung der technischen Voraussetzungen zur Installation von Messtechnik vor Ort.",
          completeAndCorrectInformation:
            "Der Kunde ist verpflichtet, die benötigten Angaben zur Bemessung der Dienstleistungen vollständig und korrekt wiederzugeben. Hierzu zählt insbesondere die Angabe der korrekten Quadratmeterflächen der vereinbarten Objekte.",
          protectionOfTechnology:
            "Der Kunde trägt dafür Sorge, dass die durch SigmaHeat verbaute Technik in den Objekten nicht für Ditte (z. B. Mieter) zugänglich ist. Eventuell auftretende Verfälschungen der ermittelten Messwerte, die auf Einwirkungen durch Dritte zurückzuführen sind, stehen allein in der Verantwortung des Kunden und begründen keinerlei Ansprüche gegen SigmaHeat.",
          reservationOfOwnership: "Eigentumsvorbehalt",
          ownedBySigmaHeat:
            "Bereitgestellte Messtechnik bleibt bei gewählter Kaufoption bis zur vollständigen Bezahlung Eigentum von SigmaHeat. Gerät der Kunde mit der Zahlung länger als 14 Tage in Verzug, hat SigmaHeat das Recht, vom Vertrag zurückzutreten und die überlassenen Gerätschaften vollständig zurückzufordern.",
          resaleOfEquipment:
            "Der Kunde ist zur Weiterveräußerung der unter Eigentumsvorbehalt stehenden Messtechnik im gewöhnlichen Geschäftsverkehr berechtigt. In diesem Falle tritt der Kunde jedoch in Höhe des Rechnungswertes der Forderung von SigmaHeat bereits jetzt alle Forderungen aus einer solchen Weiterveräußerung an SigmaHeat ab. Unbesehen der Befugnis von SigmaHeat, die Forderung selbst einzuziehen, bleibt der Kunde auch nach der Abtretung zum Einzug der Forderung ermächtigt. In diesem Zusammenhang verpflichtet SigmaHeat sich, die Forderung nicht selbst einzuziehen, solange und soweit der Kunde seinen Zahlungsverpflichtungen nachkommt, kein Antrag auf Eröffnung eines Insolvenz- oder ähnlichen Verfahrens über dessen Vermögen gestellt ist und keine Zahlungseinstellung vorliegt. Insoweit die oben genannten Sicherheiten die zu sichernden Forderungen um mehr als 10 % übersteigen, ist SigmaHeat verpflichtet, die Sicherheiten auf Verlangen des Kunden freizugeben.",
          deliveryTime: "Lieferung, Leistungszeitpunkt",
          deliveryDescription1:
            "Sofern die Leistung von SigmaHeat vereinbarungsgemäß ausschließlich die Lieferung von Messtechnik betrifft, erfolgt die Lieferung innerhalb Deutschlands mit DHL. Die Lieferzeit beträgt bis zu 14 Tage. Hiervon abweichende und mit dem Kunden individuell getroffene Vereinbarungen gelten vorrangig. Anfallende Versandkosten werden von SigmaHeat gesondert auf der Rechnung ausgewiesen. Für Lieferungen außerhalb Deutschlands fallen gesonderte Versandkosten an.",
          deliveryDescription2:
            "Soweit die Lieferung der Messtechnik nicht oder nicht vertragsgemäß erfolgt, muss der Kunde zur Bewirkung der Leistung eine Nachfrist von 2 Wochen setzen. Ansonsten ist der Kunde nicht berechtigt, vom Vertrag zurückzutreten.",
          deliveryDescription3:
            "Wurden mit dem Kunden darüber hinaus Installation der Messtechnik sowie die Überwachung und ggf. Analyse und Optimierung der Heizungsanlage vereinbart, erfolgt die Installation regelmäßig innerhalb von 90 Tagen nach Auftragsbestätigung. Je nach Auftragslage und in Ausnahmefällen kann sich dieser Zeitraum auf bis zu 120 Tage erstrecken. In diesem Fall wird der Kunde schriftlich über die Verzögerung informiert. Die Überwachung und Optimierung der Heizungsanlage beginnen unmittelbar nach Installation der Messtechnik.",
          saasDescription:
            "Hat der Kunde das Software as a Service (SaaS)-Paket erworben, beginnt die Dienstleistung unmittelbar nach Implementierung der SigmaHeat-Software. Die durchgängige unterbrechungsfreie Bereitstellung der Software sowie die durchgängige unterbrechungsfreie Analyse von Daten sowie deren Übermittlung wird nicht geschuldet.",
          warranty: "Gewährleistung",
          warrantyDescription1:
            "Es gelten die gesetzlichen Bestimmungen, soweit nachfolgend nichts anderes bestimmt ist.",
          warrantyDescription2:
            "Soweit die gelieferte Ware mangelhaft ist, ist der Kunde im Rahmen der gesetzlichen Bestimmungen berechtigt, Nacherfüllung in Form der Mangelbeseitigung oder Lieferung einer mangelfreien Sache zu verlangen. Das Wahlrecht über die Art der Nacherfüllung steht SigmaHeat zu. Die Nacherfüllung beinhaltet weder den Ausbau der mangelhaften Sache noch den erneuten Einbau, wenn SigmaHeat ursprünglich nicht zum Einbau verpflichtet war.",
          warrantyDescription3:
            "Voraussetzung für jegliche Gewährleistungsrechte ist, dass der Kunde alle nach § 377 HGB geschuldeten Untersuchungs- und Rügeobliegenheiten ordnungsgemäß erfüllt. Die Anzeige gemäß § 377 Abs. 1 HGB gilt als unverzüglich, wenn diese innerhalb von 5 Werktagen erfolgt, wobei zur Fristwahrung die rechtzeitige Absendung der Anzeige ausreichend ist.",
          warrantyDescription4:
            "Die Verjährungsfrist von Gewährleistungsansprüchen für die gelieferte Ware beträgt – außer im Fall von Schadensersatzansprüchen – zwölf Monate ab Erhalt der Ware.",
          warrantyDescription5:
            "SigmaHeat ist stets auf Zufriedenheit des Kunden bedacht und versucht in allen Fällen, eine zufriedenstellende Lösung zu finden. Sollte es SigmaHeat in Ausnahmefällen nicht gelingen, einen angezeigten Mangel innerhalb einer Frist von 90 Tagen zu beseitigen, kann der Kunde das vom Mangel betroffene Objekt aus der vertraglichen Vereinbarung entfernen und diesbezügliche bereits abgerechnete Kosten ab Eintritt des Mangels erstattet verlangen. Solche vereinbarungsgemäßen Dienstleistungen, die dem Kunden trotz Mangels zum Vorteil gereichen, kann SigmaHeat von der Rückforderung des Kunden in Abzug bringen.",
          compensationDueDate: "Vergütung, Fälligkeit",
          compensationDescription1:
            "Die Höhe der Vergütung bestimmt sich nach der zwischen den Parteien getroffenen Vereinbarung.  Sie wird nach ordnungsgemäßer Rechnungsstellung für das erste Kalenderjahr (Rumpfjahr) sofort zur Zahlung fällig, sofern zwischen den Parteien nichts anderes vereinbart wurde. Für weitere Kalenderjahre ist die vereinbarte Vergütung nach ordnungsgemäßer Rechnungsstellung im Voraus jährlich zu entrichten.",
          compensationDescription2:
            "Für reine Software as a Service (SaaS)-Leistungen (keine Bereitstellung oder Installation von Hardware) ist die vereinbarte Vergütung nach ordnungsgemäßer Rechnungsstellung je nach Vereinbarung im Voraus monatlich oder jährlich zu entrichten.",
          compensationDescription3:
            "Treffen die Parteien abweichende vertragliche Vereinbarungen zu Vergütung und Fälligkeit, gehen diese den Allgemeinen Geschäftsbedingungen vor.",
          creditreformDescription:
            "SigmaHeat ist Vertragspartner der Creditreform Hannover-Celle Bissel KG, Freundallee 11a, 30173 Hannover. Der Kunde willigt ein, dass SigmaHeat der Creditreform Rating AG Daten über die Beauftragung, Aufnahme und vereinbarungsgemäße Abwicklung der Geschäftsverbindung zwischen den Parteien übermittelt. Unabhängig davon wird SigmaHeat auch Daten aufgrund nicht vertragsgemäßen Verhaltens übermitteln. Diese Meldungen erfolgen nur, soweit dies nach Abwägung aller betroffenen Interessen zulässig ist. Die Creditreform speichert und übermittelt die Daten an ihre Vertragspartner im EU-Binnenmarkt, um diesen Informationen zur Beurteilung der Bonität von Unternehmen zu geben. Vertragspartner der Creditreform sind vor allem Kreditinstitute, Kreditkarten- und Leasinggesellschaften. Daneben erteilt die Creditreform auch Auskünfte an Handels-, Telekommunikations- und sonstige Unternehmen, die Leistungen und Lieferungen gegen Kredit gewähren. Die Creditreform stellt personenbezogene Daten nur zur Verfügung, wenn ein berechtigtes Interesse hieran im Einzelfall glaubhaft dargelegt wurde. Zur Schuldnerermittlung gibt die Creditreform Adressdaten bekannt. Bei der Erteilung von Auskünften kann die Creditreform ihren Vertragspartnern ergänzend einen aus ihrem Datenbestand errechneten Wahrscheinlichkeitswert zur Beurteilung des Kreditrisikos mitteilen (Score-Verfahren). SigmaHeat behält sich das Recht vor, dem Kunden aufgrund der Bonitätsprüfung die Zahlung auf Rechnung oder per Lastschrift zu verweigern. Weitere Informationen sind unter <a href='https://www.creditreform.de/datenschutz' target='_blank' rel='noreferrer'>https://www.creditreform.de/datenschutz</a> zu finden.",
          contractDurationCancellation: "Vertragsdauer, Kündigung",
          contractDurationDescription1:
            "Der Beginn der Leistungserbringung und die Vertragslaufzeit wird zwischen den Parteien individuell vereinbart. Eine ordentliche Kündigung vor Ende der vereinbarten Laufzeit ist ausgeschlossen.",
          contractDurationDescription2:
            "Unberührt bleibt das Recht zur außerordentlichen Kündigung aus wichtigem Grund. Ein solcher wichtiger Grund liegt für SigmaHeat insbesondere vor, wenn der Kunde",
          contractDurationReason1:
            "mit der Zahlung der Vergütung oder eines Teils hiervon mehr als 14 Tage in Verzug gerät oder",
          contractDurationReason2:
            "sich im persönlichen Kontakt gegenüber SigmaHeat (bzw. dessen Vertretern und Erfüllungsgehilfen) unangemessen verhält.",
          cancellationConditions:
            "Kündigungen sind nur wirksam, wenn diese mindestens in Textform erklärt werden.",
          rightOfWithdrawal: "Widerrufsrecht",
          noRightOfWithdrawal:
            "Dem Kunden steht kein Widerrufsrecht – insbesondere kein gesetzliches Fernabsatzwiderrufsrecht – hinsichtlich der unter Einbeziehung dieser AGB geschlossenen Verträge zu.",
          limitationOfLiability: "Haftungsbeschränkung",
          liabilityForIntentionalMisconductAndGrossNegligence:
            "SigmaHeat haftet unbeschränkt für Vorsatz und grobe Fahrlässigkeit. Für einfache Fahrlässigkeit haftet SigmaHeat nur für Schäden aus der Verletzung des Lebens, des Körpers, der Gesundheit oder einer wesentlichen Vertragspflicht (Verpflichtung, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertraut und vertrauen darf).",
          liabilityForSimpleNegligence:
            "Im Fall der einfach fahrlässigen Verletzung wesentlicher Vertragspflichten ist die Haftung von SigmaHeat der Höhe nach begrenzt auf den vorhersehbaren, typischerweise eintretenden Schaden. Im Übrigen ist die Haftung von SigmaHeat ausgeschlossen.",
          limitationOfLiabilityForSimpleNegligence:
            "Die vorstehenden Haftungsbeschränkungen gelten auch zugunsten der gesetzlichen Vertreter und Erfüllungsgehilfen von SigmaHeat.",
          exceptionsToLimitationOfLiability:
            "Die vorstehenden Haftungsbeschränkungen gelten nicht, soweit SigmaHeat einen Mangel arglistig verschwiegen hat oder eine Garantie für die Beschaffenheit der Ware übernommen hat. Das gleiche gilt für Ansprüche des Kunden nach dem Produkthaftungsgesetz.",
          electronicCommunication_heading: "Elektronische Kommunikation",
          electronicCommunication_content1:
            "Der Kunde stimmt ausdrücklich zu, dass die Kommunikation zwischen ihm und SigmaHeat überwiegend elektronisch, insbesondere über unverschlüsselte E-Mail, stattfindet. Es wird darauf hingewiesen, dass unverschlüsselte E-Mails nur eine eingeschränkte Sicherheit und Vertraulichkeit bieten.",
          electronicCommunication_content2:
            "Der Kunde hat sicherzustellen, dass die von ihm angegebene E-Mail-Adresse zutreffend ist, sodass die von SigmaHeat an diese Adresse versandten E-Mails empfangen werden können. Insbesondere hat der Kunde beim Einsatz von SPAM-Filtern sicherzustellen, dass alle durch SigmaHeat versandten E-Mails zugestellt werden können.",
          dataProtectionConfidentiality_heading: "Datenschutz, Vertraulichkeit",
          dataProtectionConfidentiality_content1:
            "Die Parteien verarbeiten die personenbezogenen Daten der jeweils anderen Partei im Einklang mit den datenschutzrechtlichen Vorgaben, insbesondere den Vorschriften der Datenschutzgrundverordnung (DSGVO) und des Bundesdatenschutzgesetzes (BDSG).",
          dataProtectionConfidentiality_content2:
            "Die Parteien verpflichten sich, alle Informationen und Daten, die zwischen ihnen im Zuge der Zusammenarbeit ausgetauscht werden, vertraulich zu behandeln und nicht an Dritte weiterzugeben, soweit nicht etwas anderes vereinbart ist bzw. der Vertragszweck nicht eine Weitergabe erfordert.",
          finalProvisions: "Schlussbestimmungen",
          finalProvisions_content1:
            "Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts.",
          finalProvisions_content2:
            "Ausschließlicher Gerichtsstand für alle Streitigkeiten aus oder im Zusammenhang mit Verträgen, die unter Einbeziehung dieser AGB zustande kommen, ist der Sitz von SigmaHeat.",
          finalProvisions_content3:
            "Sollten einzelne Bestimmungen dieser AGB einschließlich dieser Bestimmung ganz oder teilweise unwirksam sein oder werden, bleibt die Wirksamkeit der übrigen Regelungen unberührt. Anstelle der unwirksamen oder fehlenden Bestimmungen treten die jeweiligen gesetzlichen Regelungen.",
        },

        "privacy.privacyPolicyDesc":
          "Diese Datenschutzerklärung klärt über die Art, den Umfang und Zwecke der Erhebung und Verwendung von Daten der Besucher und Nutzer (nachfolgend zusammenfassend bezeichnet als „Nutzer“) durch die SigmaHeat GmbH (nachfolgend „Anbieter“) als datenschutzrechtlich verantwortliche Stelle auf.",
        "privacy.responsibleNameAndContact":
          "Name und Kontaktdaten des Verantwortlichen",
        "privacy.managedBy": "Vertreten durch ihren Geschäftsführer",
        "privacy.phone": "Tel.: ",
        "privacy.email": "E-Mail: ",
        "privacy.website": "Internetadresse: ",
        "privacy.personalDataProtection": "Schutz der personenbezogenen Daten",
        "privacy.personalDataProtectionDesc":
          "Der Anbieter trifft technische und organisatorische Maßnahmen gemäß den Anforderungen von Art. 32 DSGVO zum Schutz der personenbezogenen Daten des Nutzers. Alle Mitarbeiter des Anbieters, die mit der Verarbeitung personenbezogener Daten beschäftigt sind, sind auf das Datengeheimnis verpflichtet. Personenbezogene Daten des Nutzers werden bei der Übermittlung an die Websites grundsätzlich mittels HTTPS verschlüsselt.",
        "accessDataCollection.heading": "Erhebung von Zugriffsdaten",
        "accessDataCollection.info":
          "Der Anbieter erhebt zeitlich begrenzt Daten über jeden Zugriff auf das Onlineangebot (so genannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite), IP-Adresse und der anfragende Provider. Der Anbieter verwendet die Protokolldaten ohne Zuordnung zur Person des Nutzers oder sonstiger Profilerstellung entsprechend den gesetzlichen Bestimmungen nur für statistische Auswertungen zum Zweck des Betriebs, der Sicherheit und der Optimierung des Onlineangebotes. Diese Daten werden automatisch gelöscht. Der Anbieter behält sich jedoch vor, die Protokolldaten nachträglich zu überprüfen, wenn aufgrund konkreter Anhaltspunkte der berechtigte Verdacht einer rechtswidrigen Nutzung besteht. Diese Informationen und personenbezogenen Daten werden zu dem Zweck benötigt, die Inhalte der Website korrekt auszuliefern und zu optimieren, die Netz- und Informationssicherheit zu gewährleisten und die Website vor Angriffen, Störungen und Schädigungen zu schützen.",
        "personalDataCollection.heading":
          "Erhebung und Nutzung personenbezogener Daten",
        "personalDataCollection.info":
          "Die Verarbeitung dieser personenbezogenen Daten ist gem. Art. 6 Abs. 1 Satz 1 Buchst. f) DSGVO gerechtfertigt. Der Anbieter hat ein berechtigtes Interesse an der Datenverarbeitung zu dem Zweck,",
        connectionPurpose:
          "die Verbindung zur Webseite des Anbieters zügig aufzubauen",
        userFriendly:
          "eine nutzerfreundliche Anwendung der Webseite zu ermöglichen",
        securityStability:
          "die Sicherheit und Stabilität der Systeme zu erkennen und zu gewährleisten",
        administrationPurpose:
          "die Administration der Webseite zu erleichtern und zu verbessern. Die Verarbeitung erfolgt ausdrücklich nicht zu dem Zweck, Erkenntnisse über die Person des Besuchers der Webseite zu gewinnen.",
        "dataTransfer.heading": "Datenweiterleitung an Dritte",
        "dataTransfer.info1":
          "Personenbezogene Daten werden an Dritte übermittelt,",
        "dataTransfer.info2":
          "wenn nach Art. 6 Abs. 1 Satz 1 Buchst. a) DSGVO durch die betroffene Person ausdrücklich dazu eingewilligt wurde,",
        "dataTransfer.info3":
          "die Weitergabe nach Art. 6 Abs. 1 Satz 1 Buchst. f) DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass die betroffene Person ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe ihrer Daten hat,",
        "dataTransfer.info4":
          "für die Datenübermittlung nach Art. 6 Abs. 1 Satz 1 Buchst. c) DSGVO eine gesetzliche Verpflichtung besteht, und/oder",
        "dataTransfer.info5":
          "dies nach Art. 6 Abs. 1 Satz 1 Buchst. b) DSGVO für die Erfüllung eines Vertragsverhältnisses mit der betroffenen Person erforderlich ist. In anderen Fällen werden personenbezogene Daten nicht an Dritte weitergegeben.",
        "cookies.heading": "Cookies",
        "cookies.description":
          "Der Anbieter setzt im Rahmen seines Onlineangebotes „Cookies“ ein. Cookies sind kleine Dateien, die auf dem Computer der Nutzer abgelegt werden und Informationen für Anbieter speichern können. Temporäre Cookies werden nach dem Schließen des Browsers gelöscht, permanente Cookies bleiben für einen vorgegebenen Zeitraum erhalten und können beim erneuten Aufruf des Onlineangebotes die gespeicherten Informationen zur Verfügung stellen. Cookies werden zum einen eingesetzt, um die Nutzung des Dienstes zu erleichtern. So speichert ein Cookie den Warenkorbstatus eines Nutzers. Der Nutzer kann auf den Einsatz der Cookies Einfluss nehmen. Die meisten Browser verfügen eine Option mit der das Speichern von Cookies eingeschränkt oder komplett verhindert wird. Anbieter bemüht sich das Onlineangebot so zu gestalten, dass der Einsatz von Cookies nicht notwendig ist. Allerdings wird darauf hingewiesen, dass die Nutzung und insbesondere der Nutzungskomfort ohne Cookies eingeschränkt werden. Die durch Cookies verarbeiteten Daten sind für die o. g. Zwecke zur Wahrung der berechtigten Interessen des Anbieters nach Art. 6 Abs. 1 Satz 1 Buchst. f) DSGVO gerechtfertigt.",
        "rights.heading": "Ihre Rechte als betroffene Person",
        "rights.description":
          "Soweit Ihre personenbezogenen Daten anlässlich des Besuchs unserer Webseite verarbeitet werden, stehen Ihnen als „betroffene Person“ im Sinne der DSGVO folgende Rechte zu:",
        "information.heading": "Auskunft",
        "information.description":
          "Sie können von uns Auskunft darüber verlangen, ob personenbezogene Daten von Ihnen bei uns verarbeitet werden. Kein Auskunftsrecht besteht, wenn die Informationen aus besonderen Gründen, insbesondere wegen eines überwiegenden berechtigten Interesses eines Dritten, geheim gehalten werden müssen. Hiervon abweichend kann eine Pflicht zur Erteilung der Auskunft bestehen, wenn insbesondere unter Berücksichtigung drohender Schäden Ihre Interessen gegenüber dem Geheimhaltungsinteresse überwiegen. Das Auskunftsrecht ist ferner ausgeschlossen, wenn die Daten nur deshalb gespeichert sind, weil sie aufgrund gesetzlicher oder satzungsmäßiger Aufbewahrungsfristen nicht gelöscht werden dürfen oder ausschließlich Zwecken der Datensicherung oder der Datenschutzkontrolle dienen, sofern die Auskunftserteilung einen unverhältnismäßig hohen Aufwand erfordern würde und die Verarbeitung zu anderen Zwecken durch geeignete technische und organisatorische Maßnahmen ausgeschlossen ist. Sofern in Ihrem Fall das Auskunftsrecht nicht ausgeschlossen ist und Ihre personenbezogenen Daten von uns verarbeitet werden, können Sie von uns Auskunft über folgende Informationen verlangen:",
        "rights.purposes": "Zwecke der Verarbeitung",
        "rights.categories":
          "Kategorien der verarbeiteten personenbezogenen Daten",
        "rights.recipients":
          "Empfänger oder Kategorien von Empfängern, gegenüber denen personenbezogene Daten offengelegt wurden oder offengelegt werden, insbesondere bei Empfängern in Drittländern",
        "rights.storageDuration":
          "Die geplante Dauer, für die personenbezogene Daten gespeichert werden, oder falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer",
        "rights.correction":
          "Das Bestehen des Rechts, die Berichtigung, Löschung oder Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten oder das Recht, gegen diese Verarbeitung Widerspruch einzulegen",
        "rights.complaint":
          "Das Bestehen des Rechts, bei einer Aufsichtsbehörde für den Datenschutz Beschwerde einzulegen",
        "rights.source":
          "Verfügbare Informationen über die Herkunft der Daten, sofern personenbezogene Daten nicht bei der betroffenen Person erhoben wurden",
        "rights.automatedDecisionMaking":
          "Das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und angestrebten Auswirkungen automatisierter Entscheidungsfindungen",
        "rights.thirdCountry":
          "Informationen darüber, welche geeigneten Garantien gemäß Art. 46 Abs. 2 DSGVO zum Schutz der personenbezogenen Daten bei Übermittlung an Empfänger in Drittländern vorgesehen sind, sofern kein Beschluss der EU-Kommission über die Angemessenheit des Schutzniveaus gemäß Art. 45 Abs. 3 DSGVO vorliegt",
        "privacy.correctionTitle": "BERICHTIGUNG UND VERVOLLSTÄNDIGUNG",
        "privacy.correctionText":
          "Sofern Sie feststellen, dass uns unrichtige personenbezogene Daten von Ihnen vorliegen, können Sie von uns die unverzügliche Berichtigung dieser unrichtigen Daten verlangen. Bei unvollständigen Sie betreffenden personenbezogenen Daten können sie die Vervollständigung verlangen.",
        "privacy.deletionTitle": "LÖSCHUNG",
        "privacy.deletionText":
          "Sie haben ein Recht auf Löschung ('Recht auf Vergessenwerden'), sofern die Verarbeitung nicht zur Ausübung des Rechts auf freie Meinungsäußerung, des Rechts auf Information oder zur Erfüllung einer rechtlichen Verpflichtung oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt, erforderlich ist und einer der nachstehenden Gründe zutrifft:",
        "privacy.deletionReason1":
          "Die personenbezogenen Daten sind für die Zwecke, für die sie verarbeitet wurden, nicht mehr notwendig.",
        "privacy.deletionReason2":
          "Die Rechtfertigungsgrundlage für die Verarbeitung war ausschließlich Ihre Einwilligung, welche Sie widerrufen haben.",
        "privacy.deletionReason3":
          "Sie haben Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten eingelegt, die wir öffentlich gemacht haben.",
        "privacy.deletionReason4":
          "Sie haben Widerspruch gegen die Verarbeitung von uns nicht öffentlich gemachter personenbezogener Daten eingelegt und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor.",
        "privacy.deletionReason5":
          "Ihre personenbezogenen Daten wurden unrechtmäßig verarbeitet.",
        "privacy.deletionReason6":
          "Die Löschung der personenbezogenen Daten ist zur Erfüllung einer gesetzlichen Verpflichtung, der wir unterliegen, erforderlich.",
        "privacy.deletionExceptionText":
          "Kein Anspruch auf Löschung besteht, wenn die Löschung im Falle rechtmäßiger nicht automatisierter Datenverarbeitung wegen der besonderen Art der Speicherung nicht oder nur mit unverhältnismäßig hohem Aufwand möglich und Ihr Interesse an der Löschung gering ist. In diesem Fall tritt an die Stelle einer Löschung die Einschränkung der Verarbeitung.",
        "privacy.restrictionTitle": "EINSCHRÄNKUNG DER VERARBEITUNG",
        "privacy.restrictionText1":
          "Sie können von uns die Einschränkung der Verarbeitung verlangen, wenn einer der nachstehenden Gründe zutrifft: Sie bestreiten die Richtigkeit der personenbezogenen Daten. Die Einschränkung kann in diesem Fall für die Dauer verlangt werden, die es uns ermöglicht, die Richtigkeit der Daten zu überprüfen.",
        "privacy.restrictionText2":
          "Die Verarbeitung ist unrechtmäßig und Sie verlangen statt Löschung die Einschränkung der Nutzung Ihrer personenbezogenen Daten.",
        "privacy.restrictionText3":
          "Ihre personenbezogenen Daten werden von uns nicht länger für die Zwecke der Verarbeitung benötigt, die Sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen.",
        "privacy.restrictionText4":
          "Sie haben Widerspruch gem. Art. 21 Abs. 1 DSGVO eingelegt. Die Einschränkung der Verarbeitung kann so lange verlangt werden, wie noch nicht feststeht, ob unsere berechtigten Gründe gegenüber Ihren Gründen überwiegen. Einschränkung der Verarbeitung bedeutet, dass die personenbezogenen Daten nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses verarbeitet werden. Bevor wir die Einschränkung aufheben, haben wir die Pflicht, Sie darüber zu unterrichten.",
        "privacy.dataPortabilityTitle": "DATENÜBERTRAGBARKEIT",
        "privacy.dataPortabilityText":
          "Sie haben ein Recht auf Datenübertragbarkeit, sofern die Verarbeitung auf Ihrer Einwilligung (Art. 6 Abs. 1 Satz 1 Buchst. a) oder Art. 9 Abs. 2 Buchst. a) DSGVO) oder auf einem Vertrag beruht, dessen Vertragspartei Sie sind und die Verarbeitung mithilfe automatisierter Verfahren erfolgt. Das Recht auf Datenübertragbarkeit beinhaltet in diesem Fall folgende Rechte, sofern hierdurch nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden: Sie können von uns verlangen, die personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sie haben das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung unserseits zu übermitteln. Soweit technisch machbar, können Sie von uns verlangen, dass wir Ihre personenbezogenen Daten direkt an einen anderen Verantwortlichen übermitteln.",
        "privacy.objectionTitle": "WIDERSPRUCH",
        "privacy.objectionText1":
          "Sofern die Verarbeitung auf Art. 6 Abs. 1 Satz 1 Buchst. e) DSGVO (Wahrnehmung einer Aufgabe im öffentlichen Interesse oder in Ausübung öffentlicher Gewalt) oder auf Art. 6 Abs. 1 Satz 1 Buchst. f) DSGVO (berechtigtes Interesse des Verantwortlichen oder eines Dritten) beruht, haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten Widerspruch einzulegen. Das gilt auch für ein auf Art. 6 Abs. 1 Satz 1 Buchst. e) oder Buchst. f) DSGVO gestütztes Profiling. Nach Ausübung des Widerspruchsrechts verarbeiten wir Ihre personenbezogenen Daten nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.",
        "privacy.objectionText2":
          "Sie können jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zu Zwecken der Direktwerbung einlegen. Das gilt auch für ein Profiling, das mit einer solchen Direktwerbung in Verbindung steht. Nach Ausübung dieses Widerspruchsrechts werden wir die betreffenden personenbezogenen Daten nicht mehr für Zwecke der Direktwerbung verwenden. Sie haben die Möglichkeit, den Widerspruch telefonisch, per E-Mail, ggf. per Telefax oder an unsere zu Beginn dieser Datenschutzerklärung aufgeführte Postadresse unseres Geschäftssitzes formlos mitzuteilen.",
        "privacy.revocationTitle": "WIDERRUF EINER EINWILLIGUNG",
        "privacy.revocationText":
          "Sie haben das Recht, eine erteilte Einwilligung jederzeit mit Wirkung für die Zukunft zu widerrufen. Der Widerruf der Einwilligung kann telefonisch, per E-Mail, ggf. per Telefax oder an unsere Postadresse formlos mitgeteilt werden. Durch den Widerruf wird die Rechtmäßigkeit der Datenverarbeitung, die aufgrund der Einwilligung bis zum Eingang des Widerrufs erfolgt ist, nicht berührt. Nach Eingang des Widerrufs wird die Datenverarbeitung, die ausschließlich auf Ihrer Einwilligung beruhte, eingestellt.",
        "privacy.complaintTitle": "BESCHWERDE",
        "privacy.complaintText":
          "Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten rechtswidrig ist, können Sie Beschwerde bei einer Aufsichtsbehörde für den Datenschutz einlegen, die für den Ort Ihres Aufenthaltes oder Arbeitsplatzes oder für den Ort des mutmaßlichen Verstoßes zuständig ist.",
        "privacy.updateTitle": "AKTUALISIERUNG DIESER DATENSCHUTZERKLÄRUNG",
        "privacy.updateText":
          "Diese Datenschutzerklärung hat den Stand vom 01. Juni 2021. Wir behalten uns vor, die Datenschutzerklärung zu gegebener Zeit zu aktualisieren, um den Datenschutz zu verbessern und/oder an geänderte Behördenpraxis oder Rechtsprechung anzupassen.",

        contact: {
          chatTitle: "Schreib uns",
          chatDescription: "Unser freundliches Team steht dir zur Verfügung.",
          addressTitle: "Besuch uns",
          addressDescription: "in unserem Büro",
          phoneTitle: "Ruf uns an",
          phoneDescription: "Mo-Fr von 8 bis 17 Uhr.",
          heading:
            "Wir freuen uns darauf, gemeinsam mit dir das Klima messbar zu schützen.",
          description: "#together we beat the heat",
          placeholder: "Erzähl uns mehr über dich und was du vor hast.",
          submit: "Lass uns anfangen",
          errorMSG:
            "Es gab einen Fehler beim Versuch, die Nachricht zu senden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
          successMSG:
            "Ihr Nachricht wurde empfangen und wir werden uns in Kürze mit Ihnen in Verbindung setzen.",
          mail: "E-Mail",
          name: "Dein Name",
          redirected:
            "Bitte warten Sie, während Sie automatisch weitergeleitet werden innerhalb von: ",
          privacyPolicyMSG:
            "Um diese Nachricht zu übermitteln, ist es erforderlich, dass Sie unsere Datenschutz- und Richtlinienbedingungen akzeptieren.",
        },

        dsgvo: {
          processingDataTitle:
            "Verarbeitung (personenbezogener) Daten durch den Verantwortlichen des Online-Bewerbungsverfahrens",
          generalTitle: "Allgemeines",
          dataPrivacyNotice:
            "Wir stellen Ihnen diese Datenschutzerklärung, welche sich ausschließlich auf die im Rahmen des Online-Bewerbungsverfahrens erhobenen Daten bezieht, zur Verfügung, um Sie darüber zu informieren, wie wir mit Ihren im Rahmen des Bewerbungsverfahrens erhobenen personenbezogenen Daten bei uns umgehen.",
          responsibleEntityTitle: "Verantwortliche Stelle",
          responsibleEntityText1:
            "Verantwortliche Stelle im Sinne des Datenschutzrechts ist:",
          handelsregisterEntry: "Eintragung im Handelsregister",
          registerNumber: "Registernummer:",
          registerCourt: "Registergericht:",
          personalDataTitle:
            "Personenbezogene Daten im Rahmen des Bewerbungsprozesses",
          applicationProcessText1:
            "Sollten Sie sich bei uns auf elektronischem Wege, also per E-Mail oder über unser Webformular bewerben, dann erheben und verarbeiten wir Ihre personenbezogenen Daten zum Zwecke der Abwicklung des Bewerbungsverfahrens und zur Durchführung vorvertraglicher Maßnahmen. Mit dem Absenden einer Bewerbung auf unserer Recruiting-Seite bekunden Sie Ihr Interesse, eine Beschäftigung bei uns aufnehmen zu wollen. Sie übermitteln uns in diesem Zusammenhang personenbezogene Daten, die wir ausschließlich zum Zwecke Ihrer Stellensuche/ Bewerbung nutzen und speichern.",
          applicationProcessText2:
            "Insbesondere werden dabei die folgenden Daten erhoben:",
          applicationProcessText3: "Name (Vor- sowie Nachname)",
          applicationProcessText4: "E-Mail-Adresse",
          applicationProcessText5: "Telefonnummer",
          applicationProcessText6: "LinkedIn-Profil (optional)",
          applicationProcessText7:
            "Kanal, wie Sie auf uns aufmerksam geworden sind",
          additionalDocuments:
            "Außerdem haben Sie die Möglichkeit, aussagekräftige Dokumente wie ein Anschreiben, Ihren Lebenslauf und Zeugnisse hochzuladen. Darin befinden sich ggf. weitere personenbezogene Daten wie Geburtsdatum, Adresse etc.",
          dataAccess:
            "Auf Ihre Daten haben nur autorisierte Mitarbeiter(innen) aus dem Personalbereich bzw. in das Bewerbungsverfahren involvierte Mitarbeiter Zugriff.",
          dataStoragePurpose:
            "Die Speicherung der personenbezogenen Daten erfolgt grundsätzlich ausschließlich für den Zweck, der Besetzung der vakanten Stelle, für die Sie sich beworben haben.",
          dataRetention:
            "Ihre Daten werden über einen Zeitraum von  <strong>180</strong> Tagen über die Beendigung des Bewerbungsverfahrens hinaus gespeichert. Dies erfolgt in der Regel zur Erfüllung von rechtlichen Verpflichtungen bzw. der Abwehr von etwaigen Ansprüchen aus gesetzlichen Vorschriften. Anschließend sind wir verpflichtet, Ihre Daten zu löschen bzw. zu anonymisieren. In diesem Falle stehen uns die Daten nur noch als sogenannte Metadaten ohne direkten Personenbezug für statistische Auswertungen zur Verfügung (beispielsweise Frauen- bzw. Männeranteil an Bewerbungen, Anzahl an Bewerbungen pro Zeitraum etc.).",
          talentPoolStorage:
            "Darüber hinaus behalten wir uns vor, Ihre Daten zur Aufnahme in unseren „Talent Pool“ für  <strong>365</strong> Tage nach Beendigung des Bewerbungsverfahrens zu speichern, um etwaige weitere interessante Stellen für Sie zu identifizieren. Dies gilt beispielsweise auch für Bewerbung auf einen Ausbildungs- oder Praktikumsplatz. Durch Akzeptieren der Datenschutzerklärung willigen Sie in die etwaige weitergehende Speicherung Ihrer Daten und die Aufnahme in unseren „Talent Pool“ ein.",
          jobOfferAcceptance:
            "Sofern Sie im Rahmen des Bewerbungsverfahrens ein Angebot für eine Anstellung bei uns erhalten und dieses annehmen, speichern wir die im Rahmen des Bewerbungsverfahrens erhobenen personenbezogenen Daten mindestens für die Dauer des Angestelltenverhältnisses.",
          dataDisclosureTitle: "Weitergabe der Daten an Dritte",
          dataDisclosureText:
            "Die im Rahmen Ihrer Bewerbung übermittelten Daten werden per TLS-Verschlüsselung übertragen und in einer Datenbank gespeichert. Diese Datenbank wird von der Personio GmbH, welche eine Personalverwaltungs- und Bewerbermanagement-Software anbietet (<a href='https://www.personio.de/impressum/' target='_blank' rel='noreferrer'>https://www.personio.de/impressum/</a>), betrieben. Personio ist in diesem Zusammenhang unser Auftragsverarbeiter nach Art. 28 DS-GVO. Die Grundlage für die Verarbeitung ist hierbei ein Vertrag zur Auftragsverarbeitung zwischen uns als verantwortliche Stelle und Personio.",
          individualRightsTitle: "Betroffenenrechte",
          individualRightsText:
            "Sofern durch uns als verantwortliche Stelle personenbezogenen Daten verarbeitet werden, haben Sie als betroffene Person in Abhängigkeit von Rechtsgrundlage und Zweck der Verarbeitung bestimmte Rechte aus Kapitel III der EU Datenschutzgrundverordnung (DS-GVO), dabei ggf. insbesondere Recht auf Auskunft (Art.15 DS-GVO), Recht auf Berichtigung (Art.16 DS-GVO), Recht auf Löschung (Art. 17 DS-GVO), Recht auf Einschränkung der Verarbeitung (Art. 18 DS-GVO), Recht auf Datenübertragbarkeit (Art. 20 DS-GVO), Recht auf Widerspruch (Art. 21 DSGVO). Sofern die Verarbeitung von personenbezogenen Daten auf Ihrer Einwilligung beruht, haben sie nach Art. 7 III DS-GVO das Recht auf Widerruf dieser datenschutzrechtlichen Einwilligung. Bitte wenden Sie sich zur Geltendmachung Ihrer Betroffenenrechte in Bezug auf die in diesem Online-Bewerbungsverfahren verarbeiteten Daten an unseren Datenschutzbeauftragten (siehe Ziffer 2.).",
          concludingProvisionsTitle: "Abschließende Bestimmungen",
          concludingProvisionsText:
            "Wir behalten uns vor, diese Datenschutzerklärung jederzeit anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen des Bewerbungsverfahrens oder Ähnlichem abzubilden. Für einen erneuten Besuch dieser Recruiting-Seite oder eine erneute Bewerbung gilt dann die neue Datenschutzerklärung. Zusätzlich zu dieser Datenschutzerklärung können Sie auf <a href='sigmaheat.de/privacy-policy' target='_blank' rel='noreferrer'>sigmaheat.de/privacy-policy</a> unsere allgemeine Datenschutzerklärung einsehen.",
          processingDataTitle1:
            "Verarbeitung (personenbezogener) Daten durch den Betreiber der Recruiting-Seite",
          processingDataText:
            "Diese Recruiting-Seite wird von der Personio SE & Co. KG betrieben, einem Unternehmen mit Sitz in Deutschland, welche eine Personalverwaltungs- und Bewerbermanagement-Software anbietet (<a href='https://www.personio.de/impressum/' target='_blank' rel='noreferrer'>https://www.personio.de/impressum/</a>). Die im Rahmen Ihrer Bewerbung übermittelten Daten werden per TLS-Verschlüsselung übertragen und in einer Datenbank gespeichert. Für diese Daten ist allein das Unternehmen verantwortlich im Sinne von Art. 24 DS-GVO, das dieses Online-Bewerbungsverfahren durchführt. Personio ist lediglich Betreiber der Software und dieser Recruiting-Seite und in dem Zusammenhang Auftragsverarbeiter nach Art. 28 DS-GVO. Die Grundlage für die Verarbeitung durch Personio ist hierbei ein Vertrag zur Auftragsverarbeitung zwischen der verantwortlichen Stelle und Personio. Zudem verarbeitet die Personio SE & Co. KG zur Erbringung ihrer Dienstleistungen, insbesondere für den Betrieb dieser Recruiting-Seite, weitere Daten, die zum Teil auch personenbezogene Daten sein können. Darauf wird im Folgenden näher eingegangen.",
          dataProtectionOfficer:
            "Data Protection Officer: <a href='mailto:datenschutz@personio.de'>datenschutz@personio.de</a>",
          accessLogsTitle: "Zugriffsprotokolle („Server-Logs“)",
          accessLogsText:
            "Mit jedem Zugriff auf diese Recruiting-Seite werden automatisch allgemeine Protokolldaten, sogenannte Server-Logs, erfasst. Diese Daten sind in der Regel Pseudonyme und erlauben daher keine Rückschlüsse auf eine natürliche Person. Ohne diese Daten wäre es technisch teilweise nicht möglich, die Inhalte der Software auszuliefern und darzustellen. Zudem ist die Verarbeitung dieser Daten aus Sicherheitsaspekten, insbesondere zur Zugriffs-, Eingabe- und Weitergabe- und Speicherkontrolle, zwingend notwendig. Darüber hinaus können die anonymen Informationen für statistische Zwecke sowie für die Optimierung des Angebots und der Technik verwendet werden. Zudem können die Log-Files bei Verdacht auf eine rechtswidrige Nutzung der Software nachträglich kontrolliert und ausgewertet werden. Die Rechtsgrundlage hierfür findet sich in §25 Abs 2 Satz 2 TTDSG. Erfasst werden allgemein Daten wie der Domainname der Webseite, der Webbrowser und Webbrowser-Version, das Betriebssystem, die IP-Adresse sowie der Zeitstempel des Zugriffs auf die Software. Der Umfang dieser Protokollierung geht nicht über den gängigen Umfang jeder anderen Webseite im Internet hinaus. Die Speicherdauer dieser Zugriffsprotokolle beträgt bis zu 7 Tage. Ein Widerspruchsrecht besteht nicht.",
          errorLogsTitle: "Fehlerprotokolle („Error-Logs“)",
          errorLogsText:
            "Zum Zwecke der Fehleridentifizierung und -behebung werden sogenannte Fehlerprotokolle („Error-Logs“) angefertigt. Dies ist zwingend erforderlich, um auf mögliche Probleme bei der Darstellung und Umsetzung von Inhalten möglichst zeitnah reagieren zu können (berechtigtes Interesse). Diese Daten sind in der Regel Pseudonyme und erlauben daher keine Rückschlüsse auf eine natürliche Person. Die Rechtsgrundlage hierfür findet sich in §25 Abs 2 Satz 2 TTDSG. Bei Auftreten einer Fehlermeldung werden allgemeine Daten wie der Domainname der Webseite, der Webbrowser und Webbrowser-Version, das Betriebssystem, die IP-Adresse sowie der Zeitstempel bei Auftreten der entsprechenden Fehlermeldung/ -spezifikation erfasst. Die Speicherdauer dieser Fehlerprotokolle beträgt bis zu 7 Tage. Ein Widerspruchsrecht besteht nicht.",
          cookieUsageTitle: "Einsatz von Cookies",
          cookieUsageText:
            "Teilweise werden auf dieser Recruiting-Seite sogenannte Cookies eingesetzt. Dies sind kleine Textdateien, welche auf dem Gerät, mit welchem Sie auf diese Recruiting-Seite zugreifen, gespeichert werden. Grundsätzlich dienen Cookies dazu, die Sicherheit beim Besuch einer Website zu gewährleisten („unbedingt erforderlich“), gewisse Funktionalitäten wie Standard-Spracheinstellungen umzusetzen („funktional“), das Nutzungserlebnis oder die Performance auf der Webseite zu verbessern („Performance“) oder zielgruppenbasierte Werbung auszuspielen („Marketing“). Auf dieser Recruiting-Seite kommen grundsätzlich nur unbedingt erforderliche, funktionale und Performance Cookies zum Einsatz, insbesondere zur Umsetzung gewisser Voreinstellungen wie bspw. die Sprache, zur Identifizierung des Bewerbungskanals oder zur Analyse der Performance einer Stellenausschreibung, über die ein Nutzer auf diese Recruiting-Seite gelangt ist. Die Nutzung von Cookies ist für die Erbringung unserer Dienstleistungen und damit für die Erfüllung des Vertrags (Art. 6 (1) b) DS-GVO) zwingend erforderlich. Speicherdauer: Bis zu 1 Monat bzw. bis zur Beendigung der Browser-Session Widerspruchsrecht: Sie können über Ihre Browser-Einstellungen selbst bestimmen, ob Sie Cookies erlauben oder der Nutzung von Cookies widersprechen möchten. Bitte beachten Sie, dass eine Deaktivierung der Cookies zu einer eingeschränkten oder komplett unterbundenen Funktionalität dieser Recruiting-Seite führen kann.",
          individualRightsText1:
            "Sofern durch die Personio SE & Co. KG als verantwortliche Stelle personenbezogenen Daten verarbeitet werden, haben Sie als betroffene Person in Abhängigkeit von Rechtsgrundlage und Zweck der Verarbeitung bestimmte Rechte aus Kapitel III der EU Datenschutzgrundverordnung (DS-GVO), dabei ggf. insbesondere Recht auf Auskunft (Art.15 DS-GVO), Recht auf Berichtigung (Art.16 DS-GVO), Recht auf Löschung (Art. 17 DS-GVO), Recht auf Einschränkung der Verarbeitung (Art. 18 DS-GVO), Recht auf Datenübertragbarkeit (Art. 20 DS-GVO), Recht auf Widerspruch (Art. 21 DSGVO). Sofern die Verarbeitung von personenbezogenen Daten auf Ihrer Einwilligung beruht, haben sie nach Art. 7 III DS-GVO das Recht auf Widerruf dieser datenschutzrechtlichen Einwilligung. Bitte wenden Sie sich zur Geltendmachung Ihrer Betroffenenrechte in Bezug auf die für den Betrieb dieser Recruiting-Seite verarbeiteten Daten an den Datenschutzbeauftragten der Personio SE & Co. KG (siehe Ziffer B.).",
          concludingProvisionsText1:
            "Personio behält sich vor, diese Datenschutzerklärung jederzeit anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen der Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer Services. Für einen erneuten Besuch dieser Recruiting-Seite oder eine erneute Bewerbung gilt dann die neue Datenschutzerklärung.",
        },

        applyingFor: "Sie bewerben sich um:",

        impressum: {
          heading: "Impressum",
          requiredInfo:
            "Pflichtangaben gemäß § 5 Telemediengesetz (TMG) und § 2 der Verordnung über Informationspflichten für Dienstleistungserbringer (DL-InfoV)",
          responsible: "Verantwortlicher",
          authorities: "Zulassungs-/Aufsichtsbehörden:",
          registerCourt: "Zuständiges Registergericht",
          tradeRegisterNumber: "Handelsregisternummer",
          vatId: "Umsatzsteuer-ID",
          responsibleContent: "Inhaltlich verantwortlich gem. § 55 II 1 RStV",
          responsibleWebsite:
            "Verantwortlich für die redaktionellen Inhalte der Website sigmaheat.de und der über diese Website abrufbaren redaktionellen Inhalte in den sozialen Netzwerken (Instagram, LinkedIn) ist die",
          disputeResolution: "Streitbeilegung",
          euCommissionText:
            "Die EU-Kommission hat eine Online-Plattform zur Streitbeilegung eingerichtet, die Sie unter <a href='http://ec.europa.eu/consumers/odr/' target='_blank' rel='noreferrer'>http://ec.europa.eu/consumers/odr/</a> finden. Wir sind, sofern es Streitigkeiten mit Verbrauchern beizulegen gilt, weder verpflichtet noch bereit, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen. Für den Fall, dass Meinungsverschiedenheiten mit der SigmaHeat GmbH nicht beigelegt werden können, bieten wir eine interne Streitschlichtung an.",
          internalDisputeResolution: "Wenden Sie sich hierzu an Maik Brinkmann",
          copyRight: "Urheberrechte",
          copyRightContent:
            "Es wird darauf hingewiesen, dass diese Website und ihre Inhalte urheberrechtlich geschützt sind.",
          disclaimer: {
            heading: "Haftungsausschluss",
            contentAndLink:
              "Alle Angaben und Daten wurden nach bestem Wissen erstellt, es wird jedoch keine Gewähr für deren Vollständigkeit und Richtigkeit übernommen. Die SigmaHeat GmbH hat keinen Einfluss auf die Inhalte von per Link verknüpften externen Seiten. Deshalb distanziert sie sich hiermit ausdrücklich von allen Inhalten aller gelinkten Seiten.",
            liability:
              "Jegliche Haftung im Zusammenhang mit der Nutzung von Inhalten der Website sigmaheat.de und die über diese Website aufrufbaren Inhalte in den sozialen Netzwerken oder dem Vertrauen auf deren Richtigkeit ist daher ausgeschlossen.",
          },
          privacy: "Datenschutz",
          privacyPolicyLink: "Unsere Datenschutzerklärung finden Sie",
          here: "hier",
        },
        typo: {
          message1: "Schön, dich hier zu sehen 😊",
          message2: "Hey, möchtest du dich SigmaHeat anschließen?",
          message3: "Gemeinsam besiegen wir die Hitze.",
          message4: "Moment, wo ist dein Kaffee?",
        },
        energieservices: {
          craftsmanship: "Handwerk mal anders",
          visionaries:
            "Wir sehen uns nicht nur als Handwerker, sondern als Visionäre, die gemeinsam mit dir die Zukunft gestalten.",
          welcome: "Willkommen bei uns…",
          aboutUs:
            "…den SigmaHeat Energieservices. Dem Handwerksunternehmen mit dem gewissen Extra! Bei uns trifft traditionelles Handwerk auf innovative Ideen und Start-up Charakter.",
          scrollFurther:
            "Scrolle weiter um mehr über uns zu erfahren und lasse dich von unserer Idee begeistern.",
          ourGoals:
            "Unsere Ziele – höchste Standards bei Nachhaltigkeit und Qualität",
          qualityStandards:
            "Wir verwenden ausschließlich hochwertige Materialien und arbeiten nach den neuesten Standards und Technologien. Unseren Kunden bieten wir einen zuverlässigen und erstklassigen Service, der auf langfristigen Erfolg ausgerichtet ist.",
          climateHero: "Klimaheld:in?",
          servicesDescription:
            "Mit unseren Services schonst du als Klimaheld:in nicht nur die Umwelt, sondern auch deinen Geldbeutel.",
          whyWeAreDifferent: "Warum wir anders sind",
          aboutUsDescription:
            "Wir sind SigmaHeat Energieservices, ein junges, digitales Unternehmen, das sich auf die Optimierung von Heizungsanlagen spezialisiert hat. Unser Ziel ist es, aktiv zum Klimaschutz beizutragen, indem wir Bestandsheizungsanlagen ganzheitlich verstehen und optimieren. Durch die Zusammenarbeit mit SigmaHeat analysieren wir die Funktionalität und Missstände in den Anlagen unserer Kunden. Unser Team von SigmaHeat Energieservices führt die optimierenden Umbauarbeiten durch und jeder Mitarbeiter trägt durch seine Arbeit zum Klimaschutz bei.",
          enoughHeated: "Schon genug aufgeheizt?",
          contactUsDescription:
            "Nimm Kontakt mit uns auf. Wir beraten dich gerne, welche Lösung für dich die Beste ist.",
          contactNow: "Jetzt Kontakt aufnehmen!",
          modernCraftsmanship: "Handwerk geht modern – wollen wir wetten?",
          differentThanOthers:
            "Wir sind anders als andere SHK-Betriebe – denn bei uns trifft traditionelles Handwerk auf innovative Ideen und Start-up Charakter. Unser Ziel ist es, dass du von unserer Leidenschaft für das Handwerk genauso begeistert bist wie wir. Handwerk anders erleben lassen, dafür setzen wir auf modernste Technik und kreative Lösungen.",
          hydraulicBalancing: "Hydraulischer Abgleich nach Verfahren B",
          hydraulicBalancingDescription:
            "Dabei werden die Wärmebedarfe in den Räumen ermittelt und der optimale Wasserdurchfluss für jeden Heizkörper berechnet. Die Heizungsanlage wird entsprechend eingestellt und Thermostatventile werden angepasst. Dadurch wird eine gleichmäßige Wärmeverteilung und eine effizientere Heizungsanlage erreicht. In älteren Gebäuden sind häufig noch nicht vor einstellbare Thermostatventile verbaut, diese müssen zunächst durch voreinstellbare Thermotatventile ersetzt werden. Wir bieten das Komplettpaket an!",
          pumpReplacement: "Pumpentausch",
          pumpReplacementDescription:
            "Der Austausch alter Zirkulations- oder Heizungspumpen durch moderne Hocheffizienzpumpen bietet langfristige Vorteile. Diese Pumpen sparen Kosten, senken den Stromverbrauch und reduzieren CO2-Emissionen. Zudem haben sie eine längere Lebensdauer und erfordern weniger Wartung. Durch einen Pumpentausch bringst du deine Heizungsanlage auf den neuesten Stand und sparst langfristig Energie und Kosten.",
          bufferStorageRetrofit: "Pufferspeicher Nachrüstung",
          bufferStorageRetrofitDescription:
            "Ein Pufferspeicher speichert Wärmeenergie und ermöglicht einen kontinuierlichen und gleichmäßigen Betrieb der Heizung. Dadurch werden Energieeinsparungen und eine Reduzierung des Verschleißes der Heizungsanlage erreicht. Pufferspeicher sind flexibel einsetzbar und passen sich den individuellen Bedürfnissen an. Sie verbessern die Energieeffizienz und können auch bei der Installation einer Wärmepumpe sinnvoll sein. Der Einbau eines Pufferspeichers ist eine langfristige Investition in die Zukunft der Heizungsanlage.",
          heatingMaintenance: "Heizungswartung",
          heatingMaintenanceDescription:
            "Eine regelmäßige Heizungswartung ist aus Sicherheits- und Klimaschutzgründen wichtig. Durch die Wartung arbeitet die Heizungsanlage effizienter, verbraucht weniger Energie und reduziert den CO2-Ausstoß. Verschleißteile werden ausgetauscht, der Brennraum gereinigt und Sicherheitseinrichtungen überprüft. Eine frühzeitige Erkennung von Störungen spart Kosten und verhindert einen Totalausfall. Eine Heizungswartung trägt zur Verlängerung der Lebensdauer der Anlage und zum Klimaschutz bei.",
          heatingInspectionOptimization:
            "Heizungsprüfung und -optimierung nach GEG",
          heatingInspectionOptimizationDescription:
            "Gemäß EnSimiMaV §2 müssen Gebäudeeigentümer mit Erdgas-Wärmeerzeugungsanlagen eine Heizungsprüfung durchführen und Optimierungsmaßnahmen ergreifen. Dies umfasst die Überprüfung technischer Parameter wie Vorlauftemperatur, Heizkurve und Zirkulationsbetrieb sowie die Aktivierung von Absenkungen. Das Ergebnis und erforderliche Optimierungsmaßnahmen müssen bis zum 15. September 2024 dokumentiert und umgesetzt werden. Unsere Monteure bieten fachgerechte Heizungsprüfung und Optimierungen an, idealerweise in Kombination mit einer Heizungswartung.",
          greeting: "Wir freuen uns auf Dich!",
          teammembers:
            "Unser motiviertes Team besteht aus erfahrenen Handwerkern und kreativen Köpfen, die gemeinsam an einem Strang ziehen. Wir sind stolz auf unsere Arbeit und auf die Ergebnisse, die wir gemeinsam mit unseren Kunden erreichen.",
          apply:
            "Lass uns gemeinsam das Handwerk revolutionieren und unsere Visionen in die Tat umsetzen. Bewirb dich jetzt auf eine unserer Positionen.",
        },
        cookie:
          "Bitte stimmen Sie der Verarbeitung der Daten zu, damit wir Ihnen unseren Service anbieten können. <1>Hier</1> finden Sie unsere Datenschutzerklärung.",
        allow: "Erlauben",
      },
    },
  },
  lng: "de",
});

export default i18next;
