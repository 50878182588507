import { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/swiper-bundle.css';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

export const ArticleSlider = ({ slides }) => {
    const swiperRef = useRef(null);


    useEffect(() => {
        const swiper = new Swiper(swiperRef.current, {
            slidesPerView: 3,
            spaceBetween: 40,
            initialSlide: 0,
            slidesPerGroup: 1,
            breakpoints: {
                0: {
                    slidesPerView: 1.2,
                    spaceBetween: 20
                },
                768: {
                    slidesPerView: 1.75,
                },
                1024: {
                    slidesPerView: 3,
                }
            }
        });

        return () => {
            swiper.destroy();
        }
    }, [])

    return (
        <div className='swiper-container' ref={swiperRef}>
            <div className='swiper-wrapper'>
                {slides.map((slide, i) => {
                    return (
                        <div className='swiper-slide' key={`article-slide-${i}`}>
                            <Link className='manipulation__slider' to={'default'}>
                                <h2 className='manipulation__heading'>{slide.heading}</h2>
                                <p className='manipulation__button'>Listen</p>
                            </Link>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}



export const JobsSlider = ({ slides }) => {
    const swiperRef = useRef(null);

    useEffect(() => {
        const swiper = new Swiper(swiperRef.current, {
            speed: 550,
            slidesPerView: 1,
            spaceBetween: 20,
            loop: true,
            centeredSlides: true,
            centerInsufficientSlides: !0,
            shortSwipes: !1,
            longSwipesMs: 100,
            threshold: 10,
            longSwipesRatio: .33,
            observer: !0,
            breakpoints: {
                768: {
                    spaceBetween: 40
                }
            },
            navigation: {
                nextEl: '.slider__control__right',
                prevEl: '.slider__control__left',
            },
            on: {
                realIndexChange: index => {
                    document.querySelector('.slider__control__position').innerHTML = `${index.realIndex + 1} / ${swiper.slides.length}`
                }
            }
        });

        const nextButton = document.querySelector('.slider__control__right');
        const prevButton = document.querySelector('.slider__control__left');
        document.querySelector('.slider__control__position').innerHTML = `${swiper.activeIndex} / ${swiper.slides.length}`;

        nextButton.addEventListener('click', handleNextClick);
        prevButton.addEventListener('click', handlePrevClick);

        function handleNextClick() {
            swiper.slideNext(550);
        }

        function handlePrevClick() {
            swiper.slidePrev(550);
        }

        return () => {
            nextButton.removeEventListener('click', handleNextClick);
            prevButton.removeEventListener('click', handlePrevClick);
            swiper.destroy();
        }
    }, [])

    return (
        <div className='swiper-container' ref={swiperRef}>
            <div className='swiper-wrapper'>
                {slides.map((slide, i) => {
                    return (
                        <div className='swiper-slide' key={`job-slide-${i}`}>
                            <Link className='swiper-card' to={'/job/'+slide.id}>
                                <div className='slider__top'>
                                    <span className='slider__job__tag'>{slide.tag}</span>
                                    <div className='slider__job__info'>
                                        <h2 className='slider__job__title'>{slide.title}</h2>
                                        <h2 className='slider__job__position'>{slide.position}</h2>
                                    </div>
                                </div>
                                <p className='slider__job__location'>{slide.location}</p>
                            </Link>
                        </div>
                    )
                })}
            </div>

            <div className='slider__control__wrapper'>
                <button className='slider__control__left'></button>
                <span className='slider__control__position'>1 of 5</span>
                <button className='slider__control__right'></button>
            </div>
        </div>
    )
}



export const ClientsSlider = ({ slides }) => {
    const swiperRef = useRef(null);


    useEffect(() => {
        const swiper = new Swiper(swiperRef.current, {
            initialSlide: 1,
            slidesPerView: 3,
            spaceBetween: 20,
            loop: true,
        });

        return () => {
            swiper.destroy();
        }
    }, [slides])

    useEffect(() => {
        if (swiperRef.current) swiperRef.current.swiper.update();
    }, [slides]);

    return (
        <div className='swiper-container' ref={swiperRef}>
            <div className='swiper-wrapper'>
                {slides.map((slide, i) => {
                    return (
                        <div className='swiper-slide' key={`client-slide-${i}`}>
                            <Link className='client__slider__card' to={'default'}>
                                <img src={slide.img} alt={slide.client} />
                            </Link>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}



export const ESSlider = ({ slides }) => {
    const swiperRef = useRef(null);

    const flipCard = (e) => {
        e.preventDefault();
        let moveTarget = e.target;
        while (!moveTarget.classList.contains('swiper-slide') && !moveTarget.classList.contains('swiper-wrapper')) moveTarget = moveTarget.parentNode;
        if (moveTarget.classList.contains('swiper-wrapper')) return;
        
        [...document.querySelectorAll('.swiper-slide > .flipcard__inner')].forEach(slide => {
            if (slide === moveTarget.querySelector('.flipcard__inner')) {
                moveTarget.classList.toggle('open')
            }
        })
    }


    useEffect(() => {
        Swiper.use([Navigation, Pagination]);

        const swiper = new Swiper(swiperRef.current, {
            slidesPerView: 2.5,
            spaceBetween: 30,
            slidesPerGroup: 1,
            pagination: {
                el: '.swiper-pagination',
                dynamicBullets: true,
                clickable: true
            },
        });

        document.querySelector('.swiper-wrapper').addEventListener('click', flipCard)

        return () => {
            // document.querySelector('.swiper-wrapper').removeEventListener('click', flipCard)
            swiper.destroy();
        }
    }, [])

    return (
        <div className='swiper-container' ref={swiperRef}>
            <div className='swiper-wrapper'>
                {slides.map((slide, i) => {
                    return (
                        <div className='swiper-slide' key={`article-slide-${i}`}>
                            <div className='flipcard__inner'>
                                <div className='flipcard__front'>
                                    <div className='flipcard__slider'>
                                        <img src={slide.thumbnail} alt='' />
                                        <h2 className='flipcard__heading'>{slide.heading}</h2>
                                    </div>
                                    <button className='flipcard__toggle'>
                                        <span className='card__icon'></span>
                                    </button>
                                </div>
                                <div className='flipcard__back'>
                                    <div className='flipcard__slider'>
                                        <h2 className='flipcard__heading'>{slide.heading}</h2>
                                        <div className='flipcard__overflow'>
                                            <p className='flipcard__paragraph'>{slide.description}</p>
                                        </div>
                                    </div>
                                    <button className='flipcard__toggle'>
                                        <span className='card__icon minus'></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className='swiper-pagination'></div>
        </div>
    )
}

export const MainSlider = ({ slides }) => {

    const buttonStyle = {
        width: "30px",
        background: 'none',
        border: '0px'
    };

    const properties = {
        prevArrow: <button style={{ ...buttonStyle, marginLeft: '50px' }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z"/></svg></button>,
        nextArrow: <button style={{ ...buttonStyle, marginRight: '50px' }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M512 256L270 42.6v138.2H0v150.6h270v138z"/></svg></button>
    }

    return (
        <Slide {...properties} indicators={index => <div className="slide-indicator"></div>} transitionDuration={500}>
            {slides.map((slide, i) => <div className="each-slide-effect" key={`react-slide-key-${i}`}>
                <div style={{ 'backgroundImage': `url(${slide.img})`}}>
                    <div className='slide-wrap filter__blur'>
                        <h1>{slide.title}</h1>
                        <Link className='stacked__card__button' to={""}>Demnächst mehr...</Link>
                    </div>
                </div>
            </div>
            )}
        </Slide>
    )
}