import React from "react";
import Carousel from "react-multi-carousel";
import Entry from "./Entry";
import { useTranslation } from "react-i18next";
import { responsive } from "../util/dataBlog";
import "react-multi-carousel/lib/styles.css";

import hubitationImg from '../assets/imgs/blog/hubitation.jpg';
import defaultImg from '../assets/imgs/blog/title.jpg';
import softwareImg from '../assets/imgs/blog/software.png';
import heatingsetupImg from '../assets/imgs/blog/heatingsetup.webp';
import hardwareImg from '../assets/imgs/blog/hardware.png';


const CardCarousel = () => {
  const { t } = useTranslation();

  const imgMapFromBlogEntryId = {
    // 0: hubitationImg,
    1: softwareImg,
    3: heatingsetupImg,
    // 5:hardwareImg
  }

  const entries = t('blog.entries', { returnObjects: true })
  const entry = entries.map((entry, index) => (
    <Entry
      // {...entry}
      preview={entry.preview}
      key={entry.id}
      paragraph={entry.short}
      heading={entry.title}
      thumbnail={imgMapFromBlogEntryId[entry.id] || defaultImg}
      description={entry.description}
      short={entry.short}
      link={entry.link}
    />
  ));

  return (
    <div>
      <Carousel
        autoPlay={true}
        autoPlaySpeed={4000}
        infinite={true}
        showDots={true}
        draggable={false}
        responsive={responsive}>
        {entry}
      </Carousel>
    </div>
  );
};

export default CardCarousel;
