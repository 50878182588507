import React, { useEffect, useRef } from 'react'

function Modal({ modal, setModal, title, content }) {
    const modalRef = useRef(null);

    useEffect(() => {
        modal ? modalRef.current.showModal() : modalRef.current.close();
    }, [modal])

    return (
        <dialog ref={modalRef} className='modal'>
            <div className='modal__header'>
                <h3 className='modal__title'>{title}</h3>
                <button className='modal__close' onClick={() => setModal(false)}>
                    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                        <path d='M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z' />
                    </svg>
                </button>
            </div>
            <div className='modal__body'>
                {content}
            </div>
        </dialog>
    )
}

export default Modal