import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const NavigationDropDown = (props) => {
  const elements = props.elements;
  const display = props.display;

  const [displaySecond, setDisplay__second] = useState(false);

  const dropContentSecond = useRef();
  const dropBTNSecond = useRef();

  const handleShowMoreOrLess = useCallback(() => {
    if (window.innerWidth <= 1024) {
      const currentDisplay = dropContentSecond.current?.style.display;
      const newDisplay = currentDisplay === "block" ? "none" : "block";
      dropContentSecond.current.style.display = newDisplay;
      setDisplay__second(newDisplay === "block");
    }
  }, [dropContentSecond.current]);

  const resizeEvent = useCallback(() => {
    if (window.innerWidth > 1024) setDisplay__second();

    if (
      dropContentSecond.current &&
      // dropContent__second.current &&
      window.innerWidth > 1024
    ) {
      dropContentSecond.current.style.display = "";
      // dropContent__second.current.style.display = "";
    }
  // }, [dropContent.current, dropBTN__second.current]);
}, [dropContentSecond.current]);

useEffect(() => {
    window.addEventListener("resize", function (event) {
      resizeEvent(event);
    });

    return () => {
      window.removeEventListener("resize", function (event) {
        resizeEvent(event);
      });
    };
  }, []);

  return (
    <>
      <div className="dropdown-links-second">
        <Link
          className="navbar__menu__item"
          to={"/#"}
          onClick={(e) => e.preventDefault()}
        >
          {elements.name}
        </Link>
        <FontAwesomeIcon
          icon={display ? faArrowDown : faArrowRight}
          className={displaySecond && faArrowDown ? "rotate__icon" : ""}
          ref={dropBTNSecond}
          onClick={() => handleShowMoreOrLess()}
        />
      </div>

      <div
        className={
          "dropdown-content dropdown-content-second"
        }
        ref={dropContentSecond}
      >
        {elements.elements.map((subElem, i) => (
          <Link to={subElem.to} key={`element-key-${i}`}>
            {subElem.name}
          </Link>
        ))}
      </div>
    </>
  );
};

export default NavigationDropDown;
