import "../../assets/css/heatFeed.css";

import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navigation from "../../layouts/Navigation";
import Footer from "../../layouts/Footer";

import Newsletter from "../../layouts/Newsletter";

import CardCarousel from "../../components/CardCarousel";

function TestKit({ loader, loading }) {
  let location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {loading && loader}
      <div>
        <Navigation {...{ subMenu: false }} />
        <main id="main" className="main">
          <div className="heatfeed__container">
            <header className="heatfeed__header" />

            <section className="heatfeed__section__text">
              <h2>{t("...")}</h2>
              <p className="heatfeed__p padding__top__p">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet.
              </p>
            </section>

            <section>
              {/* <div className="pre__footer__buttons"> */}
              <div className="heatfeed__button__bar">
                <Link
                  className="stacked__card__button heatfeed__product__button button_pulse"
                  to="/contact"
                >
                  {t("getInTouch")}
                </Link>
                {/* <Link className="pre__footer__button" style={{ color: "white", backgroundColor: "black" }} to='/contact'>{t('getInTouch')}</Link> */}
              </div>
            </section>
            {/* <section className="heatfeed__card__container">
              <div className="heatfeed__card__div">
                {entrys.map((entry) => { */}
            {/* <Link  */}

            {/* //   className="heatfeed__entry"
                  //   key={entry.id}
                  //   to={`/about/${entry.id}`}
                  //   state={{ ...entry }}
                  // >
                  //   <img src={entry.thumbnail} />
                  //   <p>{entry.heading}</p>
                  // </Link>

                  //     let position =
                  //       n > index
                  //         ? "nextCard"
                  //         : n === index
                  //         ? "activeCard"
                  //         : "prevCard"; */}

            {/* //       return (
            //         <HeatFeedCard */}
            {/* //           key={entry.id}
            //           {...entry}
            //           // cardStyle={position}
            //         />
            //       );
            //     })}
            //   </div>
            // </section> */}
          </div>

          {/* <Blog /> */}
        </main>
        <Footer />

        <Newsletter />
      </div>
    </>
  );
}

export default TestKit;
