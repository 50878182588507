import { useState, useEffect, useCallback, useMemo } from 'react'
import gsap from 'gsap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'
import confetti from 'canvas-confetti'
import Cookies from 'js-cookie'
import Modal from '../components/Modal'
import GDPR from './GDPR'

function WizardForm({ loader, loading }) {
  const { t } = useTranslation();
  const [questionIndex, setQuestionIndex] = useState(0);
  const today = new Date();
  const [formValues, setFormValues] = useState({q4: today, q8: ''});
  const [modal, setModal] = useState(false);
  const _ = require('lodash');
  let { jobId } = useParams();
  const navigate = useNavigate();
  const [cookie, setCookie] = useState(true);
  const [jobTitle, setJobTitle] = useState('');

  if (jobId === undefined) jobId = 1096258;

  const fetchJobs = useCallback(() => {
      const url = "https://sigmaheat.jobs.personio.de/xml";
      const xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function() {
          if (this.readyState === 4 && this.status === 200) {
              const xml = this.responseXML;
              let positions = Array.from(xml.getElementsByTagName("position"));
              const position = positions.filter((pos) => parseInt(pos.childNodes[1].innerHTML) === parseInt(jobId))[0];
              setJobTitle(position.querySelector("name").innerHTML);
          }
      };
      xhttp.open("GET", url);
      xhttp.send();
  }, [jobId]);

  const confettiJam = () => {
    var myConfetti = confetti.create(document.querySelector('canvas'), {
        resize: true,
        useWorker: true
    });

    var end = Date.now() + (5 * 1000);
    var colors = ['#1ABC9C', '#2ECC71', '#3498DB', '#9B59B6', '#F1C40F', '#E67E22', '#E74C3C'];
    (function frame() {
        myConfetti({
            particleCount: 3,
            angle: 60,
            spread: 55,
            origin: { x: 0 },
            colors: colors
        });
        myConfetti({
            particleCount: 3,
            angle: 120,
            spread: 55,
            origin: { x: 1 },
            colors: colors
        });
        if (Date.now() < end) requestAnimationFrame(frame);
    }());
  }

  const questions = useMemo(() => [
    {
      id: 0,
      question: t('firstNameQuestion'),
      placeholder: t('firstNamePlaceholder'),
      type: 'text',
      error: t('firstNameError'),
      required: true
    },
    {
      id: 1,
      question: t('lastNameQuestion'),
      placeholder: t('lastNamePlaceholder'),
      type: 'text',
      error: t('lastNameError'),
      required: true
    },
    {
      id: 2,
      question: t('emailQuestion'),
      placeholder: t('emailPlaceholder'),
      type: 'email',
      error: t('emailError'),
      required: true
    },
    {
      id: 3,
      question: t('phoneQuestion'),
      placeholder: t('phonePlaceholder'),
      type: 'number',
      error: t('phoneError'),
      required: true
    },
    {
      id: 4,
      question: t('availabilityQuestion'),
      placeholder: t('availabilityPlaceholder'),
      type: 'date',
      error: t('availabilityError'),
      required: true
    },
    {
      id: 5,
      question: t('payQuestion'),
      placeholder: t('payPlaceholder'),
      type: 'text',
      error: t('payError'),
      required: true
    },
    {
      id: 6,
      question: t('cvQuestion'),
      placeholder: t('cvPlaceholder'),
      type: 'file',
      error: t('cvError'),
      required: true,
      accept: 'application/pdf'
    },
    {
      id: 7,
      question: t('docsQuestion'),
      placeholder: t('docsPlaceholder'),
      type: 'file',
      error: t('docsError'),
      required: false,
      accept: 'application/pdf'
    },
    {
      id: 8,
      question: t('optionalText'),
      placeholder: '',
      type: 'textarea',
      error: '',
      required: false
    }
  ], [t]);

  const handleInputChange = (event, type) => {
    const { name, value } = event.target;
    type === 'file' ? setFormValues((prevState) => ({ ...prevState, [name]: event.target.files })) : setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChange = (e, type, prop) => {
    let form = _.cloneDeep(formValues);
    form[`q${prop}`] = type === 'file' ? e.target.files : type === 'checkbox' ? e.target.checked : e.target.value;
    setFormValues(form);
  }

  const ratio = useCallback(() => {
    document.querySelector('.question__progress').style.setProperty('--progress', `${(questionIndex + 1) / questions.length * 100}%`);
  }, [questionIndex, questions.length])

  const questionsForm = document.querySelector('.wizard__question');
  const reviewForm = document.querySelector('.wizard__review');

  const timeline = gsap.timeline({ paused: true });

  timeline.to(questionsForm, { opacity: 0, duration: .15 })
    .to(questionsForm, { display: 'none' })
    .to(reviewForm, { display: 'flex' })
    .to(reviewForm, { opacity: 1, duration: .15 })

  const onCompleteForm = useCallback(() => {
    timeline.play();
  }, [timeline])

  const checkValidation = useCallback((index, form = 'questions') => {
    const textArray = [0, 1];
    const textNnoArray = [4, 5];
    const fileArray = [6, 7];

    if (index !== questions.length && !questions[index].required) return true;

    if (textArray.includes(index)) {
      if (`q${index}` in formValues === false) return false;
      if (/^[a-zA-ZäöüÄÖÜß]+$/.test(formValues[`q${index}`]) === false) return false;
    } else if (fileArray.includes(index)) {
      if (`q${index}` in formValues === false) return false;
      if (Object.keys(formValues[`q${index}`]).length === 0) return false;
    } else if (index === 2) {
      if (`q${index}` in formValues === false) return false;
      const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (regExp.test(formValues[`q${index}`]) === false) return false;
    } else if (textNnoArray.includes(index)) {
      if (`q${index}` in formValues === false) return false;
      if (formValues[`q${index}`] === '') return false;
    }

    if (form === 'review' && (`q${index}` in formValues === false || formValues[`q${index}`] === false)) return false;

    return true;
  }, [formValues, questions])

  const userAlert = useCallback((index) => {
    const form = index === questions.length ? 'review' : 'questions'
    const message = index === questions.length ? 'Privacy & Policy' : questions[index].error

    if (checkValidation(index, form) === false) {
      const MySwal = withReactContent(Swal)

      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: message
      })

      return false;
    }
  }, [checkValidation, questions])

  const handleNextClick = useCallback(() => {
    if (userAlert(questionIndex) === false) return;

    if (questionIndex === questions.length - 1) {
      document.querySelectorAll('.review__input').forEach((elem, index) => {
        if (elem.type === 'date') elem.valueAsDate = formValues[`q${index}`];
        if (elem.type === 'file') elem.files = formValues[`q${index}`];
        if (elem.type === 'text' || elem.type === 'textarea' || elem.type === 'email') elem.value = formValues[`q${index}`];
      })
      onCompleteForm();
    }

    if (questionIndex + 1 === questions.length) return;

    const nextQuestionIndex = questionIndex + 1;
    const currentQuestion = document.querySelector(`#question-${questionIndex}`);
    const nextQuestion = document.querySelector(`#question-${nextQuestionIndex}`);

    gsap.to(currentQuestion, {
      opacity: 0, y: "-50%", duration: 0.3, pointerEvents: "none", onComplete: () => {
        setQuestionIndex(nextQuestionIndex);
        gsap.fromTo(nextQuestion, { opacity: 0, y: "50%" }, { opacity: 1, y: "0%", duration: 0.3, pointerEvents: "all" });
      }
    });
  }, [userAlert, formValues, onCompleteForm, questionIndex, questions.length]);

  const handlePreviousClick = () => {
    if (questionIndex === 0) return;

    const previousQuestionIndex = questionIndex - 1;
    const currentQuestion = document.querySelector(`#question-${questionIndex}`);
    const previousQuestion = document.querySelector(`#question-${previousQuestionIndex}`);

    gsap.to(currentQuestion, {
      opacity: 0, y: "50%", duration: 0.3, pointerEvents: "none", onComplete: () => {
        setQuestionIndex(previousQuestionIndex);
        gsap.fromTo(previousQuestion, { opacity: 0, y: "-50%" }, { opacity: 1, y: "0%", duration: 0.3, pointerEvents: "all" });
      }
    });
  };

  const submitForm = () => {
    questions.forEach(elem => {
      if (userAlert(elem.id) === false) return;
    })

    if (userAlert(questions.length) === false) return;

    let files = [];

    let filebody = new FormData();
    filebody.append('file', formValues['q6'][0]);
    fetch("https://tech.sigmaheat.de/jobapply/document", {
      method: "POST",
      body: filebody,
      // headers: new Headers({
      //   'content-type': "multipart/form-data",
      // }),
    }).then(data => data.json()).then(data => {
      files.push({ "category": "cv", "original_filename": data.original_filename, "uuid": data.uuid });
      if (Object.keys(formValues).includes('q7')) {
        filebody = new FormData();
        filebody.append('file', formValues['q7'][0]);
        fetch("https://tech.sigmaheat.de/jobapply/document", {
          method: "POST",
          // headers: new Headers({
          //   'content-type': "multipart/form-data",
          // }),
          body: filebody,
        }).then(data => data.json()).then(data => {
          files.push({ "category": "other", "original_filename": data.original_filename, "uuid": data.uuid });
          send_application(files);
        });
      } else {
        send_application(files);
      }
    });
  }
  function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
  }
  const send_application = (files) => {

    var dateOffset = (2*60*60*1000);
    const today = new Date();
    today.setTime(today.getTime() - dateOffset);
    let data = {
      "first_name": formValues['q0'],
      "last_name": formValues['q1'],
      "email": formValues['q2'],
      "message": formValues['q8'],
      "application_date": today.getFullYear() + "-" + pad(today.getMonth() + 1, 2) + "-" + pad(today.getDate(), 2),
      "job_position_id": jobId,
      "files": files,
      "attributes": [
        {
          "id": "salary_expectations",
          "value": formValues['q5'] || "",
        },
        {
          "id": "phone",
          "value": formValues['q3'] || "",
        },
        {
          "id": "available_from",
          "value": formValues['q4'] || "",
        },
      ]
    };

    fetch("https://tech.sigmaheat.de/jobapply", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      }
    }).then(data => {
      console.log(data);
      if (data.status === 201) {
          confettiJam();
          let timerInterval;
          const MySwal = withReactContent(Swal)
          MySwal.fire({
              icon: 'success',
              title: 'Hurray...',
              text: t('successMSG'),
              html: `<p id="countdown">${t('contact.redirected')}<span>5</span></p>`,
              timer: 5000,
              didOpen: () => {
                  Swal.showLoading()
                  timerInterval = setInterval(() => {
                  Swal.getHtmlContainer().querySelector('span')
                      .textContent = (Swal.getTimerLeft() / 1000)
                      .toFixed(0)
                  }, 100)
              },
              willClose: () => {
                  setCookie(true)
                  clearInterval(timerInterval)
                  navigate('/')
                  const expirationDate = new Date(Date.now() + 5 * 60 * 1000);
                  Cookies.set('name', 'value', { expires: expirationDate });
              }
          })
      } else {
          const MySwal = withReactContent(Swal)
          MySwal.fire({
              icon: 'error',
              title: 'Oops...',
              text: t('contact.errorMSG')
          })
      }
    }).then(data => console.log(data));
  }

  const onEnterFunction = useCallback((event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleNextClick();
    }
  }, [handleNextClick]);

  useEffect(() => {
    document.querySelector(`#q${questionIndex}`).focus();
    ratio();
    document.addEventListener('keydown', onEnterFunction);
    return () => document.removeEventListener('keydown', onEnterFunction);
  }, [questionIndex, ratio, onEnterFunction])

  useEffect(() => {
    document.querySelector('input[type="date"]').valueAsDate = new Date();
    fetchJobs();
  }, [fetchJobs])

  return (
    <>
      {loading && loader}
      <canvas id="container" style={{position: 'fixed', width: '100%', height: '100vh', zIndex: 5, pointerEvents: 'none'}}></canvas>
      <div className='wizard__wrapper'>
        <div className='wizard__question'>
          <div className='question__progress' style={{ "--progress": '0%' }}></div>
          <div className='job__header'>
            <h3>{t('applyingFor')} {jobTitle}</h3>
            <span className='question__indicator'>{questionIndex + 1} / {questions.length}</span>
          </div>

          <ul className='questions__list'>
            {questions.map((question) => {
              return (
                <li className='question__item' key={`question-item-${question.id}`} id={`question-${question.id}`}>
                  <label className='question__label' htmlFor={`q${question.id}`}>{question.question}</label>
                  {question.type !== "textarea" && <input className='question__input' id={`q${question.id}`} name={`q${question.id}`} type={question.type} placeholder={question.placeholder} accept={question?.accept} onChange={(e) => handleInputChange(e, question.type)} />}
                  {question.type === "textarea" && <textarea className='question__input' id={`q${question.id}`} name={`q${question.id}`} rows="5" placeholder={question.placeholder} onChange={(e) => handleInputChange(e, 'text')} />}
                </li>
              )
            })}
          </ul>

          <div className='wizard__control__wrapper'>
            <button className={`wizard__button${questionIndex === 0 ? ' disabled' : ''}`} onClick={() => handlePreviousClick()}>{t('previous')}</button>
            <button className='wizard__button' onClick={() => handleNextClick()}>{questionIndex + 1 === questions.length ? t('review') : t('next')}</button>
          </div>
        </div>

        <div className='wizard__review'>
          <h2 className='review__heading'>{t('reviewHeading')} {jobTitle}</h2>

          <ul className='review__list'>
            {questions.map((question) => {
              return (
                <li className='review__item' key={`review-item-${question.id}`}>
                  <label className='review__label' htmlFor={`q${question.id}`}>{question.question}</label>
                  {question.type !== "textarea" && <input className='review__input' id={`q${question.id}`} name={`q${question.id}`} type={question.type} placeholder={question.placeholder} accept={question?.accept} required={true} onChange={(e) => handleChange(e, question.type, question.id)} />}
                  {question.type === "textarea" && <textarea className='review__input' id={`q${question.id}`} name={`q${question.id}`} rows="5" placeholder={question.placeholder} onChange={(e) => handleChange(e, 'text', question.id)} />}
                </li>
              )
            })}
          </ul>

          <div className='wizard__control__wrapper'>
            <span>
              <input type="checkbox" id="privacy-policy" name="privacy-policy" onChange={(e) => handleChange(e, 'checkbox', questions.length)} />
              <label htmlFor="privacy-policy">{t('privacyPolicyConfirmation')} <a href='/#' target='_blank' rel="noreferrer" onClick={(e) => {e.preventDefault(); setModal(true)}}>{t('privacyPolicyLink')}</a>{t('privacyPolicyConfirmationPartTwo')}</label>
            </span>
            <button className='wizard__button' onClick={() => cookie && submitForm()}>{t('submit')}</button>
          </div>
        </div>
      </div>
      <Modal {...{modal, setModal, title: "DSGVO", content: <GDPR />}} />
    </>
  )
}

export default WizardForm