import "../assets/css/heatFeed.css";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navigation from "../layouts/Navigation";
import Footer from "../layouts/Footer";

import CardCarousel from "../components/CardCarousel";
import HubspotContactForm from "../components/HubspotContactForm";
import Newsletter from "../layouts/Newsletter";

function HeatFeed({ loader, loading }) {
  let location = useLocation();

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {loading && loader}
      <div>
        <Navigation {...{ subMenu: false }} />
        <main id="main" className="main">
          <div className="heatfeed__container">
            <header className="heatfeed__header" />
            <section className="heatfeed__section__text">
              <h2>{t('blog.title')}</h2>
              <p className="heatfeed__p center__p marginb__p">
                {t('blog.description')}
              </p>
            </section>
            <section>
              <CardCarousel />
            </section>
          {/* <HubspotContactForm {...{ region: "eu1", portalId: "25048456", formId: "febd94a6-0295-4cfe-a400-a80e760e1953", redirectUrl: "https://sigmaheat.de/blog" }} /> */}

          </div>

        </main>
        <Footer />
        <Newsletter />
      </div>
    </>
  );
}

export default HeatFeed;
