import { useEffect } from 'react'
import Navigation from '../layouts/Navigation'
import JobsLayout from '../layouts/JobsLayout'
import Footer from '../layouts/Footer'
import { useLocation } from 'react-router-dom'
import Newsletter from '../layouts/Newsletter'

function Jobs({ loader, loading }) {
    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])
    
    return (
        <>
            {loading && loader}
            <div>
                <Navigation {...{subMenu: false, boolean: true}} />
                <main id="main" className="main">
                    <JobsLayout />
                </main>
                <Footer />

                <Newsletter />
            </div>
        </>
    )
}

export default Jobs