import { useEffect, useCallback } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const HubspotForm = ({ portalId, formId }) => {
    const navigate = useNavigate();

    const formFieldsToHSJSON = (form) => {
        let fieldArray = [];
        let formData = new FormData(form);
        for (let field of formData) {
            let values = {
                "name": field[0],
                "value": field[0] === "partnering_interest" || field[0] === "accept_terms" ? true : field[1]
            }
            fieldArray.push(values)
        }
        return fieldArray;
    }

    const getCookie = (name) => {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    };

    const missingKey = (array) => {
        const keys = ['company', 'address', 'city', 'country', 'email', 'lastname', 'accept_terms'];

        const foundKeys = new Set();
    
        for (let i = 0; i < array.length; i++) {
            const obj = array[i];
            foundKeys.add(obj.name);
            if (typeof obj.value === 'string' && obj.value.trim() === '') return true;
        }
    
        for (let i = 0; i < keys.length; i++) {
            if (!foundKeys.has(keys[i])) return true;
        }

        return false;
    }

    const onSubmit = useCallback((e) => {
        e.preventDefault();

        var form = document.querySelector('form')
        let obj = formFieldsToHSJSON(form)

        if (missingKey(obj)) {
            const MySwal = withReactContent(Swal)
      
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Stellen Sie sicher, dass alle erforderlichen Felder ausgefüllt sind, damit Sie zum nächsten Schritt übergehen können!'
            })
      
            return false;
        }

        var data = {
            "submittedAt": Date.now(),
            "fields": obj,
            "context": {
                "hutk": getCookie('hubspotutk'),
                "pageUri": window.location.href,
                "pageName": document.title
            },
            "legalConsentOptions": {
                "consent": {
                    "consentToProcess": true,
                    "text": "Ich stimme zu, andere Benachrichtigungen von SigmaHeat GmbH zu erhalten."
                }
            }
        }

        fetch(`https://forms-eu1.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: "POST",
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin', 
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data),
        })
        .then(response => {
            console.log(response)
            if (!response.ok) return {}
            return response.json()
        })
        .then(data => {
            window.sessionStorage.setItem("submit", true);
            navigate('/spiel/gluecksrad')
        });
    }, [])


    let startDate = new Date("March 19, 2024 09:00:00").getTime()
    let endDate = new Date("March 22, 2024 15:00:00").getTime()
    let current = new Date().getTime();

    const compareDates = (startDate, endDate) => {
        return current >= startDate && current <= endDate
    }

    useEffect(() => {
        if (!compareDates(startDate, endDate)) navigate("/partnering");
    }, []);

    return <div className="container py-3">
        <Form onSubmit={(e) => onSubmit(e)}>
            <Form.Group className="mb-3" controlId="company">
                <Form.Label>Unternehmensname</Form.Label>
                <Form.Control type="text" name="company"  placeholder="" required/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="address">
                <Form.Label>Adresse (Straße und Hausnummer)</Form.Label>
                <Form.Control type="text" name="address"  placeholder="" required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="city">
                <Form.Label>Stadt</Form.Label>
                <Form.Control type="text" name="city"  placeholder="" required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="country">
                <Form.Label>Land/Region</Form.Label>
                <Form.Control type="text" name="country"  placeholder="" required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="0-2/numberofemployees">
                <Form.Label>Anzahl der Mitarbeiter</Form.Label>
                <Form.Control type="number" name="0-2/numberofemployees"  placeholder="" required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="email">
                <Form.Label>E-Mail</Form.Label>
                <Form.Control type="email" name="email"  placeholder="" required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="firstname">
                <Form.Label>Vorname</Form.Label>
                <Form.Control type="text" name="firstname"  placeholder="" required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="lastname">
                <Form.Label>Nachname</Form.Label>
                <Form.Control type="text" name="lastname"  placeholder="" required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="message">
                <Form.Label>Nachricht</Form.Label>
                <Form.Control as="textarea" name="message"  rows={3} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="partnering_interest">
                <Form.Check type="checkbox" name="partnering_interest" label="Sind Sie an einer optionalen Zusammenarbeit interessiert, durch die Sie Aufträge über SigmaHeat generieren könnten?" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="accept_terms">
                <Form.Check type="checkbox" name="accept_terms" label="Hiermit akzeptiere ich die Teilnahmebedindungen (https://www.sigmaheat.de/teilnahmebedingungen-gewinnspiel)" />
            </Form.Group>

            <div className="d-flex justify-content-end">
                <button className="contact__button" type="submit">Einsenden</button>
            </div>
        </Form>
    </div>
}

export default HubspotForm;