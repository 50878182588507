import { useEffect } from 'react'
import Navigation from '../layouts/Navigation'
import Footer from '../layouts/Footer'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function PrivacyPolicy({ loader, loading}) {
    const { t } = useTranslation();
    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])
    
    return (
        <>
            {loading && loader}
            <div>
                <Navigation {...{subMenu: false, boolean: true}} />
                <main id='main' className='main'>
                    <section className='privacy__wrapper'>
                        <div className='privacy__container'>
                            <h1 className='terms__heading'>{t('privacyPolicy')}</h1>
                            <p className='terms__paragraph'>
                                <span>{t('privacy.privacyPolicyDesc')}</span>
                            </p>
                            <h3 className='terms__heading3'>{t('privacy.responsibleNameAndContact')}</h3>
                            <h3 className='terms__heading3'>SigmaHeat GmbH</h3>
                            <p className='terms__paragraph'>
                                <span>{t('privacy.managedBy')}</span>
                                <span>– Maik Brinkmann</span>
                                <br />
                                <span>{t('privacy.phone')} +49 511 87453680</span>
                                <span>{t('privacy.email')} <a href='mailto:info@sigmaheat.de'>info@sigmaheat.de</a></span>
                                <span>{t('privacy.website')} <a href='https://www.sigmaheat.de' target='_blank' rel='noreferrer'>sigmaheat.de</a></span>
                                <br />
                                <span>Lange Laube 2</span>
                                <span>30159 Hannover</span>
                            </p>

                            <h3 className='terms__heading3'>{t('privacy.personalDataProtection')}</h3>
                            <p className='terms__paragraph'>
                                <span>{t('privacy.personalDataProtectionDesc')}</span>
                            </p>

                            <h3 className='terms__heading3'>{t('accessDataCollection.heading')}</h3>
                            <p className='terms__paragraph'>
                                <span>{t('accessDataCollection.info')}</span>
                            </p>

                            <h3 className='terms__heading3'>{t('personalDataCollection.heading')}</h3>
                            <p className='terms__paragraph'>
                                <span>{t('personalDataCollection.info')}</span>
                            </p>

                            <ul className='terms__list'>
                                <li>{t('connectionPurpose')}</li>
                                <li>{t('userFriendly')}</li>
                                <li>{t('securityStability')}</li>
                                <li>{t('administrationPurpose')}</li>
                            </ul>

                            <h3 className='terms__heading3'>{t('dataTransfer.heading')}</h3>
                            <p className='terms__paragraph'>
                                <span>{t('dataTransfer.info1')}</span>
                                <span>{t('dataTransfer.info2')}</span>
                                <span>{t('dataTransfer.info3')}</span>
                                <span>{t('dataTransfer.info4')}</span>
                                <span>{t('dataTransfer.info5')}</span>
                            </p>

                            <h3 className='terms__heading3'>{t('cookies.heading')}</h3>
                            <p className='terms__paragraph'>
                                <span>{t('cookies.description')}</span>
                            </p>

                            <h3 className='terms__heading3'>{t('rights.heading')}</h3>
                            <p className='terms__paragraph'>
                                <span>{t('rights.description')}</span>
                            </p>

                            <h3 className='terms__heading3'>{t('information.heading')}</h3>
                            <p className='terms__paragraph'>
                                <span>{t('information.description')}</span>
                            </p>

                            <ul className='terms__list'>
                                <li>{t('rights.purposes')}</li>
                                <li>{t('rights.categories')}</li>
                                <li>{t('rights.recipients')}</li>
                                <li>{t('rights.storageDuration')}</li>
                                <li>{t('rights.correction')}</li>
                                <li>{t('rights.complaint')}</li>
                                <li>{t('rights.source')}</li>
                                <li>{t('rights.automatedDecisionMaking')}</li>
                                <li>{t('rights.thirdCountry')}</li>
                            </ul>

                            <h3 className='terms__heading3'>{t('privacy.correctionTitle')}</h3>
                            <p className='terms__paragraph'>
                                <span>{t('privacy.correctionText')}</span>
                            </p>

                            <h3 className='terms__heading3'>{t('privacy.deletionTitle')}</h3>
                            <p className='terms__paragraph'>
                                <span>{t('privacy.deletionText')}</span>
                            </p>

                            <ul className='terms__list'>
                                <li>{t('privacy.deletionReason1')}</li>
                                <li>{t('privacy.deletionReason2')}</li>
                                <li>{t('privacy.deletionReason3')}</li>
                                <li>{t('privacy.deletionReason4')}</li>
                                <li>{t('privacy.deletionReason5')}</li>
                                <li>{t('privacy.deletionReason6')}</li>
                            </ul>

                            <p className='terms__paragraph'>
                                <span>{t('privacy.deletionExceptionText')}</span>
                            </p>

                            <h3 className='terms__heading3'>{t('privacy.restrictionTitle')}</h3>
                            <p className='terms__paragraph'>
                                <span>{t('privacy.restrictionText1')}</span>
                                <span>{t('privacy.restrictionText2')}</span>
                                <span>{t('privacy.restrictionText3')}</span>
                                <span>{t('privacy.restrictionText4')}</span>
                            </p>

                            <h3 className='terms__heading3'>{t('privacy.dataPortabilityTitle')}</h3>
                            <p className='terms__paragraph'>
                                <span>{t('privacy.dataPortabilityText')}</span>
                            </p>

                            <h3 className='terms__heading3'>{t('privacy.objectionTitle')}</h3>
                            <p className='terms__paragraph'>
                                <span>{t('privacy.objectionText1')}</span>
                                <span>{t('privacy.objectionText2')}</span>
                            </p>

                            <h3 className='terms__heading3'>{t('privacy.revocationTitle')}</h3>
                            <p className='terms__paragraph'>
                                <span>{t('privacy.revocationText')}</span>
                            </p>

                            <h3 className='terms__heading3'>{t('privacy.complaintTitle')}</h3>
                            <p className='terms__paragraph'>
                                <span>{t('privacy.complaintText')}</span>
                            </p>

                            <h3 className='terms__heading3'>{t('privacy.updateTitle')}</h3>
                            <p className='terms__paragraph'>
                                <span>{t('privacy.updateText')}</span>
                            </p>

                            <h3 className='terms__heading3'>SigmaHeat GmbH</h3>
                            <p className='terms__paragraph'>
                                <span>{t('privacy.managedBy')}</span>
                                <span>– Maik Brinkmann</span>
                                <br />
                                <span>{t('privacy.phone')} +49 511 87453680</span>
                                <span>{t('privacy.email')} <a href='mailto:info@sigmaheat.de'>info@sigmaheat.de</a></span>
                                <span>{t('privacy.website')} <a href='https://www.sigmaheat.de' target='_blank' rel='noreferrer'>sigmaheat.de</a></span>
                                <br />
                                <span>Lange Laube 2</span>
                                <span>30159 Hannover</span>
                            </p>
                        </div>
                    </section>
                </main>
                <Footer />
            </div>
        </>
    )
}

export default PrivacyPolicy