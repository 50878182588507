import "../../assets/css/heatFeed.css";

import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navigation from "../../layouts/Navigation";
import Footer from "../../layouts/Footer";

import Newsletter from "../../layouts/Newsletter";

import CardCarousel from "../../components/CardCarousel";

function HydrBalancing({ loader, loading }) {
  let location = useLocation();

  const { t } = useTranslation();

  const texte = t("products.hydr_balancing.texte", { returnObjects: true });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {loading && loader}
      <div>
        <Navigation {...{ subMenu: false }} />
        <main id="main" className="main">
          <div className="heatfeed__container">
            <header className="heatfeed__header" />

            <section className="heatfeed__section__text">
              <h2>{t("products.hydr_balancing.title")}</h2>
              {texte.map((text) => (
                <>
                  {text.title && <h5>{text.title}</h5>}
                  <p className="heatfeed__p">{text.text}</p>
                  <div className="heatfeed__button__bar">
                    {text.button && (
                      <Link
                        className="stacked__card__button heatfeed__product__button button_pulse"
                        to={text.button.to}
                        style={{
                          minWidth: "120px",
                          textAlign: "center",
                          marginBottom: "20px",
                        }}
                      >
                        {text.button.text}
                      </Link>
                    )}
                  </div>
                </>
              ))}
            </section>
          </div>
        </main>
        <Footer />

        <Newsletter />
      </div>
    </>
  );
}

export default HydrBalancing;
