import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function StackedCard({
  no,
  id,
  issue,
  thumbnail,
  heading,
  paragraph,
  description,
  button,
  link,
}) {
  const [showCardThumbnail, setCardThumbnail] = useState(false);
  //"media queries"
  //listen to it when we drag the screen larger or smaller
  window.addEventListener("resize", (e) => {
    if (e.target.innerHeight > 800 || e.target.innerWidth > 768) {
      setCardThumbnail(true);
    } else {
      setCardThumbnail(false);
    }
  });

  //listen to it when we open the website
  useEffect(() => {
    if (window.innerHeight > 800 || window.innerWidth > 768) {
      setCardThumbnail(true);
    } else {
      setCardThumbnail(false);
    }
  }, []);
  //

  return (
    <>
      <div id={id}></div>
      <div></div>
      <a className="stacked__card__link" href={`#${id}`}>
        <span className="stacked__card__no">{no}</span>
        <h2 className="stacked__card__issue">{issue}</h2>
      </a>
      <div>
        <div className="main__container">
          <div className="stacked__card">
            {showCardThumbnail && (
              <div className="stacked__card__left__col">
                <img
                  src={thumbnail}
                  className="stacked__card__thumbnail"
                  alt=""
                />
              </div>
            )}
            <div className="stacked__card__right__col">
              <h3 className="stacked__card__heading">{heading}</h3>
              <p className="stacked__card__paragraph">{paragraph}</p>
              {/* {button && (
                <Link className="stacked__card__button" to={link}>
                  {button}
                </Link>
              )} */}

              {description && <Link
                key={id}
                to={`/blog/${id}`}
                className="stacked__card__button"
                state={{ thumbnail, heading, paragraph, description }}
              >
                Erfahre mehr...
              </Link>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StackedCard;
