import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'

function Dropdown({ name, filter, filterFunction }) {
    const [list, setList] = useState([]);
    const mainRef = useRef(null);

    function handleInputChange(e, value) {
        let chItems = [...list];
        if (e.target.checked) {
            chItems[chItems.length] = value
        } else {
            chItems = chItems.filter(el => el !== value);
        }
        setList(chItems);
        filterFunction(chItems);
    }

    function dropdownRender() {
        const selectBtn = mainRef.current.querySelector(".select__btn"),
              items = mainRef.current.querySelectorAll(".item");
    
        selectBtn.onclick = function () {
            selectBtn.classList.toggle("open");
        };
    
        items.forEach((item) => {
            item.addEventListener("click", (event) => {
                const checked = mainRef.current.querySelectorAll("[type='checkbox']:checked"),
                      btnText = mainRef.current.querySelector(".btn__text");
        
                if (checked && checked.length > 0) {
                    btnText.innerText = `${mainRef.current.dataset.name} ${checked.length ? '(' + checked.length + ')' : ''}`;
                } else {
                    btnText.innerText = mainRef.current.dataset.name;
                }
            });
        });
    
        document.addEventListener("click", (event) => {
            const isClickInside = mainRef.current?.contains(event.target);
            if (!isClickInside) selectBtn.classList.remove("open");
        });
    }

    useEffect(() => {
        dropdownRender();
    }, []);

    return (
        <div className="select__container" data-name={name} ref={mainRef}>
            <div className="select__btn">
                <span className="btn__text">{name}</span>
                <FontAwesomeIcon icon={faArrowDown} />
            </div>

            <ul className="list-items">
                {filter.map((opt) => {
                    return (
                        <li className="item" key={`item-${opt.id}`}>
                            <input type="checkbox" id={opt.id} onChange={(e) => handleInputChange(e, opt.id)} />
                            <label htmlFor={opt.id}>{opt.name}</label>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default Dropdown;
