import { useCallback } from "react";
import { Modal, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

export const BoostrapModal = ({ show, setShow, portalId, formId }) => {

    const navigate = useNavigate();

    const formFieldsToHSJSON = (form) => {
        let fieldArray = [];
        let formData = new FormData(form);
        for (let field of formData) {
            let values = {
                "name": field[0],
                "value": field[0] === "partnering_interest" || field[0] === "accept_terms" ? true : field[1]
            }
            fieldArray.push(values)
        }
        return fieldArray;
    }

    const getCookie = (name) => {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    };

    const missingKey = (array) => {
        const keys = ['email', 'firstname', 'lastname', 'legalconsent'];

        const foundKeys = new Set();

        for (let i = 0; i < array.length; i++) {
            const obj = array[i];
            foundKeys.add(obj.name);
        }
    
        for (let i = 0; i < keys.length; i++) {
            if (!foundKeys.has(keys[i])) return true;
        }

        return false;
    }

    const onSubmit = useCallback((e) => {
        e.preventDefault();

        var form = document.querySelector('form')
        let obj = formFieldsToHSJSON(form)

        if (missingKey(obj)) {
            const MySwal = withReactContent(Swal)
      
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Stellen Sie sicher, dass alle erforderlichen Felder ausgefüllt sind, damit Sie zum nächsten Schritt übergehen können!'
            })
      
            return false;
        }

        var data = {
            "submittedAt": Date.now(),
            "fields": obj,
            "context": {
                "hutk": getCookie('hubspotutk'),
                "pageUri": window.location.href,
                "pageName": document.title
            },
            "legalConsentOptions": {
                "consent": {
                    "consentToProcess": true,
                    "text": "Ich stimme zu, andere Benachrichtigungen von SigmaHeat GmbH zu erhalten."
                }
            }
        }

        fetch(`https://forms-eu1.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: "POST",
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin', 
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data),
        })
        .then(response => {
            if (!response.ok) return {}
            return response.json()
        })
        .then(data => {
            const MySwal = withReactContent(Swal)
      
            MySwal.fire({
                icon: 'success',
                title: 'Abonnement erfolgreich!',
                text: 'Vielen Dank für Ihre Anmeldung zu unserem Newsletter! 🎉 Sie sind jetzt Teil unserer Community und erhalten die neuesten Updates, Nachrichten und Sonderangebote direkt in Ihr Postfach. Wir freuen uns, Sie an Bord zu haben! Bei Fragen oder Anregungen können Sie sich jederzeit gerne an uns wenden. Willkommen an Bord!'
            })

            setShow()
        });
    }, [])

    return <Modal show={show} onHide={() => setShow()} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
            <h1>Bleiben Sie informiert: Jetzt für unseren Newsletter anmelden!</h1>
        </Modal.Header>

        <Modal.Body>
            <Form onSubmit={(e) => onSubmit(e)}>
                <Form.Group className="mb-3" controlId="email">
                    <Form.Label>E-Mail</Form.Label>
                    <Form.Control type="email" name="email"  placeholder="" required />
                </Form.Group>

                <Form.Group className="mb-3" controlId="firstname">
                    <Form.Label>Vorname</Form.Label>
                    <Form.Control type="text" name="firstname"  placeholder="" required />
                </Form.Group>

                <Form.Group className="mb-3" controlId="lastname">
                    <Form.Label>Nachname</Form.Label>
                    <Form.Control type="text" name="lastname"  placeholder="" required />
                </Form.Group>

                <Form.Group className="mb-3" controlId="phone">
                    <Form.Label>Telefonnummer</Form.Label>
                    <Form.Control type="tel" name="phone"  placeholder="" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="company">
                    <Form.Label>Unternehmensname</Form.Label>
                    <Form.Control type="text" name="company"  placeholder="" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="legalconsent">
                    <Form.Check type="checkbox" label="Ich stimme zu, andere Benachrichtigungen von SigmaHeat GmbH zu erhalten." name="legalconsent" />
                </Form.Group>

                <div className="d-flex justify-content-end">
                    <button className="contact__button" type="submit">Einsenden</button>
                </div>
            </Form>
        </Modal.Body>
  </Modal>
}

export default BoostrapModal;