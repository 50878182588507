import React from 'react'
import { useTranslation } from 'react-i18next'

function  GDPR() {
    const { t } = useTranslation();

    return (
        <>
            <h1 className='dsgvo__heading'>{t('dsgvo.processingDataTitle')}</h1>
            <h3 className='dsgvo__heading3'>{t('dsgvo.generalTitle')}</h3>
            <p className="dsgvo__paragraph">
                <span>{t('dsgvo.dataPrivacyNotice')}</span>
            </p>

            <h3 className='dsgvo__heading3'>{t('dsgvo.responsibleEntityTitle')}</h3>
            <p className="dsgvo__paragraph">
                <span>{t('dsgvo.responsibleEntityText1')}</span>
                <span>SigmaHeat GmbH </span>
                <span>Lange Laube 2</span>
                <span>30159 Hannover</span>
                <span>Tel.: <a href='tel:+4951187453680'>+49 511 87453680</a></span>
                <span>{t('dsgvo.handelsregisterEntry')}</span>
                <span>{t('dsgvo.registerNumber')} 221918</span>
                <span>{t('dsgvo.registerCourt')} Hannover</span>
                <br />
            </p>

            <h3 className='dsgvo__heading3'>{t('dsgvo.personalDataTitle')}</h3>
            <p className="dsgvo__paragraph">
                <span><strong>{t('dsgvo.applicationProcessText1')}</strong></span>
                <span><strong>{t('dsgvo.applicationProcessText2')}</strong></span>
                <span><strong>{t('dsgvo.applicationProcessText3')}</strong></span>
                <span><strong>{t('dsgvo.applicationProcessText4')}</strong></span>
                <span><strong>{t('dsgvo.applicationProcessText5')}</strong></span>
                <span><strong>{t('dsgvo.applicationProcessText6')}</strong></span>
                <span>{t('dsgvo.additionalDocuments')}</span>
                <span>{t('dsgvo.dataAccess')}</span>
                <span>{t('dsgvo.dataStoragePurpose')}</span>
                <span dangerouslySetInnerHTML={{ __html: t('dsgvo.dataRetention') }} />
                <span dangerouslySetInnerHTML={{ __html: t('dsgvo.talentPoolStorage') }} />
                <span>{t('dsgvo.jobOfferAcceptance')}</span>
            </p>

            <h3 className='dsgvo__heading3'>{t('dsgvo.dataDisclosureTitle')}</h3>
            <p className="dsgvo__paragraph">
                <span dangerouslySetInnerHTML={{ __html: t('dsgvo.dataDisclosureText') }} />
            </p>

            <h3 className='dsgvo__heading3'>{t('dsgvo.individualRightsTitle')}</h3>
            <p className='dsgvo__paragraph'>
                <span>{t('dsgvo.individualRightsText')}</span>
            </p>

            <h3 className='dsgvo__heading3'>{t('dsgvo.concludingProvisionsTitle')}</h3>
            <p className='dsgvo__paragraph'>
                <span dangerouslySetInnerHTML={{ __html: t('dsgvo.concludingProvisionsText') }} />
            </p>

            <h1 className='dsgvo__heading'>{t('dsgvo.processingDataTitle1')}</h1>
            <h3 className='dsgvo__heading3'>{t('dsgvo.generalTitle')}</h3>
            <p className='dsgvo__paragraph'>
                <span dangerouslySetInnerHTML={{ __html: t('dsgvo.processingDataText') }} />
            </p>

            <h3 className='dsgvo__heading3'>{t('dsgvo.responsibleEntityTitle')}</h3>
            <p className='dsgvo__paragraph'>
                <span>{t('dsgvo.responsibleEntityText1')}</span>
                <span>Personio SE & Co. KG</span>
                <span>Seidlstraße 3</span>
                <span>80335 München</span>
                <span>Tel.: <a href='tel:+498912501005'>+49 (89) 1250 1005</a></span>
                <span>{t('dsgvo.handelsregisterEntry')}</span>
                <span>{t('dsgvo.registerNumber')} HRA 115934</span>
                <span>{t('dsgvo.registerCourt')} Amtsgericht München</span>
                <span dangerouslySetInnerHTML={{ __html: t('dsgvo.dataProtectionOfficer') }} />
            </p>

            <h3 className='dsgvo__heading3'>{t('dsgvo.accessLogsTitle')}</h3>
            <p className='dsgvo__paragraph'>
                <span>{t('dsgvo.accessLogsText')}</span>
            </p>

            <h3 className='dsgvo__heading3'>{t('dsgvo.errorLogsTitle')}</h3>
            <p className='dsgvo__paragraph'>
                <span>{t('dsgvo.errorLogsText')}</span>
            </p>

            <h3 className='dsgvo__heading3'>{t('dsgvo.cookieUsageTitle')}</h3>
            <p className='dsgvo__paragraph'>
                <span>{t('dsgvo.cookieUsageText')}</span>
            </p>

            <h3 className='dsgvo__heading3'>{t('dsgvo.individualRightsTitle')}</h3>
            <p className='dsgvo__paragraph'>
                <span>{t('dsgvo.individualRightsText1')}</span>
            </p>

            <h3 className='dsgvo__heading3'>{t('dsgvo.concludingProvisionsTitle')}</h3>
            <p className='dsgvo__paragraph'>
                <span>{t('dsgvo.concludingProvisionsText1')}</span>
            </p>
        </>
    )
}

export default  GDPR