import React, { useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { MainSlider } from '../components/SwiperSlider';
import hubitation_img from '../assets/imgs/blog/hubitation.jpg'
import one_more_img from '../assets/imgs/blog/title.jpg'
import five_percent_img from '../assets/imgs/blog/title.jpg'

function MainBackground() {
    const { t } = useTranslation();
    const headingRef = useRef();
    const location = useLocation();

    let slides = t('backgroundSlider', { returnObjects: true });

    const imgs = [
        // hubitation_img,
        one_more_img,
        one_more_img,
        five_percent_img
    ];

    slides?.map((slide, index) => (index < imgs.length) ? slide.img = imgs[index] : imgs[0]);

    // function parallaxEffect() {
    //     var doc = document.documentElement;
    //     var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    //     var delta = top * -.75;
    //     headingRef.current.style.marginTop = delta + "px";
    // }

    // useEffect(() => {
    //     window.addEventListener('scroll', parallaxEffect);
    //     return () => window.removeEventListener('scroll', parallaxEffect);
    // }, [])

    return (
        <section className="main__background">
            <MainSlider {...{ slides }} />
            {/* <div className='filter__blur'>
                <div className="main__container main__cta">
                    <h1 className="main__cta__h1" ref={headingRef}>{t('mainCtaHeading')}</h1>
                    {location.pathname === '/faq' && <div className='main__buttons'>
                        <a href='#vermieter' className='main__cta_button extra__style'>Vermieter</a>
                        <a href='#mieter' className='main__cta_button extra__style'>Mieter</a>
                    </div>}
                    {location.pathname === '/' && <div className='main__buttons'>
                        <Link className='main__cta_button extra__style' to={"/contact"}>{"Jetzt Kontakt aufnehmen!"}</Link>
                        <Link className="main__cta_button extra__style" to='https://meetings-eu1.hubspot.com/maik-brinkmann/maik-brinkmann'>{t('orDirectLiveDemo')}</Link>
                    </div>}
                </div>
            </div> */}
        </section>
    )
}

export default MainBackground