import { useState, useEffect } from 'react'
import Navigation from '../layouts/Navigation'
import MainBackground from '../layouts/MainBackground'
import StackedCards from '../layouts/StackedCards'
import FeaturedJobs from '../layouts/FeaturedJobs'
import Locations from '../layouts/Locations'
// import FreshContent from '../layouts/FreshContent'
// import QuickClicks from '../layouts/QuickClicks'
import Footer from '../layouts/Footer'
import ClientsSection from '../layouts/ClientsSection'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import One from '../assets/imgs/stacked/1.jpg'
import Two from '../assets/imgs/stacked/2.png'
import Three from '../assets/imgs/stacked/3.jpg'
import Four from '../assets/imgs/stacked/4.jpg'
import Five from '../assets/imgs/stacked/5.jpg'
import Newsletter from '../layouts/Newsletter'

function Homepage({ loader, loading }) {
    let location = useLocation();
    const { t } = useTranslation();

    const stackedCards = t('cards', { returnObjects: true })

    let a = [One,Two,Three,Four,Five];

    stackedCards?.map((c,index) => a.length>index?c.thumbnail = a[index]:c.thumbnail=a[0])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])
    
    return (
        <>
            {loading && loader}
            <div>
                <Navigation {...{subMenu: false}} />
                <main id="main" className="main">
                    <MainBackground />
                    <StackedCards stackedCards={stackedCards} />
                    <ClientsSection />
                    <FeaturedJobs />
                    {/* <Locations /> */}
                    {/* <FreshContent /> */}
                    {/* <QuickClicks /> */}
                </main>
                <Footer />
                
                <Newsletter />
            </div>
        </>
    )
}

export default Homepage