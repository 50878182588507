import { useState } from "react";
import BoostrapModal from "../components/BoostrapModal";

export const Newsletter = () => {
    const [show, setShow] = useState(false)

    return <>
        <button className='stacked__card__button heatfeed__product__button button_pulse hubspot__button' onClick={() => setShow(true)}>Newsletter - Anmeldung</button>
        <BoostrapModal {...{ show, setShow: () => setShow(false), portalId: '25048456', formId: 'febd94a6-0295-4cfe-a400-a80e760e1953' }} />
    </>
}

export default Newsletter;