import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import WheelComponent from "../components/WheelComponent";
import "../assets/css/gluecksrad.css";

const Gluecksrad = () => {
  const navigate = useNavigate();

  const submit = sessionStorage.getItem("submit");

  useEffect(() => {
    if (!submit) {
      navigate("/partneranmeldung");
    }
  });

  const [winnerPrize, setWinnerPrize] = useState(false);

  let randomUpDuration = Math.random() * (350 - 300) + 300;
  let randomDownDuration = Math.random() * (450 - 400) + 400;
  let randomNum = 10;

  const probability = () => {
    randomNum = Math.floor(Math.random() * (999 - 1) + 1);
    if (randomNum == 1) {
      return "Starter-Kit";
    } else if (randomNum > 1 && randomNum <= 3) {
      return "Lasermessgerät";
    } else {
      return "SigmaHeat-Bier";
    }
  };

  const segments = [
    "SigmaHeat-Bier",
    "Starter-Kit",
    "SigmaHeat-Bier",
    "Lasermessgerät",
    "SigmaHeat-Bier",
    "Lasermessgerät",
    "Starter-Kit",
    "SigmaHeat-Bier",
    "Lasermessgerät",
    "SigmaHeat-Bier",
    "Lasermessgerät",
    "SigmaHeat-Bier",
  ];

  const segColors = [
    "#072C50",
    "#2A8AB3",
    "#F79646",
    "#FED683",
    "#E5E5E5",
    "#BB3333",
    "#072C50",
    "#2A8AB3",
    "#F79646",
    "#FED683",
    "#E5E5E5",
    "#BB3333",
  ];

  const onFinished = (winner) => {
    console.log(winner);
  };

  
  window.addEventListener("beforeunload", (ev) => {
    sessionStorage.removeItem('submit');
    sessionStorage.removeItem('prize');
  });

  useEffect(() => {
    const price = sessionStorage.getItem("prize");
    setWinnerPrize((price?.toLowerCase() === "starter-kit" || price?.toLowerCase() === "lasermessgerät") ? false : price)
  }, [])

  return (
    <div>
      {(!winnerPrize || (winnerPrize !== undefined && (winnerPrize?.toLowerCase() === "starter-kit" || winnerPrize?.toLowerCase() === "lasermessgerät"))) && (
      <div className="gluecksrad__main">
        <div className="gluecksrad__container">
          <div className="gluecksrad">
            <WheelComponent
              segments={segments}
              segColors={segColors}
              winningSegment={probability()}
              onFinished={(winner) => onFinished(winner)}
              primaryColor="black"
              buttonText=""
              size={350}
              circleSize={40}
              needleX={15}
              needleY={40}
              needle_Y={75}
              lineWidthOuterCircle={15}
              upDuration={randomUpDuration}
              downDuration={randomDownDuration}
            />
          </div>
        </div>
      </div>
      )}

      {winnerPrize && (winnerPrize?.toLowerCase() !== "starter-kit" || winnerPrize?.toLowerCase() !== "lasermessgerät") && (
        <div className="background__reload">
          <div className="gluecksrad__container">
            <div className="gluecksrad">
                <p className="gluecksrad__p gluecksrad__p__blue">
                  <p className="gluecksrad__p gluecksrad__p__blue">
                    Du hast ein {winnerPrize} gewonnen!
                  </p>
                  Gehe nun bitte zum SigmaHeat Messestand und zeige deinen Gewinn
                  vor!
                </p>
            </div>
          </div>
        </div>
      )}

      {/* <div className="trapezoid" /> */}
    </div>
  );
};

export default Gluecksrad;
